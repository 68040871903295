/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import TopNavBar from "../../TopNavBar/TopNav";
import { score, no_data } from "../../../Assets/Images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Score.css";
import { SaveBtn } from "../../Style/Styled";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Home.css";
import UseGetSubject from "../../../CustomHook/UseGetSubject";
import ClipLoader from "react-spinners/ClipLoader";
import { useApiContext } from "../../../Context/ApiContext";

function Score() {
  const { all_subject, get_all_chapters, all_chapter } = useApiContext();
  const [exam_type, setExamType] = useState([]);
  const [all_sub, setAll_Sub] = useState([]);
  // const [all_chapter, setAllChapters] = useState([]);
  const [filterChapter, setFilterChapters] = useState([]);
  const [sub_id, setSub_id] = useState("");
  const [user_id, setUser_id] = useState("");
  const [chptr_id, setChptr_id] = useState("");
  const [examType_id, setExamtype_id] = useState("1");
  const [score_data, set_score_data] = useState([]);
  const [lesson, setLesson] = useState([]);
  const [Apply, setApply] = useState(false);
  const [showDrp, setShowDrop] = useState(false);
  const [DrpData, setDrpData] = useState("selftest");
  const [showDrp2, setShowDrop2] = useState(false);
  const [DrpData2, setDrpData2] = useState("Select Subject");
  const [showDrp3, setShowDrop3] = useState(false);
  const [DrpData3, setDrpData3] = useState("Select Chapter");
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   // getExamType();
  //   get_viewAllChapters();
  // }, []);

  // const getExamType = async () => {
  //   const token = localStorage.getItem("token");

  //   try {
  //     const resp = await axios.get(
  //       `${process.env.REACT_APP_NEW_DEVELOPMENT}/exam-type`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //           "content-type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     setExamType(resp?.data);
  //   } catch (e) {
  //     if (e.status_code === 500 && e.message === "Unauthenticated") {
  //       clear_local_storage();
  //     } else if (e?.response?.data?.status_code === 401) {
  //       clear_local_storage();
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   }
  // };
  const calculateLessonPointsAndAverage = (lessons) => {
    const totalPoints = lessons.reduce(
      (total, lesson) => total + lesson.lessonPoint,
      0
    );
    const averageScore = totalPoints / lessons.length;
    return { totalPoints, averageScore };
  };
  const get_viewLessonScore = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      bodyData.append("user_id", user_id);
      bodyData.append("subject_id", sub_id);
      bodyData.append("chapter_id", chptr_id);
      bodyData.append("exam_type", examType_id);

      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/app-home-score`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      // const selfTestSubject = resp?.data.self_test.find(
      //   (subject) => subject.subject_id === sub_id
      // );

      // if (selfTestSubject) {
      //   const subjectChapters = selfTestSubject.chapterList;
      //   if (chptr_id) {
      //     const chapterLessons = subjectChapters
      //       .filter((chapter) => chapter.chapter_id === chptr_id)
      //       .flatMap((chapter) => chapter.lessonList);

      //     setLesson(chapterLessons);
      //     set_score_data(calculateLessonPointsAndAverage(chapterLessons));
      //   } else {
      //     const allLessons = subjectChapters.flatMap(
      //       (chapter) => chapter.lessonList
      //     );

      //     setLesson(allLessons);
      //     set_score_data(calculateLessonPointsAndAverage(allLessons));
      //   }
      // } else {
      //   if (!sub_id && chptr_id) {
      //     const allLessons = resp?.data.self_test.flatMap((subject) =>
      //       subject.chapterList
      //         .filter((chapter) => chapter.chapter_id === chptr_id)
      //         .flatMap((chapter) => chapter.lessonList)
      //     );

      //     setLesson(allLessons);
      //     set_score_data(calculateLessonPointsAndAverage(allLessons));
      //   } else {
      //     const allLessons = resp?.data.self_test.flatMap((subject) =>
      //       subject.chapterList.flatMap((chapter) => chapter.lessonList)
      //     );
      //     setLesson(allLessons);
      //     set_score_data(calculateLessonPointsAndAverage(allLessons));
      //   }
      // }
      if (!sub_id && chptr_id) {
        // If sub_id is empty but chptr_id is provided
        const allLessons = resp?.data.self_test.flatMap((subject) =>
          subject.chapterList
            .filter((chapter) => chapter.chapter_id === chptr_id)
            .flatMap((chapter) => chapter.lessonList)
        );

        setLesson(allLessons);
        set_score_data(calculateLessonPointsAndAverage(allLessons));
      } else {
        const selfTestSubject = resp?.data.self_test.find(
          (subject) => subject.subject_id === sub_id
        );

        if (selfTestSubject) {
          const subjectChapters = selfTestSubject.chapterList;
          if (chptr_id) {
            const chapterLessons = subjectChapters
              .filter((chapter) => chapter.chapter_id === chptr_id)
              .flatMap((chapter) => chapter.lessonList);

            setLesson(chapterLessons);
            set_score_data(calculateLessonPointsAndAverage(chapterLessons));
          } else {
            const allLessons = subjectChapters.flatMap(
              (chapter) => chapter.lessonList
            );

            setLesson(allLessons);
            set_score_data(calculateLessonPointsAndAverage(allLessons));
          }
        } else {
          const allLessons = resp?.data.self_test.flatMap((subject) =>
            subject.chapterList.flatMap((chapter) => chapter.lessonList)
          );

          setLesson(allLessons);
          set_score_data(calculateLessonPointsAndAverage(allLessons));
        }
      }
    } catch (e) {
      if (e.status_code === 500 && e.message === "Unauthenticated") {
        clear_local_storage();
      } else if (e?.response?.data?.status_code === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  // const get_viewAllChapters = async () => {
  //   const token = localStorage.getItem("token");

  //   try {
  //     const resp = await axios.get(
  //       `${process.env.REACT_APP_NEW_DEVELOPMENT}/chapter`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //           "content-type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     setAllChapters(resp?.data);
  //   } catch (e) {
  //     if (e.status_code === 500 && e.message === "Unauthenticated") {
  //       clear_local_storage();
  //     } else if (e?.response?.data?.status_code === 401) {
  //       clear_local_storage();
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   }
  // };

  const filterChaptersBySubjectId = (all_chapter, subjectId) => {
    return all_chapter.filter((chapter) => chapter.subject_id === subjectId);
  };

  useEffect(() => {
    get_all_chapters();
    setDrpData3("Select Chapter");
    setChptr_id("");
  }, [sub_id]);

  useEffect(() => {
    setFilterChapters(all_chapter);
  }, []);

  // const handle_drpChange = (data) => {
  //   if (data !== "Select Exam Type") {
  //     setDrpData(data.type);
  //     setShowDrop(false);
  //     setExamtype_id(data.id);
  //   } else {
  //     setDrpData("Select Exam Type");
  //     setShowDrop(false);
  //     setExamtype_id("");
  //   }
  // };

  const handle_drpChange2 = (data) => {
    if (data !== "Select Subject") {
      setDrpData2(data.name);
      setShowDrop2(false);
      setSub_id(data.id);
      setFilterChapters(filterChaptersBySubjectId(all_chapter, data.id));
    } else {
      setDrpData2("Select Subject");
      setShowDrop2(false);
      setSub_id("");
      setFilterChapters(all_chapter);
    }
  };
  const handle_drpChange3 = (data) => {
    if (data !== "Select Chapter") {
      setDrpData3(data.chapter_name);
      setShowDrop3(false);
      setChptr_id(data.id);
    } else {
      setDrpData3("Select Chapter");
      setShowDrop3(false);
      setChptr_id("");
    }
  };
  const navigate = useNavigate();

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };
  return (
    <div className="comp_container comic_font">
      <div className="w-100">
        <TopNavBar />

        <div className="score_Top_div">
          <div className="score_head">Score</div>
          <div className="w-100 score_content">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {!Apply && (
              <div className="score_div">
                <div className="score_show">
                  <img src={score} alt="img" className="scoreImg" />
                  <div className="check_score">
                    <div className="score_check">
                      To check the score select the given fields
                    </div>
                  </div>
                  <p className="mx-5 score_lorem">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            )}
            {Apply && (
              <>
                {loading === true ? (
                  <div className="apply_div d-flex justify-content-center align-items-center">
                    <ClipLoader
                      color="black"
                      loading={loading}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  <>
                    <div className="apply_div">
                      {lesson.length !== 0 && (
                        <>
                          <div className="d-flex justify-content-between lesson_header">
                            <div className="">Lesson Name</div>
                            <div>Score</div>
                          </div>
                          <div className="chapter_box">
                            {lesson?.map((elem, index) => (
                              <div key={index}>
                                {index < lesson.length - 1 && (
                                  <div className="chap_name col-lg-12 col-md-12 col-sm-12">
                                    <div className="number col-lg-1 col-md-1 col-sm-1">
                                      {index + 1}
                                    </div>
                                    <div className="lessionName col-lg-10 col-md-10 col-sm-10">
                                      <span className="lessionName">
                                        {" "}
                                        {elem?.lesson_name}
                                      </span>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-1">
                                      {elem?.lessonPoint}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      {lesson.length > 1 && (
                        <div className="chap_total">
                          {/* <div>Total Score - {score_data["total"]}</div>
                      <div>Average Score - {score_data["avg"]}</div> */}
                          <div>Total Score - {score_data.totalPoints}</div>
                          <div>Average Score - {score_data.averageScore}</div>
                        </div>
                      )}
                      {lesson.length === 1 && (
                        <div className="no_data_img">
                          <img src={no_data} alt="" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            <div className={Apply ? "select_apply_div" : "select_div"}>
              <div className="mt-5">
                <div>
                  <div className="dropdown_avatar w-100 mt-5 ">
                    <button
                      className="dropbtn_avatar"
                      onClick={() => {
                        setShowDrop2(!showDrp2);
                        setShowDrop3(false);
                        setShowDrop(false);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <span>{DrpData2}</span>
                        <KeyboardArrowDownIcon />
                      </div>
                    </button>

                    {showDrp2 && (
                      <div className="d-flex justify-content-center mx-3">
                        <div className="dropdown_avatar-content">
                          <a
                            onClick={() => handle_drpChange2("Select Subject")}
                          >
                            Select Subject
                          </a>
                          {all_subject?.map((elem, index) => (
                            <a
                              onClick={() => handle_drpChange2(elem)}
                              key={index}
                            >
                              {elem?.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="dropdown_avatar w-100 mt-5 not-allowed ">
                    <button
                      className="dropbtn_avatar not-allowed"
                      onClick={() => {
                        setShowDrop(!showDrp);
                        setShowDrop2(false);
                        setShowDrop3(false);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <span>{DrpData}</span>
                        <KeyboardArrowDownIcon />
                      </div>
                    </button>

                    {/* {showDrp && (
                      <div className="d-flex justify-content-center mx-3">
                        <div className="dropdown_avatar-content">
                          <a
                            onClick={() => handle_drpChange("Select Exam Type")}
                          >
                            Select Exam Type
                          </a>
                          {exam_type.map((elem, index) => (
                            <a
                              onClick={() => handle_drpChange(elem)}
                              key={index}
                            >
                              {elem.type}
                            </a>
                          ))}
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="dropdown_avatar w-100 mt-5 ">
                    <button
                      className="dropbtn_avatar"
                      onClick={() => {
                        setShowDrop3(!showDrp3);
                        setShowDrop(false);
                        setShowDrop2(false);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <span>{DrpData3}</span>
                        <KeyboardArrowDownIcon />
                      </div>
                    </button>

                    {showDrp3 && (
                      <div className="d-flex justify-content-center mx-3">
                        <div className="dropdown_avatar-content mb=5">
                          <a
                            onClick={() => handle_drpChange3("Select Chapter")}
                          >
                            Select Chapter
                          </a>
                          {filterChapter?.map((elem, index) => (
                            <a
                              onClick={() => handle_drpChange3(elem)}
                              key={index}
                            >
                              {elem?.chapter_name}
                            </a>
                          ))}
                          {/* {[
                            " Animal Kingdom",
                            "Parts of body",
                            "plants",
                            "Types of birds",
                            "Day and night",
                          ].map((elem, index) => (
                            <a onClick={() => handle_drpChange3(elem)}>
                              {elem}
                            </a>
                          ))} */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="applyBtn">
                <SaveBtn
                  className="saveBtn"
                  width="95px"
                  bg="#FF6700"
                  shadow="2px 2px 2px 2px #FF6700"
                  color="white"
                  height="38px"
                  f_weight="550"
                  f_size="20px"
                  onClick={() => {
                    setApply(true);
                    get_viewLessonScore();
                  }}
                >
                  Apply
                </SaveBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Score;
