import React, { useState } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reward_reward, badges } from "../../Assets/Images/index";
import "./RewardNew.css";
import Bagde from "./Bagde";
import Rewards from "./Rewards";

function RewardNew() {
  const [childActive, setChaildActive] = useState("rewards");
  localStorage.removeItem("subId");

  return (
    <>
      <div
        className="comp_container comic_font"
        style={{
          height: "100%",
          minHeight: "100%",
          backgroundColor: "#0c1341",
        }}
      >
        <TopNavBar />

        <div className="rewards_sideDiv">
          <div className="w-100">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <div className="d-flex justify-content-between head_reward mt-3">
                    <div
                      className="d-flex"
                      onClick={() => setChaildActive("rewards")}
                    >
                      <img
                        width={13}
                        height={13}
                        className="mt-2"
                        src={reward_reward}
                        alt=""
                      />
                      <div
                        className={
                          childActive === "rewards"
                            ? "active_reward_head mt-1 mx-2"
                            : "reward_head mt-1  mx-2"
                        }
                      >
                        Rewards
                      </div>
                    </div>
                    <div
                      className="d-flex mx-5"
                      onClick={() => setChaildActive("badge")}
                    >
                      <img
                        width={13}
                        height={13}
                        className="mt-2"
                        src={badges}
                        alt=""
                      />
                      <div
                        className={
                          childActive === "badge"
                            ? "active_reward_head mt-1 mx-2"
                            : "reward_head mt-1 mx-2"
                        }
                      >
                        Badges
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-2 reward_wrapper">
                    <div
                      className={
                        childActive === "rewards"
                          ? "reward_orange_slider"
                          : "reward_gray_slider"
                      }
                    ></div>
                    <div
                      className={
                        childActive === "badge"
                          ? "reward_orange_slider"
                          : "reward_gray_slider"
                      }
                    ></div>
                  </div>
                </div>
              </div>
              {childActive === "rewards" && <Rewards />}
              {childActive === "badge" && <Bagde />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RewardNew;
