import React, { useEffect, useState } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import "./Contact_us.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { close, contact_us, right_icon } from "../../Assets/Images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact_us() {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("User");
    setUserData(JSON.parse(user));
  }, []);

  const navigate = useNavigate();

  const get_sumit_query = async () => {
    if (message !== "") {
      setLoading(true);
      const token = localStorage.getItem("token");

      try {
        const bodyData = new FormData();
        // bodyData.append("name", userData?.full_name);
        // bodyData.append("email", userData?.email);
        bodyData.append("massage", message);
        const resp = await axios.post(
          `${process.env.REACT_APP_NEW_DEVELOPMENT}/contact`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (resp) {
          // toast.success("Query Sent");
          setMessage("");
          setOpen(true);
          setLoading(false);
        }
      } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 500) {
          clear_local_storage();
        } else {
          toast.error("Something went wrong");
        }
      }
    } else {
      toast.error("Please type something");
    }
  };
  const clear_local_storage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    navigate("/");
  };
  return (
    <>
      <div
        className="comp_container comic_font"
        style={{ height: "100%", minHeight: "100%", backgroundColor: "white" }}
      >
        <TopNavBar />

        <div className="contactus_sideDiv">
          <div className="w-100 contact_us_container">
            <div className="d-flex justify-content-center mt-4">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              {loading && (
                <>
                  <div className="">
                    <ClipLoader
                      color="black"
                      loading={true}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </>
              )}
              {!loading && (
                <div className="contact_us-card">
                  <img src={contact_us} alt="" className="contact_us_img" />
                  <div className="comic_font mt-2 send_msg">
                    Send us a message
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <div>
                      <div>
                        <textarea
                          type="text"
                          className="contact_us_inpt"
                          placeholder="Type your message"
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>

                      <div className="send_btn_div">
                        <button
                          className="send_msg_btn"
                          onClick={() => get_sumit_query()}
                          disabled={message.length === 0 ? true : false}
                        >
                          Send a message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size={700}
        animation={false}
        aria-labelledby="contained-modal-title-lg"
        centered
        style={{
          borderRadius: "50px",
        }}
      >
        <Modal.Body
          style={{
            padding: "10%",
            height: "389px",
            paddingTop: "9%",
            borderRadius: "80px",
            maxWidth: "707px",
            border: "10px solid #8B60FD",
            backgroundColor: "white",
          }}
        >
          <div className="comic_font">
            <div className="d-flex justify-content-end -w-100 contact_us_close">
              <img
                src={close}
                alt=""
                onClick={() => setOpen(false)}
                className="contact_us_close"
              />
            </div>
            <div className="contact_us_contentdiv">
              <div className="d-flex justify-content-center w-100">
                <img src={right_icon} alt="" className="faq_right_icon" />
                {/* <CheckCircleIcon
                  style={{
                    width: "120px",
                    height: "120px",
                    color: "#2AC940",
                  }}
                /> */}
              </div>
              <div className="d-flex justify-content-center thanksForReach mt-2">
                Thanks for reaching out.
              </div>
              <div className="d-flex justify-content-center mt-2">
                <div className="someoneFrom">
                  Someone from our team will soon get in touch with you.
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Contact_us;
