import React, { useEffect, useState, useRef } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import "./Revison.css";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { no_data, com } from "../../Assets/Images";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "./Iframe.css";
import { useNavigate, useParams } from "react-router-dom";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import Button from "react-bootstrap/Button";
// import MyPDFViewer from "../PdfScreen/myPdfView";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PDFModal from "../PdfModal";
import { useApiContext } from "../../Context/ApiContext";
import SubjectBar from "../SubjectBar";

function Revison() {
  const { id } = useParams();
  const { apiLoading, all_subject, revision, notes, getNotes } =
    useApiContext();
  const [notesFilter, setNotesFilter] = useState([]);

  const [loading, setLoading] = useState(false);
  const [active, set_active] = useState();
  const [active_tab, setActive_tab] = useState("selftest");
  const [open, setOpen] = useState(false);
  const [chapterId, setChapterId] = useState();

  const [allRevision, setAllRevision] = useState([]);
  const [pdfurl, setSelectedChapterUrl] = useState("");
  const [pastPaper, setpastPaper] = useState([]);

  const [lesson_id, set_lesson_id] = useState("");
  const [lession, setLession] = useState([]);
  const [page, setPage] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showRow_pp, setShowRow_pp] = useState(false);
  const [Row_pp_Data, setRow_pp_Data] = useState(10);
  const [isPaginate, setIsPaginate] = useState(true);
  const [Row_data, setRow_data] = useState(10);

  useEffect(() => {
    const storedActive = localStorage.getItem("subId");
    if (storedActive) {
      set_active(storedActive);
    } else {
      set_active(all_subject[0]?.id);
    }
  }, [all_subject]);

  useEffect(() => {
    set_lesson_id(id);
  }, [id]);

  useEffect(() => {
    const filterData = notes.filter((chapter) => chapter.subject_id == active);
    setNotesFilter(filterData);
    setActiveIndex(0);
  }, [active, notes]);

  useEffect(() => {
    if (active_tab === "selftest") {
      const selftestData = revision["Self Test"] || [];
      // const selftestData = revision.filter((data) => data === "Self Test");

      const filterData = selftestData.filter(
        (chapter) => chapter.subject_id == active
      );

      setLession(filterData[0]?.chapterList[0]?.lessonList);
      setAllRevision(filterData);
    } else if (active_tab === "pastpaper") {
      const pastpaperData = revision["Past paper"] || [];
      // const pastpaperData = revision.filter((data) => data === "Past paper");
      setAllRevision(pastpaperData);

      const filterData = pastpaperData.filter(
        (chapter) => chapter.subject_id == active
      );
      setAllRevision(filterData);
    }
  }, [active_tab, active, revision]);

  localStorage.removeItem("sub");

  const get_Revision = async (chapter_id, currentPage, Row_data) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let resp;
    try {
      const bodyData = new FormData();
      bodyData.append("subject_id", active);
      bodyData.append("chapter_id", chapter_id);
      bodyData.append("pagination", isPaginate);
      bodyData.append("step", currentPage || page);
      isPaginate === true && bodyData.append("per_page", Row_pp_Data);
      isPaginate === true && bodyData.append("step", page);

      // if (active && (chapterId || chapter_id) && page && Row_data && isPaginate == true) {
      resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewRevisionSelfTest`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      // }
      if (resp.status == 200) {
        setLession(resp?.data?.data.data);
        // setChapterId(resp?.data?.data.data[0].chapter_id)
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    } catch (e) {
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        // clear_local_storage();
      }
    }
  };
  const navigate = useNavigate();
  const postNoteVisitCount = async (chapterID) => {
    const token = localStorage.getItem("token");
    try {
      const bodyData = new FormData();
      bodyData.append("chapter_id", chapterID);

      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/note-visit-count-add`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp.data) {
        getNotes();
      }
    } catch (e) {
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
      }
    }
  };
  const get_Past = async (chapter_id, currentPage, Row_data) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let resp;
    try {
      const bodyData = new FormData();
      bodyData.append("subject_id", active);
      bodyData.append("Chapter_id", chapter_id);
      bodyData.append("pagination", isPaginate);
      bodyData.append("step", currentPage || page);
      // bodyData.append("per_page", 10);
      isPaginate === true && bodyData.append("per_page", Row_pp_Data);
      isPaginate === true && bodyData.append("step", page);

      // if (active) {
      resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewPastPaper`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      // }
      if (resp.status == 200) {
        setpastPaper(resp?.data?.data?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    } catch (e) {
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        // clear_local_storage();
      }
    }
  };
  const handle_row_click = (elem) => {
    if (elem === "All" && Row_data !== "All") {
      setIsPaginate(false);
      setRow_data(elem);
      setRow_pp_Data("");
      get_Revision();
      // setPastPaperData([]);
      // get_all_pastpapers();
    } else if (Row_data !== elem) {
      setRow_pp_Data(elem);
      setRow_data(elem);
      setIsPaginate(true);
      get_Revision(chapterId, page, elem);
      // setPastPaperData([]);
      // get_all_pastpapers();
    }
    setShowRow_pp(false);
  };
  const handle_PastPaper_row_click = (elem) => {
    if (elem === "All" && Row_data !== "All") {
      setIsPaginate(false);
      setRow_data(elem);
      setRow_pp_Data("");
      get_Past();
      // setPastPaperData([]);
      // get_all_pastpapers();
    } else if (Row_data !== elem) {
      setRow_pp_Data(elem);
      setRow_data(elem);
      setIsPaginate(true);
      get_Past(chapterId, page, elem);
      // setPastPaperData([]);
      // get_all_pastpapers();
    }
    setShowRow_pp(false);
  };

  function handleButtonClick(List, index) {
    setActiveIndex(index);
    setLession(List);
  }

  function previousPage() {
    changePage(-1);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  function nextPage() {
    changePage(1);
  }
  const handlePagination = (newPage) => {
    setPage(newPage);
    get_Revision(chapterId, newPage, Row_data);
    get_Past(chapterId, newPage, Row_data);
  };
  const handleStartRivision = (item) => {
    const isRivision = true;
    localStorage.setItem("subId", active);
    localStorage.setItem("isRivision", isRivision);
    localStorage.setItem("lesson_id", item?.lesson_id);
    localStorage.removeItem("active_video");
    localStorage.removeItem("selftest_qstn_arr");
    navigate(`/selftest/${item?.lesson_id}`, { state: item });
  };

  // pastPaper test start
  const handleStartPast = (item) => {
    navigate(`/test`, {
      state: { id: item?.id, isRivision: true },
    });
  };

  const boxShadow = "unset";
  const margin = 0;

  return (
    <>
      <div className="comp_container comic_font">
        <TopNavBar />
        <div className="d-flex header_prg" style={{ padding: "unset" }}>
          <SubjectBar
            all_subject={all_subject}
            active={active}
            set_active={set_active}
            boxShadow={boxShadow}
            margin={margin}
          />
        </div>
        <div className="sideDiv me-2">
          <div className="w-100 resRevison">
            <div className="d-flex justify-content-end mt-3 revesion_top_table">
              <button
                onClick={() => setActive_tab("selftest")}
                className={
                  active_tab === "selftest"
                    ? "active_tab me-2"
                    : "nonactive_tab me-2"
                }
              >
                Selftest
              </button>

              <button
                onClick={() => setActive_tab("pastpaper")}
                className={
                  active_tab === "pastpaper"
                    ? "active_tab me-2"
                    : "nonactive_tab me-2"
                }
              >
                Pastpaper
              </button>
              <button
                onClick={() => setActive_tab("notes")}
                className={
                  active_tab === "notes" ? "active_tab" : "nonactive_tab"
                }
              >
                Notes
              </button>
            </div>

            {!apiLoading?.revision_load ? (
              <>
                {active_tab === "selftest" &&
                  (allRevision?.length > 0 ? (
                    <div className="d-flex mt-3 revesion_top_table col-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="revion_topicName_div col-lg-2 col-md-2 col-sm-2 me-1">
                        {allRevision &&
                          allRevision[0]?.chapterList?.map((item, index) => {
                            return (
                              <div
                                className={
                                  activeIndex == index
                                    ? "active_topic_name"
                                    : "topic_name"
                                }
                                key={index}
                              >
                                <div
                                  className="cir_content_text_rivision"
                                  onClick={() =>
                                    handleButtonClick(item?.lessonList, index)
                                  }
                                >
                                  {item?.chapter_name}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div
                        className="ms-2 cir_table_container"
                        style={{
                          maxHeight: "600px",
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        {lession?.length !== 0 ? (
                          <table className="w-100">
                            <tbody className="col-10 col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 me-2">
                              <tr className="cir_table_heading col-10 col-lg-10 col-md-10 col-sm-10 me-1">
                                <td
                                  className="cir_head_text_rivision col-1 col-lg-1 col-md-2 col-sm-1"
                                  style={{
                                    borderRadius: "8px 0px 0px 0px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Lesson Code
                                </td>
                                <td
                                  className="cir_head_text_rivision col-1 col-lg-1 col-md-1 col-sm-1"
                                  style={{
                                    borderRadius: "0px 0px 0px 0px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Lesson
                                </td>
                                <td
                                  className="cir_head_text_rivision col-1 col-lg-1 col-md-1 col-sm-1"
                                  style={{
                                    borderRadius: "0px 0px 0px 0px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Com
                                </td>
                                <td
                                  className="cir_head_text_rivision col-1 col-lg-1 col-md-1 col-sm-1"
                                  style={{
                                    borderRadius: "0px 0px 0px 0px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Score
                                </td>
                                <td
                                  className="cir_head_text_rivision col-1 col-lg-1 col-md-1 col-sm-1"
                                  style={{
                                    borderRadius: "0px 0px 0px 0px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Redo
                                </td>
                              </tr>

                              {lession !== null &&
                                lession?.map((item, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className="revesion_table_content_rivision col-10 col-lg-10 col-md-10 col-sm-10 me-1"
                                    >
                                      <td className="col-2 col-lg-2 col-md-2 col-sm-2">
                                        {item?.lesson_number}
                                      </td>
                                      <td className="col-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="">
                                          <span>{item?.lesson_name}</span>
                                        </div>
                                      </td>
                                      <td className="cir_content_text_rivision col-1 col-lg-1 col-md-1 col-sm-1">
                                        {item?.score?.score_count ? (
                                          <img src={com} alt=" " />
                                        ) : (
                                          <div className="under_scr">-</div>
                                        )}
                                      </td>
                                      <td className="cir_content_text_rivision col-1 col-lg-1 col-md-1 col-sm-1">
                                        {item?.score?.score_count ? (
                                          item?.score?.score_count
                                        ) : (
                                          <div className="under_scr">-</div>
                                        )}
                                      </td>
                                      <td className="cir_content_text_rivision col-1 col-lg-1 col-md-1 col-sm-1">
                                        <div
                                          className="rev_start"
                                          key={index}
                                          onClick={() =>
                                            handleStartRivision(item)
                                          }
                                        >
                                          Start
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="">
                            <img src={no_data} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="no_data_img">
                      <img src={no_data} alt="" />
                    </div>
                  ))}

                {active_tab === "pastpaper" &&
                  (allRevision?.length > 0 ? (
                    <div className="col-lg-12 revesion_top_table col-12 col-md-12 col-sm-12 col-lg-12 w-100  mt-4">
                      <table className="rev_pastpaper_table_container col-12 col-md-12 col-sm-12 col-lg-12 w-100  mt-4">
                        <tbody className="rev_pastpaper_table_container col-12 col-md-12 col-sm-12 col-lg-12 w-100 mt-4">
                          <tr className="rev_pastpaper_table_head col-lg-12 col-md-12 col-sm-12">
                            <td
                              className="rev_pastpaper_head_text col-lg-6 col-md-6 col-sm-6"
                              style={{
                                borderRadius: " 5px 0px 0px 0px",
                                textAlign: "center",
                              }}
                            >
                              Chapter name
                            </td>
                            <td
                              className="rev_pastpaper_head_text col-lg-2 col-md-2 col-sm-2"
                              style={{ textAlign: "center" }}
                            >
                              Com
                            </td>
                            <td
                              className="rev_pastpaper_head_text col-lg-2 col-md-2 col-sm-2"
                              style={{ textAlign: "center" }}
                            >
                              Redo
                            </td>
                            {/* <td
                        className="rev_pastpaper_head_text col-lg-2 col-md-2 col-sm-2"
                        style={{
                          borderRadius: " 0px 5px 0px 0px",
                          textAlign: "center",
                        }}
                      >
                        Notes
                      </td>  */}
                          </tr>

                          {pastPaper &&
                            pastPaper?.map((item, index) => (
                              <tr
                                key={index}
                                className="revesion_table_content_rivision col-lg-12 col-md-12 col-sm-12"
                                style={{ color: "#064974", height: "76px" }}
                              >
                                <td className="cir_content_text_rivision col-lg-6 col-md-6 col-sm-6">
                                  {item?.chapter?.name}
                                </td>
                                {item?.past_paper_score ? (
                                  <td className="col-lg-2 col-md-2 col-sm-2">
                                    <img src={com} alt=" " />
                                  </td>
                                ) : (
                                  <td>
                                    <div className=" under_scr">-</div>
                                  </td>
                                )}
                                <td>
                                  <div
                                    className="cir_content_text_rivisionMy collections"
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    // onClick={() =>
                                    //   navigate(`/starttest`, {
                                    //     state: { id: item?.id, isRivision: true, },
                                    //   })
                                    // }
                                    onClick={() => handleStartPast(item)}
                                  >
                                    Start
                                  </div>
                                </td>

                                {/* <td
                              className="rev_content_text col-lg-2 col-md-2 col-sm-2"
                              key={item?.id}
                            >
                              {item?.pp_file != null ? (
                                <img
                                  src={notes}
                                  alt=" "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSelectedChapterUrl(item?.pp_file);
                                    setOpen(true);
                                  }}
                                />
                              ) : (
                                <div className="under_scr">-</div>
                              )}
                            </td> */}

                                <hr />
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="no_data_img">
                      <img src={no_data} alt="" />
                    </div>
                  ))}
                {active_tab === "notes" &&
                  (notesFilter?.length > 0 ? (
                    <div className="col-lg-12 revesion_top_table col-12 col-md-12 col-sm-12 col-lg-12 w-100  mt-4">
                      <table className="rev_pastpaper_table_container col-12 col-md-12 col-sm-12 col-lg-12 w-100  mt-4">
                        <tbody className="rev_pastpaper_table_container col-12 col-md-12 col-sm-12 col-lg-12 w-100 mt-4">
                          <tr className="rev_pastpaper_table_head col-lg-12 col-md-12 col-sm-12">
                            <td
                              className="rev_pastpaper_head_text col-lg-6 col-md-6 col-sm-6"
                              style={{
                                borderRadius: " 5px 0px 0px 0px",
                                textAlign: "center",
                              }}
                            >
                              Chapter name
                            </td>
                            <td
                              className="rev_pastpaper_head_text col-lg-2 col-md-2 col-sm-2"
                              style={{ textAlign: "center" }}
                            >
                              Com
                            </td>
                            <td
                              className="rev_pastpaper_head_text col-lg-2 col-md-2 col-sm-2"
                              style={{ textAlign: "center" }}
                            >
                              Read
                            </td>
                            {/* <td
                        className="rev_pastpaper_head_text col-lg-2 col-md-2 col-sm-2"
                        style={{
                          borderRadius: " 0px 5px 0px 0px",
                          textAlign: "center",
                        }}
                      >
                        Notes
                      </td> */}
                          </tr>

                          {notesFilter &&
                            notesFilter?.map((item, index) => (
                              <tr
                                key={index}
                                className="revesion_table_content_rivision col-lg-12 col-md-12 col-sm-12"
                                style={{ color: "#064974", height: "76px" }}
                              >
                                <td className="cir_content_text_rivision col-lg-6 col-md-6 col-sm-6">
                                  {item?.chapter_name}
                                </td>
                                {item?.visit_count ? (
                                  <td className="col-lg-2 col-md-2 col-sm-2">
                                    {item?.visit_count}
                                  </td>
                                ) : (
                                  <td className="col-lg-2 col-md-2 col-sm-2">
                                    <div className=" under_scr">-</div>
                                  </td>
                                )}
                                <td>
                                  <div
                                    className="cir_content_text_rivisionMy collections"
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    // onClick={() =>
                                    //   navigate(`/starttest`, {
                                    //     state: { id: item?.id, isRivision: true, },
                                    //   })
                                    // }
                                    onClick={() => {
                                      setSelectedChapterUrl(item?.file);
                                      postNoteVisitCount(item?.chapter_id);
                                      setOpen(true);
                                    }}
                                  >
                                    Start
                                  </div>
                                </td>

                                {/* <td
                              className="rev_content_text col-lg-2 col-md-2 col-sm-2"
                              key={item?.id}
                            >
                              {item?.list[0] != null ? (
                                <img
                                  src={notes}
                                  alt=" "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSelectedChapterUrl(item?.list[0]);
                                    setOpen(true);
                                  }}
                                />
                              ) : (
                                <div className="under_scr">-</div>
                              )}
                            </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="no_data_img">
                      <img src={no_data} alt="" />
                    </div>
                  ))}
              </>
            ) : (
              <div className="">
                <ClipLoader
                  color="black"
                  loading={apiLoading?.revision_load}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <PDFModal
        open={open}
        scale={0.4}
        onHide={false}
        myCallback={(page) => {
          setNumPages(page?._pdfInfo?.numPages);
        }}
        page={pageNumber}
        handleClose={() => {
          setOpen(false);
          setPageNumber(1);
        }}
        selectedChapterUrl={pdfurl}
        pageNumber={pageNumber}
        numPages={numPages}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </>
  );
}

export default Revison;
