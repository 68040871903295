import React, { useEffect, useRef, useState } from "react";
import TopNavBar from "../../TopNavBar/TopNav";
import "video.js/dist/video-js.css";
import { Back_btn } from "../../Style/Styled";
import {
  chapter,
  chapter_count,
  play,
  close,
  no_data,
} from "../../../Assets/Images";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./Chapter_details.css";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "video-react/dist/video-react.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import "./Video.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyPDFViewer from "../../PdfScreen/myPdfView";

import { useApiContext } from "../../../Context/ApiContext";
function Chapter_details() {
  const { id } = useParams();
  const [lesson_id, set_lesson_id] = useState("");
  const [chptr_id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [active_video, setActive_video] = useState([]);
  const [loading, setLoading] = useState(true);
  const [play_video, set_play_video] = useState(false);
  const [status, setStatus] = useState();
  const [cur_duration, set_cur_Duration] = useState("");
  const [pdf, setPdf] = useState(null);
  const [duration, set_Duration] = useState("");
  const [token, setToken] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [lesson_Data, setLesson_data] = useState([]);
  const [doQuestionByLesson, setDoQuestionByLesson] = useState([]);
  const { allLesson, apiLoading, userData, getUserProfile, selfTestQuestions } =
    useApiContext();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const navigate = useNavigate();

  const videoRef = useRef();

  const iframeRef = useRef(null);
  const filterLessonsByChapterId = (allLesson, chptr_id) => {
    return allLesson.filter((lesson) => lesson.chapter_id == chptr_id);
  };
  useEffect(() => {
    setLesson_data(filterLessonsByChapterId(allLesson, chptr_id));
  }, [chptr_id, allLesson]);

  useEffect(() => {
    set_lesson_id(id);
  }, [id]);

  useEffect(() => {
    if (userData) {
      setLoading(true);
      if (lesson_Data && lesson_Data.length > 0) {
        for (let i = 0; i < lesson_Data.length; i++) {
          if (lesson_id == lesson_Data[i]?.id) {
            setActive_video(lesson_Data[i]);
            setPdf(lesson_Data[i]);
            localStorage.setItem(
              "active_video",
              JSON.stringify(lesson_Data[i])
            );
            localStorage.setItem(
              "question_duration_minute",
              lesson_Data[i]?.question_duration_minute
            );
          }
        }
        setLoading(false);
      }
    }
  }, [userData, lesson_Data]);

  const handle_click = (elem) => {
    setLoading(true);
    setPageNumber(1);
    setActive_video(elem);
    set_lesson_id(elem.id);
    set_play_video(true);
    handleplay();
    setPdf(elem);
    // if (!elem?.video_url) {
    //   set_cur_Duration("");
    // }
    localStorage.setItem("active_video", JSON.stringify(elem));
    localStorage.setItem("lesson_id", elem.id);
    localStorage.setItem(
      "question_duration_minute",
      elem.question_duration_minute
    );
    setLoading(false);
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  const get_ongoing = async () => {
    if (lesson_id) {
      try {
        let bodyData = new FormData();
        bodyData.append("used_time_minute", cur_duration);
        bodyData.append("used_page_count", pageNumber);
        bodyData.append("lesson_id", lesson_id);
        const resp = await axios.post(
          `${process.env.REACT_APP_NEW_DEVELOPMENT}/lesson-used-time-add`,
          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (resp) {
          getUserProfile();
        }
      } catch (e) {
        if (
          e?.response?.data?.status_code === 401 ||
          e?.response?.data?.status_code === 500
        ) {
          clear_local_storage();
        } else {
          //toast.error("Something went wrong");
          console.log(e);
        }
      }
    }
  };
  const handleCompleted = async () => {
    if (lesson_id) {
      try {
        let bodyData = new FormData();
        bodyData.append("lesson_id", lesson_id);
        const resp = await axios.post(
          `${process.env.REACT_APP_NEW_DEVELOPMENT}/lesson-make-completed`,
          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (resp) {
          getUserProfile();
        }
      } catch (e) {
        if (
          e?.response?.data?.status_code === 401 ||
          e?.response?.data?.status_code === 500
        ) {
          clear_local_storage();
        } else {
          // toast.error("Something went wrong");
          console.log(e);
        }
      }
    }
  };

  const getDoSelftestQuestion = async () => {
    const token = localStorage.getItem("token");
    const studentID = userData.id;

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-selftest-question/${studentID}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        const doQuestion = resp?.data.filter(
          (lesson) => lesson.lesson_id == lesson_id
        );
        setDoQuestionByLesson(doQuestion);

        if (doQuestion.length) {
          const totalPoints = doQuestion.reduce(
            (sum, item) => sum + parseFloat(item.points),
            0
          );

          postDoQuestionAttemp(totalPoints);
        }
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const postDoQuestionAttemp = async (score) => {
    const studentID = userData.id;
    const token = localStorage.getItem("token");
    // Make the API request
    const filterQuestionbyLesson = selfTestQuestions.filter(
      (detail) => detail.lesson_id == lesson_id
    );
    try {
      let bodyData = new FormData();
      bodyData.append("student_id", studentID);
      bodyData.append("lesson_id", lesson_id);
      bodyData.append("sorce", score);
      bodyData.append("total_question", filterQuestionbyLesson.length);
      bodyData.append("complete_question", doQuestionByLesson.length);
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-selftest-attempt`,
        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp?.data?.status === "Successfully Created!") {
        deleteDoSelftestQuestion();
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const deleteDoSelftestQuestion = async () => {
    const studentID = userData.id;
    const token = localStorage.getItem("token");
    // Make the API request
    const postData = {
      student_id: studentID,
      lesson_id: lesson_id,
    };
    try {
      // let bodyData = new FormData();
      // bodyData.append("student_id", studentID);
      //  bodyData.append("lesson_id", id);

      const resp = await axios.delete(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-selftest-question-delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: postData,
        }
      );
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getDoSelftestQuestion();
  }, [active_video]);

  // useEffect(() => {
  //   debugger;
  //   if (
  //     cur_duration === duration ||
  //     cur_duration - 1 === duration ||
  //     pageNumber === numPages
  //   ) {
  //     setStatus("complete");
  //   } else if (cur_duration !== 0) {
  //     setStatus("ongoing");
  //   }
  // }, [cur_duration]);
  useEffect(() => {
    if (cur_duration !== "") {
      if (
        cur_duration &&
        duration &&
        parseFloat(cur_duration) >= parseFloat(duration) - 1 &&
        parseFloat(cur_duration) <= parseFloat(duration)
      ) {
        setStatus("complete");
      } else if (cur_duration && parseFloat(cur_duration) > 0) {
        setStatus("ongoing");
      }
    }

    if (pageNumber !== "") {
      if (pageNumber === numPages && numPages !== 0) {
        setStatus("complete");
      } else if (pageNumber > 0 && numPages !== null) {
        setStatus("ongoing");
      } else if (numPages === null || duration === "") {
        setStatus("complete");
      }
    }
  }, [pageNumber, duration, numPages]);

  // useEffect(() => {
  //   if (status === "complete") {
  //     handleCompleted();
  //   } else {
  //     get_ongoing();
  //   }
  // }, [status]);

  useEffect(() => {
    if (cur_duration % 3 === 0 && cur_duration !== 0 && status) {
      get_ongoing();
    }
  }, [cur_duration]);

  useEffect(() => {
    if (status && cur_duration !== "" && cur_duration !== 0) {
      get_ongoing();
    }
  }, [play_video, status]);

  useEffect(() => {
    if (pageNumber === numPages && numPages !== 0) {
      handleCompleted();
    }
  }, [pageNumber, numPages]);
  useEffect(() => {
    if (cur_duration === duration) {
      handleCompleted();
    }
  }, [cur_duration, duration]);

  const handleplay = () => {
    // videoRef.current.play();
    set_play_video(true);
  };

  const handlePause = () => {
    // videoRef.current.pause();
    set_play_video(false);
  };

  useEffect(() => {
    if (videoRef) {
      videoRef?.current?.seekTo({ seconds: 10.7, allowSeekAhead: true });
      //videoRef.current.seekTo(Number(active_video?.lesson_duration_minute));
    }
  }, [videoRef.current]);

  useEffect(() => {
    const lessonId = localStorage.getItem("chptr_id");
    const token = localStorage.getItem("token");
    localStorage.removeItem("isRivision");
    if (token) {
      setToken(token);
    }
    if (lessonId !== "") {
      setId(lessonId);
    }

    localStorage.removeItem("active_qstn");
    localStorage.removeItem("selftest_qstn_arr");
  }, []);

  const handlePlayerReady = () => {
    // Seek to the specific start time when the player is ready
    // videoRef.current.seekTo(Number(active_video?.lesson_duration_minute));

    const usedsecond = userData.lessonOnGoingList.filter(
      (item) => item.lesson_id == active_video.id
    );

    if (usedsecond?.length > 0) {
      videoRef.current.seekTo(Number(usedsecond[0]?.used_time));
    } else {
      videoRef.current.seekTo({ seconds: 1, allowSeekAhead: true });
    }
  };

  function previousPage() {
    changePage(-1);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  function nextPage() {
    changePage(1);
    get_ongoing();
  }

  return (
    <>
      <div className="comp_container">
        <TopNavBar />
        <div className="sideDivCourse sideDiv">
          <div className="w-100" style={{ paddingBottom: 90 }}>
            <div className="d-flex justify-content-between chapter">
              <div className="chaptr_name_head">
                {active_video?.lesson_number}
                <span className="mx-2">{active_video?.lesson_name}</span>
              </div>
              <div className="">
                <Back_btn
                  border="1px solid #064974"
                  onClick={() => navigate(`/lesson/${chptr_id}`)}
                >
                  Back
                </Back_btn>
              </div>
            </div>

            <div className="mt-3 comic_font main_div">
              <div className="video_div">
                <div className="video_video_div">
                  {apiLoading?.les_loading && (
                    <div className="d-flex justify-content-center w-100">
                      <ClipLoader
                        color="black"
                        loading={apiLoading?.les_loading}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}
                  {!apiLoading?.les_loading && (
                    <>
                      {!loading ? (
                        <>
                          {active_video?.video_url && (
                            <ReactPlayer
                              id="example_video_1"
                              ref={videoRef}
                              playsInline
                              url={active_video?.video_url}
                              className="react-player"
                              playing={play_video}
                              onProgress={(progress) => {
                                set_cur_Duration(
                                  Math.round(progress.playedSeconds)
                                );
                              }}
                              width="100%"
                              height="100%"
                              controls
                              onPlay={() => set_play_video(true)}
                              onPause={() => set_play_video(false)}
                              onDuration={(e) => {
                                set_Duration(e);
                              }}
                              onReady={handlePlayerReady}
                              playIcon={
                                <div
                                  className="play-icon"
                                  role="button"
                                  tabIndex={0}
                                  style={{ outline: "none" }}
                                >
                                  <div>+</div>
                                </div>
                              }
                            />
                          )}
                          {!active_video?.video_url && pdf?.presentation && (
                            <div className="video_video_div" style={{}}>
                              <MyPDFViewer
                                scale={0.2}
                                pdfUrl={pdf?.presentation}
                                page={pageNumber}
                                myCallback={(page) => {
                                  setNumPages(page?._pdfInfo?.numPages);
                                }}
                              />
                            </div>
                          )}

                          {!active_video?.video_url && pdf?.video_url && (
                            <ReactPlayer
                              url={pdf?.video_url}
                              playing={play_video}
                              width="100%"
                              height="100%"
                            />
                          )}

                          {!active_video?.video_url &&
                            !pdf?.presentation &&
                            !pdf?.video_url && (
                              <div className="video_no_data">
                                No data Available
                              </div>
                            )}
                        </>
                      ) : (
                        <div className="d-flex justify-content-center w-100">
                          <ClipLoader
                            color="black"
                            loading={loading}
                            size={100}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {pdf?.presentation && !active_video?.video_url && (
                  <div className="d-flex justify-content-center w-100 mt-2">
                    <div className="button_ms">
                      <div className="pagec">
                        Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                        {numPages || "--"}
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-2 test_div col-12 col-lg-12 col-md-12 col-sm-12 row d-flex ">
                  {Number(active_video?.self_test_count) !== 0 && (
                    <div className="col-lg-2 col-md-3 col-sm-4 ms-0 mt-1">
                      <button
                        className="selfTest_btn"
                        onClick={() => navigate(`/selftest/${lesson_id}`)}
                      >
                        <DescriptionIcon
                          style={{ marginTop: "-5px" }}
                          className="detail_icon"
                        />
                        <span>Self test</span>
                      </button>
                    </div>
                  )}

                  <div className="col-lg-2 col-md-3 ms-0 col-sm-4 mt-1">
                    <button
                      className="selfTest_btn"
                      onClick={() => setOpen(true)}
                      disabled={
                        active_video?.glossary?.length === 0 ? true : false
                      }
                    >
                      <DescriptionIcon
                        style={{ marginTop: "-5px" }}
                        className="detail_icon"
                      />
                      <span>Glossary</span>
                    </button>
                  </div>

                  <div className="col-lg-2 col-md-3 ms-0 col-sm-4 mt-1 d-none">
                    <button
                      className="selfTest_btn"
                      onClick={() => navigate(`/qa/${lesson_id}`)}
                    >
                      <HelpOutlineIcon className="detail_icon" />
                      <span> Q&A</span>
                    </button>
                  </div>
                  {pdf?.presentation && !active_video?.video_url && (
                    <>
                      <div className="col-lg-2 col-md-3 ms-0 col-sm-4 mt-1">
                        <button
                          className="selfTest_btn "
                          disabled={pageNumber <= 1}
                          onClick={previousPage}
                        >
                          <ArrowLeftIcon
                            style={{ marginTop: "-5px" }}
                            className="detail_icon"
                          />
                          <span>Prev</span>
                        </button>
                      </div>
                      <div className="col-lg-2 col-md-3 ms-0 col-sm-4 mt-1">
                        <button
                          className="selfTest_btn"
                          disabled={pageNumber >= numPages}
                          onClick={nextPage}
                        >
                          <span>Next</span>

                          <ArrowRightIcon
                            style={{ marginTop: "-5px" }}
                            className="detail_icon"
                          />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="video_list">
                <div className="d-flex mb-4">
                  <img src={chapter} alt="img" />
                  <div className="mx-3 comic_font chapter_one">
                    Chapter {lesson_Data[0]?.lesson_number}
                  </div>
                </div>
                {!loading ? (
                  <div className="mt-2">
                    {lesson_Data.length === 0 && (
                      <>
                        <div className="">No data found</div>
                      </>
                    )}

                    {lesson_Data.length !== 0 && (
                      <>
                        {lesson_Data?.map((elem, index) => (
                          <div key={index}>
                            {elem?.id === active_video?.id && (
                              <div
                                className="chapter_name_div_active d-flex justify-content-between  mt-1"
                                key={index}
                              >
                                <div className="d-flex">
                                  <img
                                    src={elem?.chapter?.image || chapter_count}
                                    alt="img"
                                    className="lesson_count mt-3"
                                    onClick={() => handle_click(elem)}
                                  />
                                  <div
                                    className="lesson_info mx-3"
                                    onClick={() => handle_click(elem)}
                                  >
                                    <div>
                                      <b>
                                        Lesson {elem?.lesson_number}
                                        <div>{elem?.lesson_name}</div>
                                      </b>
                                    </div>
                                    {elem?.lesson_duration_minute && (
                                      <div className="mt-1">
                                        {parseFloat(
                                          elem?.lesson_duration_minute
                                        ) + " Min"}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {elem?.video_url && (
                                  <>
                                    {!play_video && (
                                      <div
                                        className="play_bg mt-3 mx-3"
                                        onClick={() => handleplay()}
                                      >
                                        <PlayArrowIcon
                                          style={{
                                            color: "#064974",
                                            marginTop: "-5px",
                                            marginLeft: "-1px",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {play_video && (
                                      <img
                                        onClick={() => handlePause()}
                                        src={play}
                                        alt="img"
                                        className="play mt-3 mx-3"
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                            {elem?.id !== active_video?.id && (
                              <div
                                className="chapter_name_div d-flex justify-content-between  mt-1"
                                onClick={() => handle_click(elem)}
                                key={index}
                              >
                                <div className="d-flex">
                                  <img
                                    src={elem?.chapter?.image || chapter_count}
                                    alt="img"
                                    className="lesson_count mt-3"
                                  />
                                  <div
                                    className="lesson_info mx-3"
                                    style={{ color: "black" }}
                                  >
                                    <div>
                                      <b>
                                        Lesson {elem?.lesson_number}
                                        <div>{elem?.lesson_name}</div>
                                      </b>
                                    </div>
                                    {elem?.lesson_duration_minute && (
                                      <div className="mt-1">
                                        {parseFloat(
                                          elem?.lesson_duration_minute
                                        ) + " Min"}{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {elem?.video_url && (
                                  <>
                                    <div className="play_bg mt-3 mx-3">
                                      <PlayArrowIcon
                                        style={{
                                          color: "#064974",
                                          marginTop: "-8px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center w-100">
                    <ClipLoader
                      color="black"
                      loading={loading}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Modal
          show={open}
          size="xl"
          width="100%"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body
            style={{
              background: "#FEF9EF",
              borderRadius: "10px",
              window: "100%",
            }}
          >
            <img
              src={close}
              alt=""
              className="glossary_close"
              onClick={() => setOpen(false)}
            />
            <div className="w-100 comic_font glossary_data">
              <table className="table">
                <thead className="thead-dark" style={{ background: "#E0E0E0" }}>
                  <tr>
                    <th scope="col" className="glossary_head">
                      Word
                    </th>
                    <th scope="col" className="glossary_head">
                      Description
                    </th>
                    <th scope="col" className="glossary_head">
                      Example
                    </th>
                  </tr>
                </thead>
                {/* { active_video?.glossary?.length !==0 ?  */}
                <tbody>
                  {active_video?.glossary?.map((item) => (
                    <tr key={item.id}>
                      <td className="glossary_td">{item?.word}</td>
                      <td className="glossary_td">{item?.description}</td>
                      <td className="glossary_td">{item?.example || "-"}</td>
                    </tr>
                  ))}
                </tbody>
                {/* :
                <h1>No Data Available</h1>
                } */}
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default Chapter_details;
