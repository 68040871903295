import React from "react";
import "./Error.css";
import { errorFound } from "../../Assets/Images/index";

function Error() {
  return (
    <>
      <div style={{ background: "#FAF0E4" }}>
        <div className="container_error">
          <div className="text-center">
            <img src={errorFound} alt="404 Error Image" className="img-fluid" />
            <h3 className="mt-4">404 Page Not Found</h3>
            <p></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
