import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  homeLogo,
  courses,
  cirriculumn,
  pastPaper,
  FAQ,
  revision,
  // seasonActivity,
  contact,
  practiceTest,
  home_icon,
  challenges_icon,
  mock_test,
} from "../../Assets/Images/index";
import "./SideNav.css";
import { useNavigate } from "react-router-dom";

function SideNav({ nav_show }) {
  const [isHovering, setIsHovering] = useState({
    home: false,
    courses: false,
    cirriculumn: false,
    pastPaper: false,
    QA: false,
    revision: false,
    seasonActivity: false,
    contact: false,
    My_practice_test: false,
    swap: false,
    challenge: false,
    mock: false,
  });
  const [open, setOpen] = useState("");
  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/dashboard" || path === "/progress" || path === "/score") {
      setOpen("/dashboard");
    } else if (
      path === "/course" ||
      path === "/qa" ||
      path === "/selftest" ||
      path === "/solution" ||
      path.includes("lesson") ||
      path.includes("details")
    ) {
      setOpen("/course");
    } else if (path === "/pastpaper") {
      setOpen("/pastpaper");
    } else if (path === "/Season") {
      setOpen("/Season");
    } else if (path === "/practicetest" || path === "/practiceself") {
      setOpen("/practicetest");
    } else if (path === "/revision") {
      setOpen("/revision");
    } else if (path === "/curriculum") {
      setOpen("/curriculum");
    } else if (path === "/challenge") {
      setOpen("/challenge");
    } else if (path === "/mock") {
      setOpen("/mock");
    } else if (path === "/contactus") {
      setOpen("/contactus");
    } else if (path === "/faq") {
      setOpen("/faq");
    }
  }, []);

  const navigate = useNavigate();

  const handleOpen = (e, name) => {
    e.preventDefault();
    setOpen(name);
  };

  return (
    <div className="comic_font">
      {nav_show && (
        <Sidebar
          width="91px"
          style={{
            overflow: "hidden",
            position: "fixed",
            left: 0,
            height: "100vh",
            top: "0px",
            zIndex: "200",
            backgroundColor: "white",
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <Menu className="">
            <div>
              <div className="my-3">
                <MenuItem>
                  <img src={homeLogo} height={66} width={36} alt="img" />
                </MenuItem>
              </div>
              <div className="">
                <MenuItem
                  style={{
                    textAlign: "center",
                    color: "#8b60fd",
                    marginTop: "1.5rem",
                  }}
                  onClick={(e) => {
                    navigate("/dashboard");
                    handleOpen(e, "/dashboard");
                  }}
                  className="comic_font"
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      home: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      home: false,
                    }))
                  }
                >
                  {open === "/dashboard" || isHovering.home ? (
                    <span className="comic_font tab_name"> Home</span>
                  ) : (
                    <img src={home_icon} height="26px" width="26px" alt="img" />
                  )}
                </MenuItem>
              </div>
              <div>
                <MenuItem
                  className="course_hover"
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onClick={(e) => {
                    handleOpen(e, "/course");
                    navigate("/course");
                  }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      courses: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      courses: false,
                    }))
                  }
                >
                  {open === "/course" || isHovering.courses ? (
                    <span className="comic_font tab_name"> Courses</span>
                  ) : (
                    <img src={courses} height="19px" width="25px" alt="img" />
                  )}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    navigate("/pastpaper");
                    handleOpen(e, "/pastpaper");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      pastPaper: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      pastPaper: false,
                    }))
                  }
                >
                  {open === "/pastpaper" || isHovering.pastPaper ? (
                    <span className="comic_font tab_name">
                      Past
                      <br /> Paper
                    </span>
                  ) : (
                    <img src={pastPaper} height="27px" width="24px" alt="img" />
                  )}
                </MenuItem>

                {/* <MenuItem
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onClick={(e) => {
                    navigate("/Season");
                    handleOpen(e, "/Season");
                  }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      seasonActivity: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      seasonActivity: false,
                    }))
                  }
                >
                  {open === "/Season" || isHovering.seasonActivity ? (
                    <span className="comic_font tab_name">
                      Season
                      <br />
                      Activity
                    </span>
                  ) : (
                    <img
                      src={seasonActivity}
                      height="27px"
                      width="30px"
                      alt="img"
                    />
                  )}
                </MenuItem> */}

                <MenuItem
                  onClick={(e) => {
                    navigate("/practicetest");
                    handleOpen(e, "/practicetest");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      My_practice_test: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      My_practice_test: false,
                    }))
                  }
                >
                  {open === "/practicetest" || isHovering.My_practice_test ? (
                    <span className="comic_font tab_name">Activity</span>
                  ) : (
                    <img
                      src={practiceTest}
                      height="26px"
                      width="25px"
                      alt="img"
                    />
                  )}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    navigate("/revision");
                    handleOpen(e, "/revision");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      revision: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      revision: false,
                    }))
                  }
                >
                  {open === "/revision" || isHovering.revision ? (
                    <span className="comic_font tab_name">Revison</span>
                  ) : (
                    <img src={revision} height="27px" width="19px" alt="img" />
                  )}
                </MenuItem>

                <MenuItem
                  onClick={(e) => {
                    navigate("/curriculum");
                    handleOpen(e, "/curriculum");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      cirriculumn: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      cirriculumn: false,
                    }))
                  }
                >
                  {open === "/curriculum" || isHovering.cirriculumn ? (
                    <span className="comic_font tab_name">Cirriculum</span>
                  ) : (
                    <img
                      src={cirriculumn}
                      height="19px"
                      width="33px"
                      alt="img"
                    />
                  )}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    navigate("/challenge");
                    handleOpen(e, "/challenge");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      challenge: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      challenge: false,
                    }))
                  }
                >
                  {open === "/challenge" || isHovering.challenge ? (
                    <span className="comic_font tab_name">Challenge</span>
                  ) : (
                    <img
                      src={challenges_icon}
                      height="24px"
                      width="33px"
                      alt="img"
                    />
                  )}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    navigate("/mock");
                    handleOpen(e, "/mock");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      mock: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      mock: false,
                    }))
                  }
                >
                  {open === "/mock" || isHovering.mock ? (
                    <span className="comic_font tab_name">Mock</span>
                  ) : (
                    <img src={mock_test} height="24px" width="33px" alt="img" />
                  )}
                </MenuItem>
                {/* </div> */}
                {/* </div> */}
                {/* <div className="mt-5 mt-5 "> */}
                <MenuItem
                  onClick={(e) => {
                    navigate("/contactus");
                    handleOpen(e, "/contactus");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      contact: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      contact: false,
                    }))
                  }
                >
                  {open === "/contactus" || isHovering.contact ? (
                    <span className="comic_font tab_name">Contact Us</span>
                  ) : (
                    <img src={contact} height="24px" width="24px" alt="img" />
                  )}
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    navigate("/faq");
                    handleOpen(e, "/faq");
                  }}
                  style={{ textAlign: "center", color: "#8b60fd" }}
                  onMouseOver={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      QA: true,
                    }))
                  }
                  onMouseOut={() =>
                    setIsHovering((isHovering) => ({
                      ...isHovering,
                      QA: false,
                    }))
                  }
                >
                  {open === "/faq" || isHovering.QA ? (
                    <span className="comic_font tab_name">FAQ</span>
                  ) : (
                    <img src={FAQ} height="23px" width="23px" alt="img" />
                  )}
                </MenuItem>
              </div>
            </div>
          </Menu>
        </Sidebar>
      )}
    </div>
  );
}

export default SideNav;
