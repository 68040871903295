import { useEffect, useState } from "react";
import axios from "axios";

const UseGetSubject = (url) => {
  const [all_subject, setAll_subject] = useState(null);
  const [load, setload] = useState(true);
  //   const [error, seterror] = useState("");
  const token = localStorage.getItem("token");

  const callSubject = async () => {
    setload(true);
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/subject`,

        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      setAll_subject(resp?.data);
    } catch (e) {
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
    setload(false);
  };

  useEffect(() => {
    callSubject();
  }, []);

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  return { all_subject, load };
};

export default UseGetSubject;

// testting/
