/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSpeechRecognition } from "react-speech-recognition";
import { Back_btn, SaveBtn } from "../../Style/Styled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import ClipLoader from "react-spinners/ClipLoader";
import { sound, right_ans, wrong_ans } from "../../../Assets/Images";
import "../../Chapter/Selftest/Selftest.css";
import "./Selftest_sa.css";
import useSound from "use-sound";
import { right_ans_sound } from "../../../Assets/Sound/Index";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessModal from "../../SuccessModal";
import ScoreModal from "../../ScoreModal";

function Selftest_sa() {
  const { state } = useLocation();
  const [data, setdata] = useState();
  const [type, setType] = useState();
  const [season_data, setSeaon_data] = useState({});
  const [active_lesson, setActiveLesson] = useState();
  const [submit_test, setsubmit_test] = useState(false);
  const [score_modal, setScore_modal] = useState(false);
  const [Active, setActive] = useState(0);
  const [option, setOption] = useState("");
  const [correct, setCorrect] = useState();
  const [timeLeft, setTimeLeft] = useState(state?.time ?? 1800);
  const [play] = useSound(right_ans_sound);
  const [selfTestData, setSelfTestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSolution, setShowSolution] = useState(false);
  const [show_next, setShow_next] = useState(false);
  const [show_submit, setShow_submit] = useState(true);
  const [qstn_no_arr, set_qstn_no_arr] = useState([]);
  const [initial_index, setInitial_index] = useState(0);
  const [final_index, setFinal_index] = useState(9);
  const [score, setScore] = useState(0);
  const [scoreTotal, setScoreTotal] = useState(0);
  // const { id, season_activity_score } = data

  // speect-recognition
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [speaking, setSpeaking] = useState(false);
  const [supported, setSupported] = useState(false);
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    if ("speechSynthesis" in window) {
      setSupported(true);
      setVoices(window.speechSynthesis.getVoices());
    }
  }, []);

  const speak = (text, voice) => {
    if (supported) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = voice;
      window.speechSynthesis.speak(utterance);
      setSpeaking(true);
      utterance.onend = () => setSpeaking(false);
    }
  };

  const cancel = () => {
    if (supported) {
      window.speechSynthesis.cancel();
      setSpeaking(false);
    }
  };

  // end speect-recognition

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("season_data"));
    if (obj) {
      setSeaon_data(obj);
    }
    const seasonTypeData = JSON.parse(localStorage.getItem("seasonTypeData"));
    if (seasonTypeData) {
      setdata(seasonTypeData?.data);
      setType(seasonTypeData?.type);
    }
    const active_lesson = localStorage.getItem("active_video");
    if (active_lesson) {
      setActiveLesson(JSON.parse(active_lesson));
    }
    const active_qstn = localStorage.getItem("season_active_qstn");
    if (active_qstn) {
      setActive(Number(active_qstn));
    } else {
      setActive(0);
    }
    const selftest_qstn_arr = JSON.parse(
      localStorage.getItem("season_selftest_qstn_arr")
    );
    if (selftest_qstn_arr) {
      set_qstn_no_arr(selftest_qstn_arr);
      if (selftest_qstn_arr[Active]?.correct == false) {
        setShowSolution(true);
      }
    }
  }, []);

  useEffect(() => {
    if (data) {
      get_self_test();
    }
  }, [data]);
  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [timeLeft]);

  const get_self_test = async () => {
    const token = localStorage.getItem("token");
    let resp;
    try {
      if (data?.id) {
        const bodyData = new FormData();
        bodyData.append("test_referrer_id", data?.id);

        resp = await axios.post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/ps/seasonActivityQuestion`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
      }
      if (resp.status == 200) {
        setSelfTestData(resp?.data?.data);
        const selftest_qstn_arr = JSON.parse(
          localStorage.getItem("season_selftest_qstn_arr")
        );

        if (selftest_qstn_arr) {
          set_qstn_no_arr(selftest_qstn_arr);
        } else {
          if (resp.data?.data.length !== 0) {
            const arr = [];
            for (let i = 0; i < resp.data?.data.length; i++) {
              arr.push({ id: i, correct: "", ans_id: "" });
            }
            set_qstn_no_arr(arr);
          }
        }

        if (resp.data?.data.length > 10) {
          setFinal_index(9);
        }

        // console.log("data = ", data);

        let timingHours = 0;
        let timingMinutes = 0;
        if (data?.duration_hours && data?.duration_hours > 0) {
          timingHours = data?.duration_hours * 3600;
        }

        if (data?.duration_minutes && data?.duration_minutes > 0) {
          timingHours = data?.duration_minutes * 60;
        }
        let totalSeconds = timingHours + timingMinutes;
        const oldTime = localStorage.getItem("Time");

        setTimeLeft(oldTime ?? totalSeconds);
        setLoading(false);
      }
    } catch (e) {
      if (e.status_code === 500 && e.message === "Unauthenticated") {
        clear_local_storage();
      } else if (e?.response?.data?.status_code === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const submit_score = async () => {
    const token = localStorage.getItem("token");

    try {
      let resp;
      if (data?.season_activity_score) {
        const bodyData = new FormData();
        bodyData.append(
          "season_activity_score_id",
          data?.season_activity_score?.id
        );
        bodyData.append("score", score);

        resp = await axios.post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/ps/updateSocre`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
      } else {
        const bodyData = new FormData();
        bodyData.append("season_activity_id", data?.id);
        bodyData.append("score", score);

        resp = await axios.post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/ps/storeSocre`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
      }
      if (resp) {
        setLoading(false);
        setScore_modal(true);
      }
    } catch (e) {
      if (e.status_code === 500 && e.message === "Unauthenticated") {
        clear_local_storage();
      } else if (e?.response?.data?.status_code === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  useEffect(() => {
    if (selfTestData !== []) {
      if (selfTestData?.questions_data) {
        localStorage.setItem(
          "solution",
          selfTestData?.questions_data[Active]?.solution
        );
        localStorage.setItem(
          "theory",
          selfTestData?.questions_data[Active]?.knowledge
        );
      }
    }
  }, [selfTestData]);

  // useEffect(() => {
  //   setShowSolution();
  //   setOption("");
  //   // setCorrect();
  //   if (selfTestData !== []) {
  //     localStorage.setItem("season_solution", selfTestData[Active]?.solution);
  //   }
  // }, [Active]);
  useEffect(() => {
    if (selfTestData?.length !== 0 && qstn_no_arr?.length !== 0) {
      if (qstn_no_arr[Active]?.ans_id !== "") {
        setCorrect();
        if (qstn_no_arr[Active]?.correct == false) {
          setShowSolution(true);
          localStorage.setItem(
            "solution",
            selfTestData[Active]?.one_word_correct_answer
          );
          localStorage.setItem("theory", selfTestData[Active]?.knowledge);
        } else {
          setShowSolution(false);
        }
        if (qstn_no_arr?.length === Active + 1) {
          setShow_submit(false);
          setShow_next(true);
        }
      }
      // if (selfTestData?.questions_data) {
      //   localStorage.setItem(
      //     "solution",
      //     selfTestData?.questions_data[Active]?.solution
      //   );
      //   localStorage.setItem(
      //     "theory",
      //     selfTestData?.questions_data[Active]?.knowledge
      //   );
      // }
    }
  }, [Active]);

  useEffect(() => {
    if (showSolution === true) {
      localStorage.setItem("season_active_qstn", Active);
      localStorage.setItem(
        "season_selftest_qstn_arr",
        JSON.stringify(qstn_no_arr)
      );
      if (qstn_no_arr?.length === Active + 1) {
        setShow_submit(false);
        setShow_next(true);
      }
    }
  }, [showSolution, Active]);

  const navigate = useNavigate();
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSubmit = (id, index, mark) => {
    if (option !== "") {
      setShow_submit(false);
      if (id === option) {
        setCorrect(true);
        setShowSolution(false);
        set_qstn_no_arr(
          qstn_no_arr.map((item) =>
            item.id === index
              ? {
                  ...item,
                  id: index,
                  correct: true,
                  ans_id: option,
                  mark: mark,
                }
              : item
          )
        );

        play();
      } else {
        setCorrect(false);
        setShowSolution(true);
        set_qstn_no_arr(
          qstn_no_arr.map((item) =>
            item.id === index
              ? { ...item, id: index, correct: false, ans_id: option, mark: 0 }
              : item
          )
        );
      }
      setShow_next(true);
    }
    if (option === "") {
      if (qstn_no_arr[index].ans_id !== "") {
        setShow_submit(false);
        // setShowSolution(false);
        // setShow_submit(false);
        handleNext(index);
        // setShow_next(true);
      }
    }
  };

  const handleNext = (index) => {
    if (final_index === index) {
      setFinal_index(final_index + 1);
      setInitial_index(initial_index + 1);
    }
    setShow_next(false);

    if (correct || selfTestData.length > Active + 1) {
      if (option !== "") {
        setOption("");
        setActive(Active + 1);
      } else {
        if (qstn_no_arr[index].ans_id) {
          setOption("");
          setActive(Active + 1);
        }
      }
    }
    if (
      qstn_no_arr[Active + 1].correct == false &&
      qstn_no_arr[Active + 1].correct !== ""
    ) {
      setCorrect(false);
    } else if (
      qstn_no_arr[Active + 1].correct == true &&
      qstn_no_arr[Active + 1].correct !== ""
    ) {
      setCorrect(true);
    } else {
      setCorrect();
    }

    setShowSolution();
    // setCorrect();
    setOption("");
    setShow_submit(true);
    cancel();
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  const handleClose = () => {
    navigate("/Season");
    localStorage.removeItem("Time");
    cancel();
  };

  const handleForward = () => {
    if (final_index + 1 !== qstn_no_arr.length) {
      setFinal_index(final_index + 1);
      setInitial_index(initial_index + 1);
    }
  };

  const handleBackward = () => {
    if (initial_index > 0) {
      setFinal_index(final_index - 1);
      setInitial_index(initial_index - 1);
    }
  };

  const handle_back_btn = (index) => {
    setActive(Active - 1);
    if (initial_index === index) {
      handleBackward();
    }
    if (qstn_no_arr[Active]?.correct == false) {
      setShowSolution(true);
    }
  };

  const handle_submit_test = () => {
    setsubmit_test(true);
    let count = 0;
    let total = 0;
    for (let i = 0; i < qstn_no_arr.length; i++) {
      if (qstn_no_arr[i].mark) {
        count = count + qstn_no_arr[i].mark;
      }
    }
    setScore(count);
    for (let i = 0; i < selfTestData.length; i++) {
      if (selfTestData[i].mark) {
        count = total + selfTestData[i].mark;
        total = total + selfTestData[i].mark;
      }
    }
    setScoreTotal(total);
  };
  const handle_no_click = (index) => {
    if (Active !== index) {
      if (qstn_no_arr[index].correct !== "") {
        setActive(index);
        setShow_submit(true);
        setShow_next(false);
      } else if (qstn_no_arr[index - 1].correct !== "") {
        setActive(index);
        setShow_submit(true);
        setShow_next(false);
      }
    }
  };

  const renderQuestion = () => {
    if (data?.question_type == "filling_in_the_blank" && !data?.question) {
      return (
        selfTestData &&
        selfTestData?.map((elem, index) => (
          <div key={index}>
            {index === Active && (
              <div className="qstn_bg">
                <div className="d-flex">
                  <div className="question_1">
                    <div className="qstn_sound">
                      <div>Q. {index + 1}</div>
                      <div className="mx-1">
                        {supported && (
                          <div className="sa_speechMenu col-sm-6">
                            {!speaking ? (
                              <VolumeOffIcon
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  speak({
                                    text: elem?.filling_in_the_blank
                                      .split(/<\/?p>/)
                                      .filter((part) => part.trim() !== ""),
                                    voice: voices[2],
                                  })
                                }
                              />
                            ) : (
                              <img
                                src={sound}
                                alt=""
                                className="sound_qstn"
                                onClick={cancel}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="sa_question_div">
                    <div className="question">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: elem?.filling_in_the_blank
                            .split(/<\/?p>/)
                            .filter((part) => part.trim() !== ""),
                        }}
                        style={{}}
                      />
                      {/* <div>{elem.question}</div> */}
                    </div>
                  </div>
                  <div className="sa_marks">{elem.mark} marks</div>
                </div>
                {elem.one_word_answer || elem?.filling_in_the_blank ? (
                  // Render the input box for one-word answer
                  <div className="col-lg-6 col-md-6 col-sm-6 ">
                    <input
                      className={`p-2 ${elem?.filling_in_the_blank && "mt-5"}`}
                      value={
                        qstn_no_arr[Active]?.ans_id !== ""
                          ? qstn_no_arr[Active]?.ans_id
                          : option
                      }
                      style={{
                        borderRadius: "10px",
                        backgroundColor: qstn_no_arr[Active]?.ans_id
                          ? "#daccff"
                          : "#fff",
                        pointerEvents: qstn_no_arr[Active]?.ans_id && "none",
                      }}
                      type="text"
                      placeholder=""
                      // value={option}
                      onChange={(e) => setOption(e.target.value)}
                      disabled={qstn_no_arr[Active]?.ans_id ? true : false}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="options mt-3">
                      {elem.answers.map((item, index) => (
                        <div key={index}>
                          {option !== "" && (
                            <div
                              className={
                                option === item.id
                                  ? "selected_optn optn"
                                  : "optn"
                              }
                              // style={{ cursor: showSolution && "none" }}
                              onClick={() =>
                                !showSolution && setOption(item.id)
                              }
                            >
                              <div className="d-flex">
                                {item.id}.
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer
                                      .split(/<\/?p>/)
                                      .filter((part) => part.trim() !== ""),
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {option === "" && qstn_no_arr[Active].ans_id && (
                            <div
                              className={
                                qstn_no_arr[Active].ans_id === item.id
                                  ? "selected_optn optn"
                                  : "optn"
                              }
                              onClick={() =>
                                !showSolution && setOption(item.id)
                              }
                            >
                              <div className="d-flex">
                                {item.id}.
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer
                                      .split(/<\/?p>/)
                                      .filter((part) => part.trim() !== ""),
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {option === "" && !qstn_no_arr[Active].ans_id && (
                            <div
                              className="optn"
                              // style={{ cursor: showSolution && "none" }}
                              onClick={() =>
                                !showSolution && setOption(item.id)
                              }
                            >
                              <div className="d-flex">
                                {item.id}.
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer
                                      .split(/<\/?p>/)
                                      .filter((part) => part.trim() !== ""),
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {correct && (
                  <div className="d-flex justify-content-center">
                    <div className="right_mob ">
                      <img src={right_ans} alt="" />
                      <div className="correct_text">Correct</div>
                    </div>
                  </div>
                )}
                {correct === false && (
                  <div className="d-flex justify-content-center ">
                    <div
                      className="correct_mob position-absolute"
                      style={{ bottom: "10" }}
                    >
                      <img src={wrong_ans} alt="" />
                      <div
                        className="correct_text mt-0"
                        style={{ color: "#EB1D1D" }}
                      >
                        Incorrect
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between btn_div_selftest w-100 ps-1">
                  <div className="mt-1 mb-2 solution_mob">
                    {(showSolution || correct == false) && (
                      <SaveBtn
                        onClick={() =>
                          navigate("/solution", {
                            state: {
                              id: data?.id,
                              isSeasionActivity: true,
                              data: data,
                              type,
                            },
                          })
                        }
                        className=""
                        width="95px"
                        bg="#FF6700"
                        height="38px"
                        shadow="2px 2px 2px 2px #FF6700"
                        color="white"
                      >
                        Solution
                      </SaveBtn>
                    )}
                  </div>
                  {correct && (
                    <div className="right mt-1">
                      <img src={right_ans} alt="" />
                      <div className="correct_text">Correct</div>
                    </div>
                  )}
                  {correct === false && (
                    <div className="correct mt-2">
                      <img src={wrong_ans} alt="" />
                      <div
                        className="correct_text"
                        style={{ color: "#EB1D1D" }}
                      >
                        Incorrect
                      </div>
                    </div>
                  )}
                  <div className="mt-1 ">
                    <div className="d-flex">
                      {index > 0 && (
                        <SaveBtn
                          onClick={() => handle_back_btn(index)}
                          className="bck_btn_self"
                          width="89px"
                          bg="#918E96"
                          shadow="2px 2px 2px 2px #918E96"
                          style={{ color: "white" }}
                          height="35px"
                          color="white"
                          f_weight="550"
                          f_size="20px"
                        >
                          Back
                        </SaveBtn>
                      )}

                      {show_submit && !show_next && (
                        <>
                          <SaveBtn
                            onClick={() => {
                              let param = elem.filling_in_the_blank_gap.length
                                ? elem.filling_in_the_blank_gap[0].gap
                                : elem.filling_in_the_blank_gap[0].gap;

                              return handleSubmit(param, index, elem?.mark);
                            }}
                            className="next_btn_self"
                            width="89px"
                            bg="#FF6700"
                            shadow="2px 2px 2px 2px #FF6700"
                            height="35px"
                            color="white"
                            f_weight="550"
                            f_size="20px"
                          >
                            Next
                          </SaveBtn>
                        </>
                      )}

                      {show_next &&
                        !show_submit &&
                        selfTestData.length > Active + 1 && (
                          <>
                            <SaveBtn
                              onClick={() => handleNext(index)}
                              className="next_btn_self"
                              width="89px"
                              bg="#FF6700"
                              shadow="2px 2px 2px 2px #FF6700"
                              color="white"
                              style={{ width: "89px" }}
                              height="35px"
                              f_weight="550"
                              f_size="20px"
                            >
                              Next
                            </SaveBtn>
                          </>
                        )}
                      {show_next &&
                        !show_submit &&
                        selfTestData.length === Active + 1 && (
                          <SaveBtn
                            onClick={() => handle_submit_test()}
                            className="next_btn_self"
                            width="89px"
                            bg="#FF6700"
                            shadow="2px 2px 2px 2px #FF6700"
                            height="35px"
                            color="white"
                            f_weight="550"
                            f_size="20px"
                          >
                            Submit
                          </SaveBtn>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))
      );
    } else {
      return (
        selfTestData &&
        selfTestData?.map((elem, index) => (
          <div key={index}>
            {index === Active && (
              <div className="qstn_bg">
                <div className="d-flex">
                  <div className="question_1">
                    <div className="qstn_sound">
                      <div>Q. {index + 1}</div>
                      {/* <div className="mx-1">
                        {supported && (
                          <div className="sa_speechMenu col-sm-6">
                            {!speaking ? (
                              <VolumeOffIcon
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  speak({
                                    text: elem?.question,
                                    voice: voices[2],
                                  })
                                }
                              />
                            ) : (
                              <img
                                src={sound}
                                alt=""
                                className="sound_qstn"
                                onClick={cancel}
                              />
                            )}
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="sa_question_div">
                    <div className="question">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: elem?.question
                            .split(/<\/?p>/)
                            .filter((part) => part.trim() !== ""),
                        }}
                      />
                      {/* <div>{elem.question}</div> */}
                    </div>
                  </div>
                  <div className="sa_marks">{elem.mark} marks</div>
                </div>
                {elem.one_word_answer ? (
                  // Render the input box for one-word answer
                  <div className="col-lg-6 col-md-6 col-sm-6 inputAnsPast">
                    <input
                      className="p-2 mt-5"
                      value={
                        qstn_no_arr[Active]?.ans_id !== ""
                          ? qstn_no_arr[Active]?.ans_id
                          : option
                      }
                      style={{
                        borderRadius: "10px",
                        backgroundColor: qstn_no_arr[Active]?.ans_id
                          ? "#daccff"
                          : "#fff",
                        pointerEvents: qstn_no_arr[Active]?.ans_id && "none",
                      }}
                      type="text"
                      placeholder=""
                      // value={option}
                      onChange={(e) => setOption(e.target.value)}
                      disabled={showSolution}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="options mt-3">
                      {elem.answers.map((item, index) => (
                        <div key={index}>
                          {option !== "" && (
                            <div
                              className={
                                option === item.id
                                  ? "selected_optn optn"
                                  : "optn"
                              }
                              // style={{ cursor: showSolution && "none" }}
                              onClick={() =>
                                !showSolution && setOption(item.id)
                              }
                            >
                              <div className="d-flex">
                                {item.id}.
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer
                                      .split(/<\/?p>/)
                                      .filter((part) => part.trim() !== ""),
                                  }}
                                />
                              </div>
                              {/* {item.answer} */}
                            </div>
                          )}
                          {option === "" && qstn_no_arr[Active].ans_id && (
                            <div
                              className={
                                qstn_no_arr[Active].ans_id === item.id
                                  ? "selected_optn optn"
                                  : "optn"
                              }
                              // style={{ cursor: showSolution && "none" }}
                              onClick={() =>
                                !showSolution && setOption(item.id)
                              }
                            >
                              <div className="d-flex">
                                {item.id}.
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer
                                      .split(/<\/?p>/)
                                      .filter((part) => part.trim() !== ""),
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {option === "" && !qstn_no_arr[Active].ans_id && (
                            <div
                              className="optn"
                              // style={{ cursor: showSolution && "none" }}
                              onClick={() =>
                                !showSolution && setOption(item.id)
                              }
                            >
                              <div className="d-flex">
                                {item.id}.
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer
                                      .split(/<\/?p>/)
                                      .filter((part) => part.trim() !== ""),
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {correct && (
                  <div className="d-flex justify-content-center">
                    <div className="right_mob correct_mob">
                      <img src={right_ans} alt="" />
                      <div className="correct_text">Correct</div>
                    </div>
                  </div>
                )}
                {correct === false && (
                  <div className="d-flex justify-content-center ">
                    <div
                      className="correct_mob position-absolute"
                      style={{ bottom: "100px" }}
                    >
                      <img src={wrong_ans} alt="" />
                      <div
                        className="correct_text"
                        style={{ color: "#EB1D1D" }}
                      >
                        Incorrect
                      </div>
                    </div>
                  </div>
                )}
                <div className="">
                  <div className="d-flex justify-content-between btn_div_selftest w-100 ps-3">
                    <div className="mt-1 mb-2 mobileButton text-start">
                      {(showSolution || correct == false) && (
                        <SaveBtn
                          onClick={() =>
                            navigate("/solution", {
                              state: {
                                id: data?.id,
                                isSeasionActivity: true,
                                data: data,
                                type,
                                time: timeLeft,
                              },
                            })
                          }
                          className=""
                          width="95px"
                          bg="#FF6700"
                          height="38px"
                          shadow="2px 2px 2px 2px #FF6700"
                          color="white"
                        >
                          Solution
                        </SaveBtn>
                      )}
                    </div>
                    {correct && (
                      <div className="right mt-1  self_correct">
                        <img src={right_ans} alt="" />
                        <div className="correct_text">Correct</div>
                      </div>
                    )}
                    {correct === false && (
                      <div className="correct mt-2 align-items-center m-0 justify-content-center self_incorrect ">
                        <img src={wrong_ans} alt="" />
                        <div
                          className="correct_text mt-0"
                          style={{ color: "#EB1D1D" }}
                        >
                          Incorrect
                        </div>
                      </div>
                    )}
                    <div className="mt-1 ">
                      <div className="d-flex self_Button">
                        {index > 0 && (
                          <SaveBtn
                            onClick={() => handle_back_btn(index)}
                            className="bck_btn_self"
                            width="89px"
                            bg="#918E96"
                            shadow="2px 2px 2px 2px #918E96"
                            style={{ color: "white" }}
                            height="35px"
                            color="white"
                            f_weight="550"
                            f_size="20px"
                          >
                            Back
                          </SaveBtn>
                        )}

                        {show_submit && !show_next && (
                          <>
                            <SaveBtn
                              onClick={() => {
                                let param = elem.correct_answers.length
                                  ? elem.correct_answers[0].id
                                  : elem.one_word_answer;

                                return handleSubmit(param, index, elem?.mark);
                              }}
                              className="next_btn_self"
                              width="89px"
                              bg="#FF6700"
                              shadow="2px 2px 2px 2px #FF6700"
                              height="35px"
                              color="white"
                              f_weight="550"
                              f_size="20px"
                            >
                              Next
                            </SaveBtn>
                          </>
                        )}

                        {show_next &&
                          !show_submit &&
                          selfTestData.length > Active + 1 && (
                            <>
                              <SaveBtn
                                onClick={() => handleNext(index)}
                                className="next_btn_self"
                                width="89px"
                                bg="#FF6700"
                                shadow="2px 2px 2px 2px #FF6700"
                                color="white"
                                style={{ width: "89px" }}
                                height="35px"
                                f_weight="550"
                                f_size="20px"
                              >
                                Next
                              </SaveBtn>
                            </>
                          )}
                        {show_next &&
                          !show_submit &&
                          selfTestData.length === Active + 1 && (
                            <SaveBtn
                              onClick={() => handle_submit_test()}
                              className="next_btn_self"
                              width="89px"
                              bg="#FF6700"
                              shadow="2px 2px 2px 2px #FF6700"
                              height="35px"
                              color="white"
                              f_weight="550"
                              f_size="20px"
                            >
                              Submit
                            </SaveBtn>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))
      );
    }
  };
  return (
    <>
      <div
        className="selfTest_bg comic_font"
        style={{ backgroundColor: "#F8CE56" }}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="top_math">
          SqillUP: {season_data.sub} Season activity
        </div>
        <div className="d-flex justify-content-between">
          <div className="">
            <Back_btn border="2px solid #8B60FD" style={{ cursor: "default" }}>
              <AccessTimeIcon
                style={{
                  fontSize: "19px",
                  background: "#39311C",
                  color: "white",
                  borderRadius: "50%",
                  marginTop: "-3px",
                  marginRight: "3px",
                }}
              />
              {formatTime(timeLeft)}
            </Back_btn>
          </div>
          <div className="num_pagination">
            {initial_index !== 0 && qstn_no_arr.length > 10 && (
              <div className="mt-1" onClick={() => handleBackward()}>
                <ArrowBackIosIcon style={{ color: "#8B60FD" }} />
              </div>
            )}
            {qstn_no_arr?.map((elem, index) => (
              <div key={index}>
                {index <= final_index && index >= initial_index && (
                  <>
                    {index === Active ? (
                      <>
                        <div
                          className="active_qtn_num"
                          onClick={() => {
                            handle_no_click(index);
                          }}
                          key={index}
                        >
                          {index + 1}
                        </div>
                      </>
                    ) : (
                      <>
                        {elem.correct === "" ? (
                          <div
                            className="qtn_num"
                            onClick={() => {
                              handle_no_click(index);
                            }}
                            key={index}
                          >
                            {index + 1}
                          </div>
                        ) : (
                          <>
                            {!elem.correct ? (
                              <div
                                className="qtn_num"
                                style={{
                                  backgroundColor: "#EA1C1C",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={() => {
                                  handle_no_click(index);
                                }}
                                key={index}
                              >
                                {index + 1}
                              </div>
                            ) : (
                              <div
                                className="qtn_num"
                                style={{
                                  backgroundColor: "#2BB10D",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={() => {
                                  handle_no_click(index);
                                }}
                                key={index}
                              >
                                {index + 1}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
            {final_index + 1 !== qstn_no_arr.length &&
              qstn_no_arr.length > 10 && (
                <div className="mt-1 mx-2" onClick={() => handleForward()}>
                  <ArrowForwardIosIcon style={{ color: "#8B60FD" }} />
                </div>
              )}
          </div>
          <div className="">
            <Back_btn border="2px solid #8B60FD" onClick={() => handleClose()}>
              Close
            </Back_btn>
          </div>
        </div>
        {!loading ? (
          renderQuestion()
        ) : (
          <div className="d-flex justify-content-center w-100">
            <ClipLoader
              color="black"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}

        <div className="chptr_name_selftest d-flex justify-content-start mt-2">
          {season_data.season}: {season_data.week} paper
        </div>
      </div>
      <SuccessModal
        title={"Are you sure? You want to submit your Season activity test?"}
        show={submit_test}
        onHide={() => setsubmit_test(!submit_test)}
        myCallback={(param) => {
          console.log("param = ", param);
          if (param == true) {
            setScore_modal(param);
            submit_score();
          } else if (param == false) {
            setsubmit_test(param);
          }
        }}
      />
      <ScoreModal
        title={"You have successfully completed Season activity test."}
        onClick={() => handleClose()}
        totalScore={scoreTotal}
        obtainedScore={score}
        subTitle={" Score obtained"}
        show={score_modal}
        onHide={() => setScore_modal(!setScore_modal)}
      />
    </>
  );
}

export default Selftest_sa;
