import React from "react";
import { Progress_btn } from "./Style/Styled";

const SubjectBar = ({ all_subject, active, set_active, boxShadow, margin }) => {
  return (
    <div
      className="d-flex header_prg"
      style={{ margin: margin, boxShadow: boxShadow }}
    >
      {all_subject?.map((elem, index) => (
        <div key={index}>
          <Progress_btn
            bg={active == elem.id ? elem.color : "#D6D6D6"}
            onClick={() => set_active(elem.id)}
          >
            {/* {elem.name} */}
            {elem?.name.length > 10
              ? `${elem?.name.slice(0, 6)}...${elem?.name.slice(
                  elem?.name.length - 2,
                  elem?.name.length
                )}`
              : elem?.name}
          </Progress_btn>
        </div>
      ))}
    </div>
  );
};

export default SubjectBar;
