import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { no_data } from "../../Assets/Images";

function Instruction() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    const data = localStorage.getItem("pp_qstn");
    setData(JSON.parse(data));
    const modes = localStorage.getItem("mode");
    setMode(modes);
  }, []);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  const handleStartClick = () => {
    if (mode == "learn") {
      navigate("/learn-challenge", { state: state });
    } else {
      navigate("/test-challenge", { state: state });
    }
  };

  const handleClose = () => {
    navigate("/challenge");
    localStorage.removeItem("challenge");
  };

  return (
    <>
      {data?.length === 0 && (
        <>
          {loading && (
            <div className=" mt-5">
              <ClipLoader
                color="black"
                loading={loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          {!loading && (
            <>
              <div className="no_data_img">
                <img src={no_data} alt="" />
              </div>
            </>
          )}
        </>
      )}

      {data && (
        <div className="past_paper">
          <div className="d-flex justify-content-end mb-3">
            <div className="  closese" onClick={() => handleClose()}>
              Close
            </div>
          </div>

          <img src={data?.instruction} alt="" className="cover_image_test" />

          <div className="d-flex justify-content-end">
            <div
              className="btn_in mb-3"
              type="submit"
              onClick={handleStartClick}
            >
              Start
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Instruction;
