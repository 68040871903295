import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./Test.css";
import { close, no_data } from "../../../Assets/Images";

import axios from "axios";
import ScoreModal from "../../ScoreModal";
import ClipLoader from "react-spinners/ClipLoader";
import StructuredQues from "./StructuredQues";

function Test() {
  const [data, setData] = useState();

  const [timeLeft, setTimeLeft] = useState();
  const [show_sol, setShow_sol] = useState(false);
  const [Active, setActive] = useState(0);
  const [show_modal, setShowModal] = useState(false);
  const [initial_index, setInitial_index] = useState(0);
  const [final_index, setFinal_index] = useState(9);
  const [question, setQuestion] = useState([]);
  const [child, setChild] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hideButton, setHideButton] = useState("block");
  const [back, setBack] = useState(true);
  const [autoHide, setAutoHide] = useState(true);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [studentId, setStudentId] = useState();
  const [userData, setUserData] = useState();

  const pastPaperQuestion = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/past-paper-question`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        const filterQuestion = resp.data.filter(
          (item) => item.paper_id == state?.id
        );
        setQuestion(filterQuestion);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      pastPaperQuestion();
    }
    const arr = JSON.parse(localStorage.getItem("pp_qstn"));
    if (arr) {
      setData(arr);
    }

    const userId = localStorage.getItem("User");
    const student = JSON.parse(userId);
    setStudentId(student.id);
  }, []);

  useEffect(() => {
    if (data) {
      let time =
        Number(data.duration_hours) * 60 * 60 +
        Number(data.duration_minutes) * 60;
      setTimeLeft(time);
    }
  }, [data]);

  const formatTime = (time) => {
    let hours = Math.floor(time / (60 * 60));

    let divisor_for_minutes = time % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return `${hours}:${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const handle_no_click = (index) => {
    setActive(index);
    setChild(0);
  };
  const handle_back_click = (index) => {
    setShow_sol(false);
    if (
      (Active !== 0 && child === 0) ||
      question[Active].sub_question.length === 0
    ) {
      setActive(Active - 1);
      setChild(question[Active - 1].sub_question.length - 1);
      if (final_index === Active) {
        setFinal_index(final_index - 1);
        setInitial_index(initial_index - 1);
      }
    } else {
      setChild(child - 1);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handle_next_click = async () => {
    setShow_sol(false);

    if (
      (Active !== question?.length - 1 &&
        child == question[Active].sub_question.length - 1) ||
      question[Active].sub_question.length === 0
    ) {
      setActive(Active + 1);
      setChild(0);
      if (final_index === Active) {
        setFinal_index(final_index + 1);
        setInitial_index(initial_index + 1);
      }
    } else {
      setChild(child + 1);
    }
  };
  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  const handle_Submit = async () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    if (state?.isRivision == true) {
      navigate("/revision");
    } else {
      navigate("/pastpaper");
    }
    localStorage.removeItem("solution");
    localStorage.removeItem("active_qstn");
    localStorage.removeItem("selftest_qstn_arr");
    localStorage.removeItem("Time");
    localStorage.removeItem("mode");
    localStorage.removeItem("theory");
    localStorage.removeItem("pp_qstn");
    localStorage.removeItem("challenge");
  };
  const handleRecheck = () => {
    setShowModal(false);
    navigate("/checkanswer", { state: state });
  };

  const handleForward = () => {
    if (final_index + 1 !== question?.length) {
      setFinal_index(final_index + 1);
      setInitial_index(initial_index + 1);
    }
  };

  const handleBackward = () => {
    if (initial_index > 0) {
      setFinal_index(final_index - 1);
      setInitial_index(initial_index - 1);
    }
  };

  const handleAutoCheck = () => {
    const data = question[Active];
    if (
      question[Active]?.answers == null ||
      question[Active]?.sub_question[child]?.answers == null
    ) {
      setShow_sol(true);
      setAutoHide(false);
    }
  };
  const handleNavigate = () => {
    if (state?.isRivision == true) {
      navigate("/revision");
    } else {
      navigate("/pastpaper");
    }
  };
  useEffect(() => {
    if (Active === 0 && child === 0) {
      setBack(false);
    } else {
      if (Active === 0 && question[Active].sub_question.length === 0) {
        setBack(false);
      } else {
        setBack(true);
      }
    }
  }, [Active, child]);

  useEffect(() => {
    if (Active === question.length - 1) {
      if (
        question[Active].sub_question.length - 1 === child ||
        question[Active].sub_question.length == 0
      ) {
        setHideButton("none");
      }
    } else {
      setHideButton("block");
    }
  }, [Active, child]);

  useEffect(() => {
    const user = localStorage.getItem("User");
    setUserData(JSON.parse(user));
  }, []);
  const [doQuestionByLesson, setDoQuestionByLesson] = useState([]);
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const getDoSelftestQuestion = async () => {
    const token = localStorage.getItem("token");
    const studentID = userData?.id;

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-past-paper-question/${studentID}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        const doQuestion = resp?.data.filter(
          (lesson) => lesson.paper_id == state?.id
        );
        setDoQuestionByLesson(doQuestion);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (doQuestionByLesson.length > 0) {
      postDoQuestionAttemp();
    }
  }, [doQuestionByLesson]);

  const countTotalQuestions = (questions) => {
    let totalQuestions = 0;

    questions.forEach((q) => {
      if (q.sub_question && q.sub_question.length > 0) {
        totalQuestions += q.sub_question.length;
      } else {
        totalQuestions += 1;
      }
    });

    return totalQuestions;
  };

  const postDoQuestionAttemp = async (score) => {
    const studentID = userData.id;
    const token = localStorage.getItem("token");
    // Make the API request
    // const filterQuestionbyLesson = question.filter(
    //   (detail) => detail.paper_id == state?.id
    // );

    try {
      let bodyData = new FormData();
      bodyData.append("student_id", studentID);
      bodyData.append("paper_id", state?.id);
      bodyData.append("sorce", state?.score?.correct_score);
      bodyData.append("total_question", countTotalQuestions(question));
      bodyData.append("complete_question", doQuestionByLesson.length);
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-past-paper-attempt`,
        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp?.data?.status === "Successfully Created!") {
        deleteDoSelftestQuestion();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const deleteDoSelftestQuestion = async () => {
    const studentID = userData.id;
    const token = localStorage.getItem("token");
    // Make the API request
    const postData = {
      student_id: studentID,
      paper_id: state?.id,
    };
    try {
      // let bodyData = new FormData();
      // bodyData.append("student_id", studentID);
      //  bodyData.append("lesson_id", id);

      await axios.delete(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-past-paper-question-delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: postData,
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (question.length > 0 && !questionsLoaded) {
      const oldTime = localStorage.getItem("Time");
      if (oldTime) {
        return;
      }
      setQuestionsLoaded(true); // Mark questions as loaded
      getDoSelftestQuestion();
    }
  }, [question, questionsLoaded]);
  return (
    <>
      {data?.question_type == "Structured question" ? (
        <>
          <div className="pp_test_container">
            <div className="d-flex gap-3 justify-content-between align-items-center w-100 ">
              <div className="pp_timer">{formatTime(timeLeft)}</div>

              <div className="num_pagination">
                {initial_index !== 0 && question?.length > 10 && (
                  <div className="mt-1" onClick={() => handleBackward()}>
                    <ArrowBackIosIcon style={{ color: "#8B60FD" }} />
                  </div>
                )}

                {question?.map((elem, index) => {
                  return (
                    <div key={index}>
                      {index <= final_index && index >= initial_index && (
                        <>
                          {index === Active ? (
                            <div className="mx-1">
                              <div
                                className="pp_index_active"
                                onClick={() => {
                                  handle_no_click(index);
                                }}
                              >
                                {index + 1}
                              </div>
                              <div className="d-flex mt-2">
                                {elem?.sub_question?.length > 0 &&
                                  elem?.sub_question?.map((item, subIndex) => {
                                    return (
                                      <div
                                        key={subIndex} // Add key here
                                        className={
                                          subIndex === child
                                            ? `me-2 pp_index_active`
                                            : "me-2 pp_index"
                                        }
                                        onClick={() => setChild(subIndex)}
                                      >
                                        {subIndex + 1}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ) : (
                            <div
                              key={index} // This key is not necessary as it is already on the parent div
                              className="mx-1"
                              onClick={() => {
                                handle_no_click(index);
                              }}
                            >
                              <div className="pp_index">{index + 1}</div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}

                {final_index + 1 !== question?.length &&
                  question?.length > 10 && (
                    <div className="mt-1 mx-2" onClick={() => handleForward()}>
                      <ArrowForwardIosIcon style={{ color: "#8B60FD" }} />
                    </div>
                  )}
              </div>

              {!show_sol && (
                <div
                  className="pp_close new-btn"
                  onClick={() => handleNavigate()}
                >
                  Close
                </div>
              )}
              {show_sol && (
                <img
                  src={close}
                  alt=""
                  onClick={() => {
                    setShow_sol(false);
                    setAutoHide(true);
                  }}
                  className="pp_close_icon"
                />
              )}
            </div>

            <div className="d-flex justify-content-center mt-3">
              {show_sol ? (
                <div className="d-flex justify-content-center mt-3">
                  {question[Active]?.sub_question.length === 0 ? (
                    <>
                      {
                        <div className="pp_test_white_div">
                          <img src={question[Active]?.sa_answer} alt="answer" />
                        </div>
                      }
                    </>
                  ) : (
                    <>
                      {
                        <div className="pp_test_white_div">
                          <img
                            src={
                              question[Active]?.sub_question[child]?.sa_answer
                            }
                            alt="answer"
                          />
                        </div>
                      }
                    </>
                  )}
                </div>
              ) : question[Active] && question[Active]?.sub_question == 0 ? (
                <div>
                  <div className="pp_test_white_div">
                    <div className="pp_test_white_div img-fluid">
                      {question[Active]?.question?.type == "image" ? (
                        <figure className="image">
                          <img
                            src={question[Active]?.question?.content}
                            alt="question"
                          />
                        </figure>
                      ) : (
                        <p>{question[Active]?.question?.content}</p>
                      )}
                      {question[Active]?.sub_question_text && (
                        <p className="subQuestionText">
                          {question[Active]?.sub_question_text}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    {question[Active]?.sub_question.map((item, index) => {
                      return (
                        <div key={index}>
                          {index === child && (
                            <div className="pp_test_white_div">
                              <div
                                className="pp_test_white_div img-fluid"
                                // dangerouslySetInnerHTML={{
                                //   __html: item?.question,
                                // }}
                              >
                                {item?.question?.type == "image" ? (
                                  <figure className="image">
                                    <img
                                      src={item?.question?.content}
                                      alt="question"
                                    />
                                  </figure>
                                ) : (
                                  <p>{item?.question?.content}</p>
                                )}
                                {item?.sub_question_text && (
                                  <p className="subQuestionText">
                                    {item?.sub_question_text}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            {!loading ? (
              <>
                {question.length === 0 ? (
                  <div className="no_data1_img" style={{ left: "0" }}>
                    <img src={no_data} alt="" />
                  </div>
                ) : (
                  <div className="postDiv">
                    <div className="pp_year d-flex justify-content-start">
                      {data?.year}
                    </div>
                    <div className="d-flex justify-content-center pp_test_btn">
                      {back && !show_sol && (
                        <div
                          className="mx-2 pp_back_btn"
                          onClick={() => handle_back_click()}
                        >
                          Back
                        </div>
                      )}

                      {!show_sol && (
                        <div
                          className="mx-2 pp_auto_check_btn d-none"
                          onClick={() => handleAutoCheck()}
                        >
                          {"Auto Check"}
                        </div>
                      )}
                      {/* {hideButton === "block" && autoHide && ( */}
                      <>
                        {(Active !== question?.length - 1 ||
                          (Active === question?.length - 1 &&
                            question[Active]?.sub_question.length > 0 &&
                            child !==
                              question[Active]?.sub_question.length - 1)) && (
                          <div
                            className="mx-2 pp_back_btn"
                            onClick={handle_next_click}
                          >
                            Next
                          </div>
                        )}
                      </>
                      {/* )} */}

                      {(Active === question?.length - 1 &&
                        question[Active]?.sub_question.length === 0 &&
                        !show_sol) ||
                      (Active === question?.length - 1 &&
                        child === question[Active]?.sub_question.length - 1 &&
                        !show_sol) ? (
                        <div
                          className="mx-2 pp_back_btn"
                          onClick={handle_Submit}
                        >
                          Submit
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className=" mt-5">
                <ClipLoader
                  color="black"
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
          <ScoreModal
            className="pp_model pp_model_title mt-3"
            title={"You have successfully completed your Past Paper test."}
            onClick={() => handleClose()}
            show={show_modal}
            btn_content={"check your Answer"}
            body={
              "Check your answer and calculate your score. Tick the correct answer"
            }
            btn={() => handleRecheck()}
            onHide={() => setShowModal(!show_modal)}
          />
        </>
      ) : (
        <StructuredQues
          question={question}
          loading={loading}
          data={data}
          state={state}
        />
      )}
    </>
  );
}

export default Test;
