import React from "react";
import Modal from "react-bootstrap/Modal";
import { Document, Page, pdfjs } from "react-pdf";
import { close } from "../../Assets/Images"; // Ensure correct path to close image
import "./styles.css"; // Ensure correct path to CSS

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.worker.min.js`;

function PDFModal({
  open,
  scale,
  pageNumber,
  page,
  numPages,
  previousPage,
  myCallback,
  nextPage,
  handleClose,
  selectedChapterUrl,
}) {
  return (
    <Modal show={open} onHide={handleClose} dialogClassName="pdf-modal">
      <Modal.Body
        className="pdfModalBody"
        style={{
          borderTop: "8px solid #8B60FD",
          borderLeft: "8px solid #8B60FD",
          borderRight: "8px solid #8B60FD",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
        }}
      >
        <div className="pdfModal">
          <div className="close_btn_notification mt-2">
            <img
              src={close}
              alt="close"
              height={35}
              width={35}
              onClick={handleClose}
            />
          </div>
          <div className="react-pdf__Page__canvas col-12 mt-5">
            <Document file={selectedChapterUrl} onLoadSuccess={myCallback}>
              <Page pageNumber={page} scale={scale || 1.0} />
            </Document>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          borderBottom: "8px solid #8B60FD",
          borderLeft: "8px solid #8B60FD",
          borderRight: "8px solid #8B60FD",
          borderBottomLeftRadius: "60px",
          borderBottomRightRadius: "60px",
        }}
      >
        {selectedChapterUrl && (
          <div className="d-flex justify-content-center w-100 mt-2">
            <div className="button_ms">
              <div className="pagec">
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </div>
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
                className="Pre_btn"
              >
                Previous
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                className="Pre_btn mx-2"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default PDFModal;
