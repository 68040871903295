import React from "react";
import { Modal } from "react-bootstrap";
import "./SummaryModal.css"; // Import your custom styles
import { purplecloseBtn } from "../../Assets/Images/index";
import { useApiContext } from "../../Context/ApiContext";

const SummaryModal = ({ show, handleClose, data }) => {
  const { exam_type } = useApiContext();
  return (
    <Modal show={show} onHide={handleClose} className="summary-modal" centered>
      <Modal.Body>
        <div className=" mb-3">
          <div className="d-flex justify-content-end align-items-center">
            <img src={purplecloseBtn} alt="Close" onClick={handleClose} />
          </div>
          <h5 className="modal-title mb-0 d-flex justify-content-center align-items-center">
            <div className="">Summary</div>
          </h5>
        </div>
        <div className="summary-content">
          {Object.values(data)?.length > 0 ? (
            <>
              {Object.values(data)?.map((item, index) => {
                const subName = exam_type.find(
                  (detail) => detail.id === item.type_id
                );

                return (
                  <div key={index} className="d-flex mb-3 mainBox">
                    <div className="test-name">
                      <div className="">{subName?.type}</div>
                    </div>
                    <div className="score-board">
                      {/* <div className="boxed">
                            <img src={item.image} alt="Image 1" className="img-fluid" />
                            <div className="number">{item.reward}</div>
                          </div> */}
                      <div className="boxed">
                        <img
                          src={item.image}
                          alt="Image 2"
                          className="img-fluid"
                        />
                        <div className="number">{item?.total_reward}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="d-flex mb-3 mainBox">No collection found</div>
            </>
          )}

          <div className="total">
            Total Earned reward:{" "}
            {Object.values(data).reduce(
              (sum, data) => sum + data.total_reward,
              0
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SummaryModal;
