import styled from "styled-components";

export const Button = styled.button`
  border: none;
  background-color: ${({ bgColor }) => bgColor};
  color: black;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 30px;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 800;

  padding-top: 12px;
  border: ${({ border }) => border};
  box-shadow: 2px 2px 2px 2px #facd51;
`;

export const SaveBtn = styled.button`
  background-color: ${({ bg }) => bg};
  border: 2px solid white;
  box-shadow: ${({ shadow }) => shadow};
  border-radius: 20px;
  max-width: ${({ width }) => width};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-size: ${({ f_size }) => f_size};
  color: ${({ color }) => color};
  height: ${({ height }) => height};
  font-weight: ${({ f_weight }) => f_weight};
`;

export const Progress_btn = styled.button`
  background-color: ${({ bg }) => bg};
  border: none;
  border-radius: 5px;
  width: 120px;
  height: 45px;
  color: white;
  margin: 5px;
  font-size: 18px;
`;

export const Back_btn = styled.button`
  border: ${({ border }) => border};
  background-color: #fdf980;
  color: black;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 6px;
  width: 98px;
  height: 47px;
  font-size: 19px;
  font-weight: 500;
  // padding-top: 5px;
  cursor: pointer;
`;

export const PostQuestion = styled.button`
  background-color: white;
  color: #ff6700;
  padding-left: 4px;
  padding-right: 4px;
  width: 179px;
  height: 39px;
  font-size: 15px;
  font-weight: 500;
  // padding-top: 5px;
  cursor: pointer;
  border: 1px solid #ff6700;
  border-radius: 6px;
  @media (max-width: 374px) {
    width: 80px;
  }
`;

// background: linear-gradient(180.55deg, #F9CE54,#F8CF57,#F1D771,#E9E18F,#E4E69E);
