import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { close } from "../../../Assets/Images";
import "./Solution.css";

function Solution() {
  const [open, setOpen] = useState("Solution");
  const [lesson_id, setLesson_id] = useState("");
  const [sol_data, setSol_data] = useState();
  const [theory_data, setTheory_data] = useState();
  const { state } = useLocation();
  const { id, isSeasionActivity, data, type, time } = state;
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("solution"));
    if (data) {
      setSol_data(data);
    }
    const theory = JSON.parse(localStorage.getItem("theory"));
    if (theory) {
      setTheory_data(theory);
    }
    const id = localStorage.getItem("lesson_id");
    if (id) {
      setLesson_id(id);
    }
  }, []);
  const navigate = useNavigate();
  const closeTest = () => {
    const isRivision = localStorage.getItem("isRivision");
    localStorage.setItem("Time", time);
    if (isSeasionActivity == true) {
      navigate(`/s_test/${id}`, { state: { data: data, type: type } });
      // navigate(-1)
    } else if (isRivision) {
      navigate(`/selftest/${id}`, {
        state: { id: id, isRivision: isRivision },
      });
    } else {
      navigate(`/selftest/${lesson_id}`, { state: { isRivision: isRivision } });
    }
  };
  return (
    <div className="outer comic_font">
      <img
        src={close}
        alt=""
        className="close_solution"
        onClick={() => closeTest()}
        // onClick={() =>}
      />
      <div>
        <div className="tab_div">
          <div
            className={open === "Solution" ? "inner_left_active" : "inner_left"}
            onClick={() => setOpen("Solution")}
          >
            Solution
          </div>

          <div
            className={open === "Theory" ? "inner_left_active" : "inner_left"}
            onClick={() => setOpen("Theory")}
          >
            Theory
          </div>
        </div>
        <div className="inner">
          <div className="d-flex justiy-content-center">
            {open === "Solution" && (
              <div className="txt">
                {sol_data?.type == "image" ? (
                  <div>
                    <figure className="image">
                      <img
                        className="w-100"
                        src={sol_data?.content}
                        alt="question"
                      />
                    </figure>
                  </div>
                ) : (
                  <div>
                    <p>{sol_data?.content}</p>
                  </div>
                )}
              </div>
            )}
            {open === "Theory" && (
              <div className="txt">
                {theory_data?.type == "image" ? (
                  <div>
                    <figure className="image">
                      <img
                        className="w-100"
                        src={theory_data?.content}
                        alt="question"
                      />
                    </figure>
                  </div>
                ) : (
                  <div>
                    <p>{theory_data?.content}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solution;
