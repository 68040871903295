import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { close } from "../../Assets/Images";

// import "./Solution.css";

function MockSolution() {
  const [open, setOpen] = useState("Solution");
  const [lesson_id, setLesson_id] = useState("");
  const [sol_data, setSol_data] = useState();
  const [theory_data, setTheory_data] = useState();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("solution"));
    if (data) {
      setSol_data(data);
    }
    const theory = JSON.parse(localStorage.getItem("theory"));
    if (theory) {
      setTheory_data(theory);
    }
    const id = localStorage.getItem("lesson_id");
    if (id) {
      setLesson_id(id);
    }
  }, []);
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className="outer comic_font">
      <img
        src={close}
        alt=""
        className="close_solution"
        onClick={handleBackButtonClick}
        // onClick={() =>}
      />
      <div>
        <div className="tab_div">
          <div
            className={open === "Solution" ? "inner_left_active" : "inner_left"}
            onClick={() => setOpen("Solution")}
          >
            Solution
          </div>

          <div
            className={open === "Theory" ? "inner_left_active" : "inner_left"}
            onClick={() => setOpen("Theory")}
          >
            Theory
          </div>
        </div>
        <div className="inner">
          <div className="d-flex justiy-content-center">
            {open === "Solution" && (
              <div className="txt">
                {sol_data?.type == "image" ? (
                  <div>
                    <figure className="image">
                      <img
                        className="w-100"
                        src={sol_data?.content}
                        alt="question"
                      />
                    </figure>
                  </div>
                ) : (
                  <div>
                    <p>{sol_data?.content}</p>
                  </div>
                )}
              </div>
            )}
            {open === "Theory" && (
              <div className="txt">
                {theory_data?.type == "image" ? (
                  <div>
                    <figure className="image">
                      <img
                        className="w-100"
                        src={theory_data?.content}
                        alt="question"
                      />
                    </figure>
                  </div>
                ) : (
                  <div>
                    <p>{theory_data?.content}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MockSolution;
