import React, { useEffect, useState, useRef } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../PastPaper/PastPaper.css";
import "./Curriculum.css";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { no_data, aqa, download_gray, close } from "../../Assets/Images";
import "../Revison/Iframe.css";
import PDFModal from "../PdfModal";
import SubjectBar from "../SubjectBar";
import { useApiContext } from "../../Context/ApiContext";

function Curriculum() {
  const { all_subject, curriculumCourse, apiLoading } = useApiContext();
  const [loading, setLoading] = useState(true);
  const [active, set_active] = useState();
  const [open, setOpen] = useState(false);
  const [cir_data, setCir_data] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedChapterUrl, setSelectedChapterUrl] = useState("");
  const [syllabusd, setSyllabusd] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [pageScale, setPageScale] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
  }

  function handleZoomIn() {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  }
  // function previousPage() {
  //   changePage(-1);
  // }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  // function nextPage() {
  //   changePage(1);
  // }
  function handleZoomOut() {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }

  // function handleNext() {
  //   if (pageNumber < totalPages) {
  //     setPageNumber(pageNumber + 1);
  //   }
  // }
  // function handlePrevious() {
  //   if (pageNumber > 0) {
  //     setPageNumber(pageNumber - 1);
  //   }
  // }
  useEffect(() => {
    if (all_subject) {
      set_active(all_subject[0]?.id);
    }
  }, [all_subject]);

  useEffect(() => {
    const filterData = curriculumCourse.filter(
      (chapter) => chapter.subject_id === active
    );
    setCir_data(filterData);
  }, [active, curriculumCourse]);

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  useEffect(() => {
    for (let i = 0; i < cir_data?.length; i++) {
      setSyllabusd(cir_data[0].curriculum_file);
    }
  }, [active]);

  const handleDownload = (pdf) => {
    fetch(pdf)
      .then((response) => {
        if (!response.ok) {
          console.error("Network response was not ok");
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);

        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = pdf.split("/").pop(); // Extracting filename from URL
        alink.click();
      })
      .catch((error) => {
        console.error("Error fetching or creating blob:", error);
      });
  };
  const handlePdfOpen = (pdfFile) => {
    setPageNumber(1);
    setSelectedChapterUrl(pdfFile);
  };

  const previousPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));
  const nextPage = () => setPageNumber((prev) => Math.min(prev + 1, numPages));

  const boxShadow = "unset";
  const margin = 0;
  return (
    <>
      <div className="comp_container comic_font">
        <TopNavBar />
        <div
          className="d-flex justify-content-between header_prg"
          style={{ padding: "unset" }}
        >
          <SubjectBar
            all_subject={all_subject}
            active={active}
            set_active={set_active}
            boxShadow={boxShadow}
            margin={margin}
          />
          <div className="download_aqa_div">
            <img src={aqa} alt="aqa" />
            {show && (
              <div className="download_div">Download syllabus from here</div>
            )}
            <div
              className="download_btn d-flex justify-content-between"
              onMouseOver={() => setShow(true)}
              onMouseOut={() => setShow(false)}
              onClick={() => handleDownload(syllabusd)}
            >
              Download syllabus
              <span>
                <img src={download_gray} alt="download" />
              </span>
            </div>
          </div>
        </div>
        <div className="sideDiv carriculamSideDiv pe-2">
          <div className="w-100">
            <div className="download_aqa_div_mobile mt-3">
              <img src={aqa} alt="" />
              <div className="download_btn d-flex justify-content-between">
                Download syllabus
                <span>
                  <img src={download_gray} alt="" />
                </span>
              </div>
            </div>
            {/* {active === "Maths" && ( */}
            <>
              {cir_data?.length === 0 && (
                <>
                  {apiLoading?.curriculum_load ? (
                    <div className=" mt-5">
                      <ClipLoader
                        color="black"
                        loading={loading}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="no_data_img">
                        <img src={no_data} alt="" />
                      </div>
                    </>
                  )}
                </>
              )}
              {cir_data?.length !== 0 && (
                <div className="d-flex mt-3 revesion_top_table_div">
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                    <table className="cir_table_container_carriculum">
                      <tbody>
                        <tr className="cir_table_head">
                          <td
                            className="cir_head_text_no chaptr_no_width"
                            style={{
                              borderRadius: " 8px 0px 0px 0px",
                              fontWeight: "500",
                            }}
                          >
                            Chapter no.
                          </td>
                          <td
                            className="cir_head_text"
                            style={{
                              borderRadius: "0px 8px 0px 0px",
                              fontWeight: "500",
                            }}
                          >
                            Chapter name
                          </td>
                        </tr>

                        {cir_data[0]?.lesson?.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              className="revesion_table_content"
                              style={{ height: "76px" }}
                            >
                              <td
                                className="cir_content_text"
                                onClick={() => {
                                  handlePdfOpen(item?.lesson_plan); // Update this with the correct path from your API
                                  setOpen(true);
                                }}
                              >
                                {item?.chapter_number}
                              </td>
                              <td
                                className="cir_content_text"
                                onClick={() => {
                                  handlePdfOpen(item?.lesson_plan); // Update this with the correct path from your API
                                  setOpen(true);
                                }}
                              >
                                <div className="d-flex justify-content-between">
                                  <span>{item?.chapter_name}</span>
                                  <div className="forward_arr">
                                    <ArrowForwardIosIcon
                                      style={{ color: "#8B60FD" }}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
            {/* {active === "Science" && (
              <>
                {cir_data?.length === 0 && (
                  <>
                    {loading && (
                      <div className=" mt-5">
                        <ClipLoader
                          color="black"
                          loading={loading}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                    {!loading && (
                      <>
                        <div className="no_data_img">
                          <img src={no_data} alt="" />
                        </div>
                      </>
                    )}
                  </>
                )}
                {cir_data?.length !== 0 && (
                  <div className="d-flex mt-3 revesion_top_table_div">
                    <div>
                      <table className="cir_table_container_carriculum me-3">
                        <tbody>
                          <tr className="cir_table_head">
                            <td
                              className="cir_head_text_no chaptr_no_width"
                              style={{ borderRadius: " 8px 0px 0px 0px" }}
                            >
                              Chapter no.
                            </td>

                            <td
                              className="cir_head_text"
                              style={{ borderRadius: "0px 8px 0px 0px" }}
                            >
                              Chapter name
                            </td>
                          </tr>

                          {cir_data?.map((item, index) => (
                            <tr
                              key={index}
                              className="revesion_table_content"
                              style={{ height: "76px" }}
                            >
                              <td
                                className="cir_content_text"
                                onClick={() => setOpen(true)}
                              >
                                {item?.number}
                              </td>
                              <td
                                className="cir_content_text"
                                onClick={() => setOpen(true)}
                              >
                                <div className="d-flex justify-content-between">
                                  <span>{item?.name}</span>
                                  <div className="forward_arr">
                                    <ArrowForwardIosIcon
                                      style={{ color: "#8B60FD" }}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )} */}
            {/* {cir_data?.length !== 0 && totalPage > 1 && (
              <div className="pb-3 mt-2">
                <div
                  className={
                    showRow_pp
                      ? "row_drop_open d-flex justify-content-center"
                      : "d-flex justify-content-center"
                  }
                >
                  <div className="dropdown row_dropdown_margin">
                    <button
                      className="row_dropbtn"
                      onClick={() => setShowRow_pp(!showRow_pp)}
                    >
                      <div className="d-flex justify-content-between px-1">
                        <span> {Row_data}</span>
                        <KeyboardArrowDownIcon />
                      </div>
                    </button>

                    {showRow_pp && (
                      <div className="d-flex justify-content-center mx-3">
                        <div className="row_dropdown-content">
                          {arr.map((elem, index) => (
                            <a
                              onClick={() => handle_row_click(elem)}
                              key={index}
                              className={
                                Row_data === elem
                                  ? "selected_row"
                                  : "selecting_row"
                              }
                            >
                              {elem}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-center mt-1">
                    <Stack spacing={0}>
                      <Pagination
                        color="primary"
                        defaultPage={page}
                        count={totalPage}
                        onChange={(event, page) => handlePagination(page)}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            )} */}
            {/* {cir_data?.length !== 0 && totalPage > 1 && (
              <div className="d-flex justify-content-center mt-1">
                <Stack spacing={0}>
                  <Pagination
                    color="primary"
                    page={page} // Use the controlled 'page' state here
                    count={totalPage}
                    onChange={(event, newPage) => setPage(newPage)} // Update the 'page' state on change
                  />
                </Stack>
              </div>
            )} */}
          </div>
        </div>
      </div>

      <PDFModal
        open={open}
        scale={0.4}
        onHide={false}
        myCallback={(page) => setNumPages(page._pdfInfo.numPages)}
        page={pageNumber}
        handleClose={() => setOpen(false)}
        selectedChapterUrl={selectedChapterUrl}
        pageNumber={pageNumber}
        numPages={numPages}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </>
  );
}

export default Curriculum;
