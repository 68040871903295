/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "./Mock.css";
import TopNavBar from "../TopNavBar/TopNav";
import { no_data, courses } from "../../Assets/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubjectBar from "../SubjectBar";
import { useApiContext } from "../../Context/ApiContext";

function Mock() {
  const { all_subject } = useApiContext();
  const [math_loading, setMath_Loading] = useState(true);
  const [all_chapter, setAll_chapter] = useState([]);
  const [math_data, setMath_data] = useState([]);
  const [active, set_Active] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const storedActive = localStorage.getItem("subId");
    if (storedActive) {
      set_Active(storedActive);
    } else {
      set_Active(all_subject[0]?.id);
    }
  }, [all_subject]);
  useEffect(() => {
    getMockTest();
  }, []);

  const getMockTest = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/mock-test`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setAll_chapter(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setMath_Loading(false);
    }
  };

  useEffect(() => {
    const filterData = all_chapter.filter(
      (chapter) => chapter.subject_id == active
    );
    setMath_data(filterData);
  }, [active, all_chapter]);

  const handle_start_test = (item) => {
    localStorage.setItem("subId", active);
    navigate("/mock-test", { state: item });
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };
  const boxShadow = "unset";
  // const margin = 0;

  return (
    <>
      <div
        className="comp_container comic_font"
        style={{ backgroundColor: "#fff" }}
      >
        <TopNavBar />
        <div
          style={{
            padding: "5px 5px 0",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 6px #00000029",
          }}
        >
          <SubjectBar
            all_subject={all_subject}
            active={active}
            set_active={set_Active}
            boxShadow={boxShadow}
          />
        </div>
        <div
          className="sideDiv bg-mock"
          style={{ boxShadow: "box-shadow: 0px 0px 6px #00000029" }}
        >
          <div className="w-100">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="chapter_contaier_div mock-container">
              <>
                {math_data?.length === 0 && (
                  <>
                    {math_loading && (
                      <div className="">
                        <ClipLoader
                          color="black"
                          loading={math_loading}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                    {!math_loading && (
                      <>
                        <div className="no_data_img">
                          <img src={no_data} alt="" />
                        </div>
                      </>
                    )}
                  </>
                )}
                {math_data.length !== 0 && (
                  <div className="mock_grid mt-5">
                    {math_data?.map((elem, index) => (
                      <div className="mock_box" key={index}>
                        <div className="mock_title mt-2 mb-4">
                          {" "}
                          {elem?.mock_no}{" "}
                        </div>
                        <div className="my-4">
                          <img src={courses} alt="mock" />
                        </div>
                        {elem?.score == null ? (
                          <div className="table_data p-2">-</div>
                        ) : (
                          <div className="table_data p-2">
                            {elem?.score?.score_count}/
                            {elem?.score?.total_question_count}
                          </div>
                        )}

                        {elem?.score == null ? (
                          <button
                            className="start-mock-test"
                            style={{
                              border: "unset",
                            }}
                            onClick={() => handle_start_test(elem)}
                          >
                            <span>Test</span>
                          </button>
                        ) : (
                          <button
                            className="start-mock-test"
                            style={{
                              backgroundColor: "#00CB8E",
                              border: "unset",
                            }}
                            onClick={() => handle_start_test(elem)}
                          >
                            <span>Redo</span>
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mock;
