import React, { useEffect, useState } from "react";
import TopNavBar from "../../TopNavBar/TopNav";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reward_reward, badges, no_data } from "../../../Assets/Images/index";
import LockIcon from "@mui/icons-material/Lock";
import "./MyCollection.css";
import "../Rewards.css";
import { Back_btn } from "../../Style/Styled";

function MyCollection() {
  const [active, setActive] = useState("rewards");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [AllRewards, setAllRewards] = useState([]);
  const [AllBadges, setAllBadges] = useState([]);

  useEffect(() => {
    const user = localStorage.getItem("User");
    setUserData(JSON.parse(user));
  }, []);

  const navigate = useNavigate();
  // useEffect(() => {
  //   get_all_rewards();
  //   get_all_Badges();
  // }, []);

  useEffect(() => {
    if (active === "rewards") {
      setLoading(true);
      get_all_rewards();
    } else if (active === "badge") {
      setLoading(true);
      get_all_Badges();
    }
  }, [active]);

  const get_all_rewards = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      const resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewReward`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setAllRewards(resp.data.data);
        setLoading(false);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };
  const get_all_Badges = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      const resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewBadge`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setAllBadges(resp.data.data);
        setLoading(false);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  return (
    <>
      <div
        className="comp_container comic_font"
        style={{
          height: "100%",
          minHeight: "100%",
          backgroundColor: "#0c1341",
        }}
      >
        <TopNavBar />

        <div className="rewards_sideDiv">
          <div className="w-100">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <div className="d-flex justify-content-between head_reward mt-3">
                    <div
                      className="d-flex"
                      onClick={() => setActive("rewards")}
                    >
                      <img
                        width={13}
                        height={13}
                        className="mt-2"
                        src={reward_reward}
                        alt=""
                      />
                      <div
                        className={
                          active === "rewards"
                            ? "active_reward_head mt-1 mx-2"
                            : "reward_head mt-1  mx-2"
                        }
                      >
                        Rewards
                      </div>
                    </div>
                    <div
                      className="d-flex mx-5"
                      onClick={() => setActive("badge")}
                    >
                      <img
                        width={13}
                        height={13}
                        className="mt-2"
                        src={badges}
                        alt=""
                      />
                      <div
                        className={
                          active === "badge"
                            ? "active_reward_head mt-1 mx-2"
                            : "reward_head mt-1 mx-2"
                        }
                      >
                        Badges
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-2 reward_wrapper">
                    <div
                      className={
                        active === "rewards"
                          ? "reward_orange_slider"
                          : "reward_gray_slider"
                      }
                    ></div>
                    <div
                      className={
                        active === "badge"
                          ? "reward_orange_slider"
                          : "reward_gray_slider"
                      }
                    ></div>
                  </div>
                </div>
                <div className="col_btn_desktop">
                  <Back_btn
                    border="1px solid #064974"
                    onClick={() => navigate("/rewards")}
                  >
                    Back
                  </Back_btn>
                </div>
              </div>
              {/* <div className="d-flex">
                <div
                  className={
                    active === "rewards"
                      ? "reward_orange_slider"
                      : "reward_gray_slider"
                  }
                ></div>
                <div
                  className={
                    active === "badge"
                      ? "reward_orange_slider"
                      : "reward_gray_slider"
                  }
                ></div>
              </div> */}
              <div className="d-flex justify-content-between col_eadig ">
                <div className="my_col_head">My Collection</div>
                <div className="col_btn_mobile">
                  <Back_btn
                    border="1px solid #064974"
                    onClick={() => navigate("/rewards")}
                  >
                    Back
                  </Back_btn>
                </div>
              </div>
              {active === "rewards" && (
                <>
                  {AllRewards?.length === 0 && (
                    <>
                      {loading && (
                        <div className="">
                          <ClipLoader
                            color="white"
                            loading={loading}
                            size={100}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      )}
                      {!loading && (
                        <>
                          <div className="no_data_img">
                            <img src={no_data} alt="" />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {AllRewards.length !== 0 && (
                    <div className="d-flex justify-content-center">
                      <div className="mt-3 myCollection_top_div">
                        {AllRewards.map((item, index) => (
                          <div key={index} className="">
                            {item.status === "Locked" ? (
                              <div className="reward_box">
                                <div className="lock_icon">
                                  <LockIcon
                                    style={{
                                      color: "#ff9d00",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                <div className="rewards_img">
                                  <img src={item?.reward_type?.image} alt="" />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="reward_box"
                                style={{ background: "transparent" }}
                              >
                                <div className="rewards_img mt-4">
                                  <img src={item?.reward_type?.image} alt="" />
                                </div>
                              </div>
                            )}

                            <div className="hovering_box">
                              {item.description}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
              {active === "badge" && (
                <>
                  {AllBadges?.length === 0 && (
                    <>
                      {loading && (
                        <div className="">
                          <ClipLoader
                            color="white"
                            loading={loading}
                            size={100}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      )}
                      {!loading && (
                        <>
                          <div className="no_data_img">
                            <img src={no_data} alt="" />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {AllBadges?.length !== 0 && (
                    <div className="d-flex justify-content-center">
                      <div className="mt-3 myCollection_top_div">
                        {AllBadges?.map((item, index) => (
                          <div key={index} className="">
                            {item.status === "Locked" ? (
                              <div className="badge_box">
                                <div className="lock_icon">
                                  <LockIcon
                                    style={{
                                      color: "#ff9d00",
                                      fontSize: "30px",
                                    }}
                                  />
                                </div>
                                <div className="rewards_img">
                                  <img src={item?.badge_type?.image} alt="" />
                                </div>
                                <div className="star_reward mt-1">
                                  {item.title}
                                </div>
                              </div>
                            ) : (
                              <div
                                className="badge_box"
                                key={index}
                                style={{ background: "#2BD1CB", opacity: "1" }}
                              >
                                <div className="rewards_img mt-4">
                                  <img src={item?.badge_type?.image} alt="" />
                                </div>
                                <div className="star_reward mt-1">
                                  {item.title}
                                </div>
                              </div>
                            )}

                            <div className="hovering_box">
                              {item.description}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyCollection;
