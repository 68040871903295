import React, { useState, useEffect } from "react";
import SideNav from "../SideNavBar/SideNav";
import TopNavBar from "../TopNavBar/TopNav";
import ClipLoader from "react-spinners/ClipLoader";
import { gift_gif, board_gif } from "../../Assets/Gif";
import "./UserProfile.css";
import { Tooltip } from "@mui/material";

import { useApiContext } from "../../Context/ApiContext";

function UserProfile() {
  const [eBoard, setEBoard] = useState([]);
  const [schoolName, setSchoolName] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [className, setClassName] = useState([]);
  const [keyStageName, setKeyStageName] = useState([]);
  const [avthar, setAvatar] = useState([]);
  const {
    examBoard,
    keyStage,
    year,
    school,
    country,
    userData,
    loading,
    allAvatar,
  } = useApiContext();

  useEffect(() => {
    if (userData && examBoard) {
      const filteredExamBoard = examBoard.filter(
        (board) => board.id === userData?.student_info?.exam_board_id
      );
      setEBoard(filteredExamBoard);
    }
  }, [userData, examBoard]);

  useEffect(() => {
    if (userData && school) {
      const filteredSchoolName = school.filter(
        (school) => school.id === userData?.student_info?.school_id
      );
      setSchoolName(filteredSchoolName);
    }
  }, [userData, school]);

  useEffect(() => {
    if (userData && country) {
      const filteredCountryName = country.filter(
        (country) => country.id === userData?.country_id
      );
      setCountryName(filteredCountryName);
    }
  }, [userData, country]);

  useEffect(() => {
    if (userData && keyStage) {
      const filteredKeyStage = keyStage.filter(
        (keyStage) => keyStage.id === userData?.student_info?.key_stage_id
      );
      setKeyStageName(filteredKeyStage);
    }
  }, [userData, keyStage]);

  useEffect(() => {
    if (userData && year) {
      const filteredClass = year.filter(
        (year) => year.id === userData?.student_info?.class_id
      );
      setClassName(filteredClass);
    }
  }, [userData, year]);

  useEffect(() => {
    if (userData && allAvatar) {
      const filteredAvatar = allAvatar.filter(
        (avthar) => avthar.id === userData?.avathar_image_id
      );
      setAvatar(filteredAvatar);
    }
  }, [userData, allAvatar]);

  return (
    <>
      <TopNavBar />
      <div
        className="d-flex container_div"
        style={{ backgroundColor: "#FEF9EF" }}
      >
        <SideNav />

        <div className="sideDiv  w-100 comic_font">
          {loading && (
            // <div>
            <ClipLoader
              color="black"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            // </div>
          )}

          {!loading && (
            <div className="profile_container">
              <div className="container_profile">
                <img className="KK" src={avthar[0]?.image} alt="" />

                <div className="d-flex justify-content-center">
                  <div className="parent_boxDiv">
                    <h4 className="d-flex justify-content-start w-100 mt-2">
                      Basic Details
                    </h4>
                    <div className="profile_box_div">
                      <div className="box">
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">Name</div>
                          <div className="profile_value">
                            {userData?.full_name}
                          </div>
                        </div>
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">School Name</div>

                          <div className="profile_value">
                            {schoolName[0]?.school_name}
                          </div>
                        </div>
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">Exam board</div>
                          <div className="profile_value">
                            {eBoard[0]?.exam_board}
                          </div>
                        </div>
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">Class</div>

                          <div className="profile_value">
                            {className[0]?.class_name}
                          </div>
                        </div>
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">Country</div>

                          <div className="profile_value">
                            {countryName[0]?.country_name}
                          </div>
                        </div>
                      </div>
                      <div className="box mx-2">
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">Gender</div>
                          <div className="profile_value">
                            {userData?.gender}
                          </div>
                        </div>
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">Birthday</div>

                          <div className="profile_value">
                            {userData?.student_info?.date_of_birth}
                          </div>
                        </div>
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">Key Stage</div>
                          <div className="profile_value">
                            {keyStageName[0]?.key_stage}
                          </div>
                        </div>
                        <Tooltip title={userData?.address_1} arrow>
                          <div className="profile_data_div d-flex mt-3">
                            <div className="profile_key">Address</div>
                            <div
                              className="profile_value"
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {userData?.address_1}
                            </div>
                          </div>
                        </Tooltip>
                        <div className="profile_data_div d-flex mt-3">
                          <div className="profile_key">City</div>

                          <div className="profile_value">{userData?.city}</div>
                        </div>
                      </div>
                      <img className="gift-price" src={gift_gif} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <img className="gifts" src={board_gif} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UserProfile;
