import React, { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import TopNavBar from "../TopNavBar/TopNav";
import {
  snowman,
  edit,
  homeSkillUp,
  calendar_header,
  calendar_v,
  circle_v,
  book_v,
  math_bg,
  science_bg,
  squillup_bg,
} from "../../Assets/Images/index";
import RemoveIcon from "@mui/icons-material/Remove";
import "./Home.css";
import "./progress.css";
import "./Calendar2.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import { Calendar } from "primereact/calendar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useApiContext } from "../../Context/ApiContext";
function Home() {
  const {
    allAvatar,
    userData,
    apiLoading,
    notice,
    Average,
    prgrs_data,
    date_arr,
    all_subject,
  } = useApiContext();

  // const { all_subject } = UseGetSubject();

  const [Greeting, setGreeting] = useState("");
  // const [showDrp, setShowDrop] = useState(false);
  // const [DrpData, setDrpData] = useState("selftest");
  const [month, setMonth] = useState("");
  const [avthar, setAvatar] = useState([]);
  const [curr_month, set_curr_month] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  const month_arr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    const d = new Date();
    let name = month_arr[d.getMonth()];
    // const avatar = localStorage.getItem("avatar");
    setMonth(name);
    // if (avatar) {
    //   setAvatar(avatar);
    // }
    localStorage.removeItem("sub");
    localStorage.removeItem("subId");
  }, []);
  useEffect(() => {
    localStorage.setItem("subject", JSON.stringify(all_subject));
  }, [all_subject]);

  useEffect(() => {
    const today = new Date();
    const hour = today.getHours();

    if (hour < 12) {
      setGreeting("Good Morning");
    } else if (hour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, []);

  useEffect(() => {
    const date = new Date();
    const month = date.getMonth();
    set_curr_month(month);
  }, []);

  const dateTemplate = (date) => {
    let holiday = 0;
    let name = "";
    if (date) {
      for (let i = 0; i < date_arr.length; i++) {
        if (
          date.day === date_arr[i].day &&
          date.month === date_arr[i].month &&
          date.year === date_arr[i].year
        ) {
          holiday = 1;
          name = date_arr[i].name;
        }
      }
    }

    if (date.month === curr_month) {
      if (holiday === 1) {
        return (
          <>
            {!tooltipVisible && (
              <div
                className="calendar-day"
                onMouseOver={() => {
                  setTooltipVisible(true);
                  setKey(date.day);
                }}
                style={{
                  backgroundColor: "#FFB252",
                  height: "27px",
                  cursor: "pointer",
                  padding: "0.2rem",
                }}
              >
                {date?.day}
              </div>
            )}

            {tooltipVisible && (
              <>
                {key === date.day ? (
                  <div
                    onMouseOut={() => {
                      setTooltipVisible(false);
                      setKey("");
                    }}
                    style={{
                      height: "27px",
                      cursor: "pointer",
                      width: "30px",
                      fontSize: "9px",
                      color: "#064974",
                      backgroundColor: "#E6FFBE",
                    }}
                  >
                    {name}
                  </div>
                ) : (
                  <div
                    className="calendar-day"
                    onMouseOver={() => {
                      setTooltipVisible(true);
                      setKey(date.day);
                    }}
                    style={{
                      backgroundColor: "#FFB252",
                      height: "27px",
                      cursor: "pointer",
                      padding: "0.2rem",
                    }}
                  >
                    {date?.day}
                  </div>
                )}
              </>
            )}
          </>
        );
      } else {
        return date?.day;
      }
    }
  };

  useEffect(() => {
    if (userData && allAvatar) {
      const filteredAvatar = allAvatar.filter(
        (avthar) => avthar.id === userData?.avathar_image_id
      );
      setAvatar(filteredAvatar);
    }
  }, [userData, allAvatar]);

  // const handle_drpChange = (data) => {
  //   setDrpData(data.type);
  //   setShowDrop(false);
  //   setExamType_id(data.id);
  // };
  // useEffect(() => {
  //   getAvrgPercent();
  // }, [exam_type_id]);

  // useEffect(() => {
  //   if (Average?.length === 0) {
  //     setExamType_id("1");
  //   }
  // }, [Average]);
  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  // useEffect(() => {
  // }, [notice, loading]);

  // const [selectedValue, setSelectedValue] = useState("");
  // const handleDropdownChange = (e) => {
  //   setSelectedValue(e.target.value);
  // };

  // const filteredData =
  //   selectedValue === "over_all_point_percentage"
  //     ? Average
  //     : Average.filter((item) => item === selectedValue);

  // console.log(filteredData);

  return (
    <div className="comp_container comic_font">
      <TopNavBar />
      <>
        <div className="sideDiv homeSideDiv">
          <div className="dash_side_div">
            <div>
              <div>
                <div className="notice_squipill_div">
                  <div className="notice-div mt-3">
                    <div className="circle_img d-flex">
                      <img src={circle_v} alt="" />
                    </div>
                    <div className="book_img">
                      <img src={book_v} alt="" />
                    </div>
                    <div className="calendr_img">
                      <img src={calendar_v} alt="" />
                    </div>
                    {apiLoading.notice && (
                      <div className="" style={{ paddingTop: "30px" }}>
                        <ClipLoader
                          color="black"
                          loading={true}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                    {!apiLoading.notice && notice?.length === 0 && (
                      <div className="no_notice">Notice data not available</div>
                    )}
                    {!apiLoading.notice && notice?.length !== 0 && (
                      <p className="notice_para">
                        {notice?.quote} - {notice.quote_author}
                      </p>
                    )}
                  </div>
                  <div className="mt-3 d-flex justify-content-between squipill_container">
                    <img src={squillup_bg} alt="" className="homeback" />
                    <img
                      src={homeSkillUp}
                      alt="img"
                      className="mx-2 squipill_books"
                    />
                    <div className="welcome_squipill">
                      <div className="squillup">
                        welcome to Sqillup
                        <br />
                        <span>Science & Maths</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 w-100 progress_score_div">
                <div className="progressBar_mainDiv">
                  <div className="d-flex justify-content-between mx-2">
                    <div className="progressBar">Progress bar (Courses)</div>
                    <div
                      className="viewAll mt-1"
                      onClick={() => navigate("/progress")}
                    >
                      View all
                    </div>
                  </div>
                  <div className="progressDiv mt-2">
                    <div className="progress_flex">
                      {!apiLoading.prgrs_bar && (
                        <>
                          {prgrs_data?.slice(0, 2).map((elem, index) => (
                            <div key={index}>
                              {elem.subject_name === "Primary maths" ? (
                                <div className="">
                                  <div className="">
                                    <div className="wrapper">
                                      <div className="thermometer">
                                        <div className="thermometer-body">
                                          <div className="thermometer_circle">
                                            {Math.round(elem.subjectPercentage)}
                                            %
                                          </div>
                                          <progress
                                            max="100"
                                            // value="60"
                                            value={parseFloat(
                                              elem.subjectPercentage
                                            )}
                                          ></progress>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="progressBarAlign">
                                      {[
                                        100, 90, 80, 70, 60, 50, 40, 30, 20, 10,
                                      ].map((num, index) => (
                                        <div className="dash_range" key={index}>
                                          <span className="dash">
                                            <RemoveIcon
                                              style={{ fontSize: "40px" }}
                                            />
                                          </span>
                                          <span
                                            className={
                                              num <=
                                              parseFloat(elem.subjectPercentage)
                                                ? "num_blue"
                                                : "num_gray"
                                            }
                                          >
                                            {num}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  <div
                                    className="d-flex justify-content-between maths_prg"
                                    style={{ color: "#E97838" }}
                                  >
                                    {elem.subject_name}
                                  </div>
                                </div>
                              ) : (
                                <div className="science_prgress_align">
                                  <div className="">
                                    <div className="wrapper">
                                      <div className="primary">
                                        <div className="thermometer">
                                          <div
                                            className="thermometer-body2"
                                            style={{
                                              content: parseFloat(
                                                elem.subjectPercentage
                                              ),
                                            }}
                                          >
                                            <div className="thermometer_circle2">
                                              {Math.round(
                                                elem.subjectPercentage
                                              )}
                                              %
                                            </div>
                                            <progress
                                              max="100"
                                              // value="60"
                                              value={parseFloat(
                                                elem.subjectPercentage
                                              )}
                                              id="th-animated"
                                            ></progress>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="progressBarAlign1">
                                      {[
                                        100, 90, 80, 70, 60, 50, 40, 30, 20, 10,
                                      ].map((num, index) => (
                                        <div className="dash_range" key={index}>
                                          <span className="dash">
                                            <RemoveIcon
                                              style={{ fontSize: "40px" }}
                                            />
                                          </span>
                                          <span
                                            className={
                                              num <=
                                              parseFloat(elem.subjectPercentage)
                                                ? "num_blue"
                                                : "num_gray"
                                            }
                                          >
                                            {num}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div
                                    className="science_prg"
                                    style={{ color: "#6DEEA5" }}
                                  >
                                    {elem.subject_name}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="score_main_div">
                  <div className="d-flex justify-content-between mx-2">
                    <div className="progressBar">Score</div>
                    <div
                      className="viewAll mt-1"
                      onClick={() => navigate("/score")}
                    >
                      View all
                    </div>
                  </div>
                  <div className="scoreWhiteDiv mt-2">
                    <div className="dropdown w-100"></div>
                    {/* <div className="dropdown w-100">
                      <button
                        className="dropbtn"
                        onClick={() => setShowDrop(!showDrp)}
                      >
                        <div className="d-flex justify-content-between">
                          <span> {DrpData}</span>
                          <KeyboardArrowDownIcon />
                        </div>
                      </button>

                      {showDrp && (
                        <div className="d-flex justify-content-center mx-3">
                          <div
                            className="dropdown-content"
                            style={{ width: "88%" }}
                          >
                            <a
                              onClick={() =>
                                handle_drpChange({
                                  id: "",
                                  type: "Select Exam Type",
                                })
                              }
                            >
                              Select Exam Type
                            </a>
                            {exam_type.map((elem, index) => (
                              <a
                                onClick={() => handle_drpChange(elem)}
                                key={index}
                              >
                                {elem.type}
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                    </div> */}
                    {/* <select
                        value={selectedValue}
                        onChange={handleDropdownChange}
                      >
                        <option value="over_all_point_percentage">
                          Select Exam Type
                        </option>
                        {exam_type.map((elem, index) => (
                          <option key={index} value={elem.type}>
                            {elem.type}
                          </option>
                        ))}
                      </select> */}

                    {Average?.slice(0, 2).map((elem, index) => {
                      const subName = all_subject.find(
                        (detail) => detail.id === elem.subject_id
                      );
                      return (
                        <div key={index}>
                          <div className="d-flex justify-content-center w-100">
                            <div className="scoreDiv mt-2">
                              <img
                                src={
                                  subName?.name === "Science"
                                    ? science_bg
                                    : math_bg
                                  // elem.subject.name === "Maths"
                                  //elem?.subject_id === 6 ? math_bg : science_bg
                                  // math_bg
                                }
                                style={{
                                  border: `2px solid ${subName?.color}`,
                                }}
                                alt="img"
                                width="100%"
                                height="100%"
                              />
                              <div
                                className="Subn_name"
                                style={{
                                  color: subName?.color,
                                }}
                              >
                                {subName?.name}
                              </div>
                              <div
                                className="avgOuter"
                                style={{
                                  border: `5px solid ${subName?.color}`,
                                }}
                              >
                                <div
                                  className="avgInner"
                                  style={{
                                    backgroundColor: subName?.color,
                                  }}
                                >
                                  <div className="percent">
                                    {parseFloat(
                                      elem?.averagePointPercentage
                                    ).toFixed(2)}
                                    %
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="snowDiv mt-3">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="snow_cal">
              <div className="imgContainer mt-3">
                <div className="d-flex justify-content-center mt-3">
                  {!apiLoading.avatar ? (
                    <div>
                      {/* {User_data?.profile_image && ( */}
                      <div>
                        <img
                          src={avthar[0]?.image || snowman}
                          alt="img"
                          className="img img-fluid"
                          height="192px"
                          width="142px"
                        />
                        <img
                          src={edit}
                          alt="img"
                          style={{ background: "#FDF980" }}
                          className="edit"
                          onClick={() => navigate("/avatar")}
                        />
                      </div>
                      {/* )} */}
                      {Greeting && (
                        <div className="greetings">
                          Hi !<span className="mx-2">{Greeting},</span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="">
                      <ClipLoader
                        color="black"
                        loading={true}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}
                </div>
              </div>
              {!apiLoading.calander && (
                <>
                  <div
                    className="calendar_top_div"
                    style={{ backgroundColor: "#E6FFBE" }}
                  >
                    <img
                      src={calendar_header}
                      alt="img"
                      className="calendar_topHeader"
                    />

                    <div className="month calenderMonth">{month}</div>

                    <div className="calenderContainer">
                      <Calendar inline dateTemplate={dateTemplate} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Home;
