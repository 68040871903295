import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./Start_test.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { no_data } from "../../../Assets/Images";

function Start_test(props) {
  const [loading, setLoading] = useState(true);
  const [showInstruction, setShowInstruction] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    const data = localStorage.getItem("pp_qstn");
    setData(JSON.parse(data));
    const modes = localStorage.getItem("mode");
    setMode(modes);
  }, []);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const handleNextClick = () => {
    setShowInstruction(true);
  };
  const handleStartClick = () => {
    if (mode == "learn") {
      navigate("/learn-pastpaper", { state: state });
    } else {
      navigate("/test", { state: state });
    }
  };
  return (
    <>
      {data?.length === 0 && (
        <>
          {loading && (
            <div className=" mt-5">
              <ClipLoader
                color="black"
                loading={loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          {!loading && (
            <>
              <div className="no_data_img">
                <img src={no_data} alt="" />
              </div>
            </>
          )}
        </>
      )}

      {data && (
        <div className="past_paper">
          <div className="d-flex justify-content-end mb-3">
            <div className="  closese" onClick={() => navigate("/pastpaper")}>
              {/* <h2> */}
              Close
              {/* </h2> */}
            </div>
          </div>

          {!showInstruction && (
            <img src={data?.cover_image} alt="" className="cover_image_test" />
          )}
          {showInstruction && data?.instruction && (
            <div className="instruction_image">
              <img
                src={data.instruction}
                alt="Instruction"
                className="cover_image_test"
              />
            </div>
          )}

          <div className="d-flex justify-content-end">
            {!showInstruction && data?.instruction ? (
              <div
                className="btn_in mb-3"
                type="submit"
                onClick={handleNextClick}
              >
                Next
              </div>
            ) : (
              <div
                className="btn_in mb-3"
                type="submit"
                onClick={handleStartClick}
              >
                Start
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default Start_test;
