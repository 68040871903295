import React, { useEffect, useState } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Challenge.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { no_data, challengeSvg } from "../../Assets/Images";
import { useApiContext } from "../../Context/ApiContext";
import SubjectBar from "../SubjectBar";
import { toast } from "react-toastify";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function Challenge() {
  // const { all_subject, load } = UseGetSubject();
  const { all_subject, year_data } = useApiContext();
  const [loading, setLoading] = useState(true);
  const [pp_loading, setPP_loading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [active, set_active] = useState();
  const [pastPaperData, setPastPaperData] = useState([]);
  const [showDrp, setShowDrop] = useState(false);
  const [DrpData, setDrpData] = useState("Challenge paper type");
  const [userData, setUserData] = useState();
  const [showDrp2, setShowDrop2] = useState(false);
  const [DrpData2, setDrpData2] = useState("Select Year");
  const [token, setToken] = useState();
  const [exam_board, setExamBoard] = useState([]);
  const [exam_board_id, setExamBoard_id] = useState("");
  // const [year_data, setYear_data] = useState([]);
  const [year, setYear] = useState("");
  const [allPastPaperData, setAllPastPaperData] = useState([]);
  const [filterPastPaper, setFilterPastPaper] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // const [chapter_data, setChapter_data] = useState("Select Chapter");

  const [chapter_id, setChapter_id] = useState("");

  // const [totalData, setTotalData] = useState(0);
  // const [page_arr, setPage_arr] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const storedActive = localStorage.getItem("subId");
    if (storedActive) {
      set_active(storedActive);
    } else {
      set_active(all_subject[0]?.id);
    }
  }, [all_subject]);

  localStorage.removeItem("sub");

  useEffect(() => {
    if (userData && token && active) {
      if (exam_board_id !== "" || year !== "" || toggle) {
        setPP_loading(true);
        setPastPaperData([]);
        // get_all_pastpapers_after(currentPage);
      }
    }
  }, [exam_board_id, year, toggle, chapter_id]);

  const handle_drpChange = (id, name) => {
    setDrpData(name);
    setShowDrop(false);
    setExamBoard_id(id);
  };
  const handle_drpChange2 = (data) => {
    if (data === "Select Year") {
      setYear("");
      setShowDrop2(false);
      setDrpData2(data);
    } else {
      setDrpData2(data);
      setShowDrop2(false);
      setYear(data);
    }
  };

  useEffect(() => {
    if (toggle) {
      setDrpData2("Select Chapter");
    } else {
      setDrpData2("Select Year");
    }
  }, [toggle]);

  useEffect(() => {
    getPastPaper();
    getChallengeBoard();
  }, []);
  const getPastPaper = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/challenge`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      if (resp) {
        setAllPastPaperData(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };
  const getChallengeBoard = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/challenge-board`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      if (resp) {
        setExamBoard(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    const filterData = allPastPaperData.filter(
      (paper) => paper.subject_id == active
    );
    setFilterPastPaper(filterData);
  }, [active, allPastPaperData]);
  useEffect(() => {
    setDrpData("Challenge paper type");
    setDrpData2("Select Year");
    setCurrentPage(1);
  }, [active]);

  // useEffect(() => {
  //   const extractedYears = filterPastPaper.map((item) => item.year);
  //   const uniqueYears = [...new Set(extractedYears)];
  //   setYear_data(uniqueYears);
  // }, [filterPastPaper]);

  useEffect(() => {
    if (DrpData === null || DrpData2 === null) return;

    let filterExamBoardData = filterPastPaper;
    let filterYearData = filterPastPaper;

    if (DrpData !== "Challenge paper type") {
      filterExamBoardData = filterPastPaper.filter(
        (paper) => paper.challenge_board_id === exam_board_id
      );
    }

    if (DrpData2 !== "Select Year") {
      filterYearData = filterPastPaper.filter(
        (paper) => paper.year === DrpData2
      );
    }

    const filtered = filterExamBoardData.filter((paper) =>
      filterYearData.includes(paper)
    );

    setPastPaperData(filtered);
    // if (filterPastPaper.length > 0) {
    //   setLoading(false);
    // }
  }, [DrpData, DrpData2, filterPastPaper]);

  useEffect(() => {
    setYear("");
    setDrpData2("Select Year");
    setChapter_id("");
    // setChapter_data("Select Chapter");
  }, [toggle]);

  const clear_local_storage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    navigate("/");
  };

  const handle_start_test = (item, mode) => {
    localStorage.setItem("pp_qstn", JSON.stringify(item));
    localStorage.setItem("mode", mode);
    localStorage.setItem("challenge", true);
    localStorage.removeItem("selftest_qstn_arr");
    localStorage.removeItem("active_qstn");
    localStorage.removeItem("chapter");
    localStorage.removeItem("Time");
    localStorage.removeItem("solution");
    localStorage.removeItem("theory");
    localStorage.removeItem("checkboxStatus");
    localStorage.removeItem("answeredQuestions");
    localStorage.removeItem("currentQuestionAnswered");
    localStorage.setItem("subId", active);
    navigate("/instruction", { state: item });
  };

  // pagination

  const itemsPerPage = 10;
  // Get current data for the page
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return pastPaperData.slice(startIndex, endIndex);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(pastPaperData.length / itemsPerPage);
  return (
    <div
      className="comp_container comic_font"
      style={{ backgroundColor: "#FEF9EF" }}
    >
      <TopNavBar />
      <SubjectBar
        all_subject={all_subject}
        active={active}
        set_active={set_active}
      />
      <div className="sideDiv">
        <div className="w-100">
          <div className="pp_drp_downs mx-4">
            <div className="self_dropdown">
              <div className="pp_dropdown">
                <button
                  className="pp_dropbtn"
                  onClick={() => setShowDrop(!showDrp)}
                >
                  <div className="d-flex justify-content-between slectExam ">
                    <span className="pastPaper_drpdown"> {DrpData}</span>
                    <KeyboardArrowDownIcon />
                  </div>
                </button>

                {showDrp && (
                  <div className="d-flex justify-content-center mx-3 z ">
                    <div
                      className="pp_dropdown-content"
                      style={{ width: "100%" }}
                    >
                      {exam_board.length !== 0 && (
                        <>
                          <button
                            onClick={() =>
                              handle_drpChange(" ", "Challenge paper type")
                            }
                          >
                            Challenge paper type
                          </button>
                          {exam_board.map((item, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handle_drpChange(
                                  item?.id,
                                  item?.challenge_board
                                )
                              }
                            >
                              {item?.challenge_board}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!toggle && (
                <div className="pp_dropdown_year mx-2">
                  <button
                    className="pp_dropbtn"
                    onClick={() => setShowDrop2(!showDrp2)}
                  >
                    <div className="d-flex justify-content-between  selectYear ">
                      <span className="pastPaper_drpdown"> {DrpData2}</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  </button>

                  {showDrp2 && (
                    <div className="d-flex justify-content-center mx-3 ">
                      <div
                        className="pp_dropdown-content"
                        style={{ width: "100%" }}
                      >
                        {year_data.length !== 0 && (
                          <>
                            <button
                              onClick={() => handle_drpChange2("Select Year")}
                            >
                              Select year
                            </button>
                            {year_data.map((item, index) => (
                              <button
                                onClick={() => handle_drpChange2(item?.year)}
                                key={index}
                              >
                                {item?.year}
                              </button>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {loading === true ? (
            <div className=" mt-5">
              <ClipLoader
                color="black"
                loading={pp_loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              {pastPaperData?.length > 0 ? (
                <>
                  <div className="table_main_div ">
                    <div className="pp_table_div_challenge mt-5 ms-4 me-5 gap-4">
                      <div className="comic_font table_right">
                        <table className="pp_table">
                          <thead className="tr_row">
                            <tr className="">
                              <th
                                className="table_head"
                                scope="col"
                                style={{
                                  border: "none",
                                  borderRadius: "30px 0px 0px 0px",
                                }}
                              >
                                Subject
                              </th>

                              <th className="table_head" scope="col">
                                Year
                              </th>

                              <th className="table_head" scope="col">
                                Mode
                              </th>
                              <th className="table_head" scope="col">
                                Mode
                              </th>
                              <th
                                className="table_head"
                                scope="col"
                                style={{
                                  border: "none",
                                  borderRadius: "0px 30px 0px 0px",
                                }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {getCurrentPageData()?.map((item, index) => {
                              return (
                                <tr key={index} className="td_row">
                                  <td
                                    className="table_data"
                                    style={{
                                      color: "#162880",
                                      textAlign: "start",
                                    }}
                                  >
                                    <img
                                      className="mx-3"
                                      src={challengeSvg}
                                      alt="svg"
                                    />
                                    <u>{item?.challenge_name}</u>
                                  </td>

                                  <td className="table_data">{item?.year}</td>

                                  <td
                                    className="start_test"
                                    onClick={() =>
                                      handle_start_test(item, "learn")
                                    }
                                  >
                                    <button className="mode">
                                      <span>Learn</span>
                                    </button>
                                  </td>
                                  <td
                                    className="start_test"
                                    onClick={() =>
                                      handle_start_test(item, "test")
                                    }
                                  >
                                    <button className="mode">
                                      {item?.score == null ? (
                                        <span>Test</span>
                                      ) : (
                                        <span>Redo</span>
                                      )}
                                    </button>
                                  </td>
                                  {item?.score == null ? (
                                    <td className="table_data">-</td>
                                  ) : (
                                    <td
                                      className="table_data"
                                      style={{ color: "#95C136" }}
                                    >
                                      {item?.score?.score_count}/
                                      {item?.score?.total_question_count}
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {pastPaperData.length > itemsPerPage && (
                    <div className="pagination d-flex justify-content-center my-4">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <ArrowLeftIcon />
                      </button>
                      <span className="mx-1">
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <ArrowRightIcon />
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="no_data_img">
                    <img src={no_data} alt="" />
                  </div>
                </>
              )}
            </>
          )}

          {/* {pastPaperData.length !== 0 && (
            <div className="pb-5 mt-2">
              <div
                className={
                  showRow_pp
                    ? "row_drop_open d-flex justify-content-center"
                    : "d-flex justify-content-center"
                }
              >
                <div className="dropdown row_dropdown_margin">
                  <button
                    className="row_dropbtn"
                    onClick={() => setShowRow_pp(!showRow_pp)}
                  >
                    <div className="d-flex justify-content-between px-1 ">
                      <span> {Row_data}</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  </button>

                  {showRow_pp && (
                    <div className="d-flex justify-content-center mx-3">
                      <div className="row_dropdown-content">
                        {arr.map((elem, index) => (
                          <a
                            onClick={() => handle_row_click(elem)}
                            key={index}
                            className={
                              Row_data === elem
                                ? "selected_row"
                                : "selecting_row"
                            }
                          >
                            {elem}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-1">
                  <Stack spacing={0}>
                    <Pagination
                      color="primary"
                      defaultPage={currentPage}
                      count={totalPage}
                      onChange={(event, page) => handlePageChange(page)}
                    />
                  </Stack>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Challenge;
