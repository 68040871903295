import React, { useEffect } from "react";
import "./MyPractice.css";
import axios from "axios";
import TopNavBar from "../../TopNavBar/TopNav";
import { useNavigate } from "react-router";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../../SeasonActivity/activity.css";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import no_Data from "../../../Assets/Images/No data.png";
import SubjectBar from "../../SubjectBar";
import { useApiContext } from "../../../Context/ApiContext";
import ActivitySettingModal from "./ActivitySettingModal";

function MyPractice_Test() {
  const { all_subject, all_chapter } = useApiContext();
  const navigate = useNavigate();
  const [active_sub, set_active_sub] = useState();
  const [activityData, setActivityData] = useState([]);
  const [showDrp, setShowDrop] = useState(false);
  const [DrpData, setDrpData] = useState("Select chapter");
  const [DrpDataId, setDrpDataId] = useState();
  const [active, setActive] = useState("Set 1");
  const [dataa, setDataa] = useState([]);
  const [sets, setsets] = useState([]);
  const [activeSetData, setActiveSetData] = useState([]);
  const [activeSetChapterData, setActiveSetChapterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [chapter_data, setChapter_data] = useState([]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const storedActive = localStorage.getItem("subId");
    if (storedActive) {
      set_active_sub(storedActive);
    } else {
      set_active_sub(all_subject[0]?.id);
    }
  }, [all_subject]);

  useEffect(() => {
    getSets();
    getActivity();
  }, []);
  useEffect(() => {
    const filterData = all_chapter.filter(
      (chapter) => chapter.subject_id == active_sub
    );
    setChapter_data(filterData);
    setDrpData("Select chapter");
  }, [active_sub, all_chapter]);

  const handle_drpChange = (data, id) => {
    setDrpData(data);
    setDrpDataId(id);
    setShowDrop(false);
  };

  useEffect(() => {
    if (DrpData === null) return;

    let filterChapterData = activeSetChapterData;

    if (DrpData !== "All" && DrpData !== "Select chapter") {
      filterChapterData = activeSetChapterData.filter(
        (paper) => paper.chapter_id === DrpDataId
      );
    }

    setActiveSetData(filterChapterData);
  }, [DrpData, activeSetChapterData]);
  const clear_local_storage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    navigate("/");
  };
  // Get Months

  useEffect(() => {
    const filterData = activityData.filter(
      (chapter) => chapter.subject_id == active_sub
    );
    setDataa(filterData);
    setActive("Set 1");
  }, [active_sub, activityData]);

  const getSets = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/set-list`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setsets(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const getActivity = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/activity`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setActivityData(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    const activeSet = dataa.filter((item) => item.batch == active);

    setActiveSetChapterData(activeSet);
  }, [dataa, active]);

  const handle_start_test = (item) => {
    localStorage.setItem("subId", active_sub);
    navigate("/practiceself", { state: item });
  };
  const boxShadow = "unset";

  return (
    <>
      <div className="comp_container comic_font">
        <TopNavBar />
        {loading ? (
          <div className=" mt-5">
            <ClipLoader
              color="black"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div style={{ backgroundColor: "white" }}>
            <div className=" activity_header">
              <SubjectBar
                all_subject={all_subject}
                active={active_sub}
                set_active={set_active_sub}
                boxShadow={boxShadow}
              />
              <div className="setting_btn" onClick={handleOpenModal}>
                Activity Setting
              </div>
            </div>
            <div className="activity_sideDiv">
              <div className="w-100">
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <div className="white_space" style={{ background: "white" }}>
                  <div
                    className="d-flex  assigned"
                    style={{
                      color: "#8B60FD",
                      margin: "20px 0",
                      fontSize: "20px",
                    }}
                  >
                    {sets?.map((item, index) => (
                      <div
                        className={
                          active === item?.name
                            ? "purple_line_practice_test"
                            : "inactive_practice_test"
                        }
                        key={index}
                        onClick={() => setActive(item?.name)}
                        style={{
                          color: "#000",
                        }}
                      >
                        {item?.name}
                      </div>
                    ))}
                  </div>
                  <div
                    className="dropdown drp_width me-0 me-md-5"
                    style={{ marginBottom: !showDrp ? "10px" : "0px" }}
                  >
                    <button
                      className="dropbtn"
                      onClick={() => setShowDrop(!showDrp)}
                    >
                      <div className="d-flex justify-content-between px-1">
                        <span> {DrpData}</span>
                        <KeyboardArrowDownIcon />
                      </div>
                    </button>

                    {showDrp && (
                      <div className="d-flex justify-content-center  mx-3 p-2">
                        <div
                          className="dropdown-content "
                          style={{ width: "88%" }}
                        >
                          <button
                            className="btn-dropdown"
                            onClick={() => handle_drpChange("All", "")}
                          >
                            All
                          </button>
                          {chapter_data?.map((elem, index) => (
                            <button
                              className="btn-dropdown"
                              onClick={() =>
                                handle_drpChange(elem?.chapter_name, elem.id)
                              }
                              key={index}
                            >
                              <span className="me-1">{index + 1}.</span>{" "}
                              {elem?.chapter_name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {activeSetData.length ? (
                  <div className="table_main_div ">
                    <div className="pp_table_div_challenge mt-5 ms-4 me-5 gap-4">
                      <div className="comic_font table_right">
                        <table className="pp_table">
                          <thead className="tr_row">
                            <tr className="">
                              <th
                                className="table_head"
                                scope="col"
                                style={{
                                  border: "none",
                                  borderRadius: "30px 0px 0px 0px",
                                }}
                              >
                                Activity
                              </th>

                              <th className="table_head" scope="col">
                                Activity Name
                              </th>
                              <th className="table_head" scope="col">
                                Score
                              </th>

                              <th
                                className="table_head"
                                scope="col"
                                style={{
                                  border: "none",
                                  borderRadius: "0px 30px 0px 0px",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeSetData?.map((item, index) => {
                              return (
                                <tr key={index} className="td_row">
                                  <td
                                    className="table_data"
                                    style={{ color: "#162880" }}
                                  >
                                    <u>Activity {index + 1}</u>
                                  </td>

                                  <td className="table_data">
                                    {item?.activity_name}
                                  </td>

                                  {item?.score == null ? (
                                    <td className="table_data">-</td>
                                  ) : (
                                    <td
                                      className="table_data"
                                      style={{ color: "#95C136" }}
                                    >
                                      {item?.score?.score_count}/
                                      {item?.score?.total_question_count}
                                    </td>
                                  )}
                                  <td className="start_test">
                                    {item?.score == null ? (
                                      <button
                                        className="mode"
                                        onClick={() => handle_start_test(item)}
                                      >
                                        <span>Test</span>
                                      </button>
                                    ) : (
                                      <button
                                        className="mode"
                                        style={{ backgroundColor: "#58C64F" }}
                                        onClick={() => handle_start_test(item)}
                                      >
                                        <span>Redo</span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="no_data_img">
                    <img src={no_Data} alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <ActivitySettingModal show={showModal} handleClose={handleCloseModal} />
      </div>
    </>
  );
}

export default MyPractice_Test;
