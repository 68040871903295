import React, { useEffect, useState } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import "./Faq.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { close, faq, right_icon } from "../../Assets/Images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function Faq() {
  const [loading, setLoading] = useState(false);
  const [allFaq, setAllFaq] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [message, setMessge] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    get_all_faq();
  }, []);

  const navigate = useNavigate();

  const get_all_faq = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("User");
    const user_type1 = JSON.parse(user);
    const user_type = user_type1?.user_type;

    try {
      const bodyData = new FormData();
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/faq`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        const faq = resp.data.filter((chapter) => chapter.for === user_type);
        setAllFaq(faq);
        setLoading(false);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };

  const create_faq = async () => {
    if (message !== "") {
      setLoading(true);
      const token = localStorage.getItem("token");

      try {
        const bodyData = new FormData();
        bodyData.append("massage", message);
        const resp = await axios.post(
          `${process.env.REACT_APP_NEW_DEVELOPMENT}/contact`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (resp) {
          // toast.success("Question added");
          setMessge("");
          setOpen(true);
          get_all_faq();
          setLoading(false);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message);
        if (
          e?.response?.data?.status_code === 401 ||
          e?.response?.data?.status_code === 500
        ) {
          clear_local_storage();
        }
      }
    } else {
      toast.error("Please type something");
    }
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  return (
    <>
      <div
        className="comp_container comic_font"
        style={{ height: "100%", minHeight: "100%" }}
      >
        <TopNavBar />

        <div className="sideDiv">
          <div className="w-100 faq_container">
            <div className="d-flex justify-content-center mt-4">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div>
                {loading && (
                  <div className="">
                    <ClipLoader
                      color="black"
                      loading={loading}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                )}

                {!loading && (
                  <>
                    <div>
                      <div className="wel_faq">
                        Welcome to SqillUP FAQ centre
                      </div>
                      <div className="mt-4">
                        <img src={faq} alt="" className="faq_img" />
                      </div>
                      <div className="d-flex justify-content-center">
                        <div>
                          {allFaq.map((item, index) => (
                            <div
                              key={item.id}
                              className="accordian_qna_div mt-3"
                            >
                              <div
                                onClick={() => handleClick(index)}
                                className="accordian_qstn"
                              >
                                <div className="acc_qstn">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.question,
                                    }}
                                  />
                                </div>
                                {index === activeIndex && (
                                  <div className="acc_icon">
                                    <RemoveCircleIcon
                                      style={{
                                        color: "#8B60FD",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </div>
                                )}
                                {index !== activeIndex && (
                                  <div className="acc_icon">
                                    <AddCircleIcon
                                      style={{
                                        color: "#8B60FD",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              {index === activeIndex && (
                                <div className="accordian_ans">
                                  <div className="acc_ans">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.answer,
                                      }}
                                    />
                                    {/* {item.answer} */}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="faq_find_here mt-5">
                      Can't find your question here? Contact us at
                      <span className="contact_squipill">
                        contact@sqillup.uk.com
                      </span>
                    </div>
                    <div className="faq_find_here mt-3 faq_display_none">
                      OR
                    </div>
                    <div className="faq_display_none">
                      <textarea
                        className="faq_textarea mt-4"
                        placeholder="Type your message"
                        onChange={(e) => setMessge(e.target.value)}
                      />
                    </div>
                    <div className="faq_display_none">
                      <button
                        className="send_query_btn"
                        onClick={() => create_faq()}
                        disabled={message.length === 0 ? true : false}
                      >
                        Submit a query
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size={700}
        animation={false}
        aria-labelledby="contained-modal-title-lg"
        centered
        style={{
          borderRadius: "50px",
        }}
      >
        <Modal.Body
          style={{
            padding: "10%",
            height: "389px",
            paddingTop: "9%",
            borderRadius: "80px",
            maxWidth: "707px",
            border: "10px solid #8B60FD",
            backgroundColor: "white",
          }}
        >
          <div className="comic_font">
            <div className="d-flex justify-content-end -w-100 contact_us_close">
              <img
                src={close}
                alt=""
                onClick={() => setOpen(false)}
                className="contact_us_close"
              />
            </div>
            <div className="contact_us_contentdiv">
              <div className="d-flex justify-content-center -w-100">
                <img src={right_icon} alt="" className="faq_right_icon" />
                {/* <CheckCircleIcon
                  style={{
                    width: "120px",
                    height: "120px",
                    color: "#2AC940",
                  }}
                /> */}
              </div>
              <div className="d-flex justify-content-center thanksForReach mt-2">
                Your query has been sent!
              </div>
              <div className="d-flex justify-content-center mt-2">
                <div className="someoneFrom">
                  Someone from our team will soon get in touch with you.
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Faq;
