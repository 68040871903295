/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSpeechRecognition } from "react-speech-recognition";
import { Back_btn, SaveBtn } from "../Style/Styled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import ClipLoader from "react-spinners/ClipLoader";
import {
  sound,
  right_ans,
  wrong_ans,
  cartoon,
  no_data,
  close,
} from "../../Assets/Images";

import useSound from "use-sound";
import { right_ans_sound } from "../../Assets/Sound/Index";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessModal from "../SuccessModal";
import ScoreModal from "../ScoreModal";
import { useApiContext } from "../../Context/ApiContext";

function ChallengeAnswer() {
  const { id } = useParams();
  const { state } = useLocation();
  const [isRivision, setIsRivision] = useState();
  const [active_lesson, setActiveLesson] = useState();
  const [submit_test, setsubmit_test] = useState(false);
  const [score_modal, setScore_modal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(state?.time ?? 1800);
  const [play] = useSound(right_ans_sound);
  const [question, setquestion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show_next, setShow_next] = useState(false);
  const [show_submit, setShow_submit] = useState(true);
  const [initial_index, setInitial_index] = useState(0);
  const [final_index, setFinal_index] = useState(9);
  const [qstn_no_arr, set_qstn_no_arr] = useState([]);
  const [Active, setActive] = useState(0);
  const [option, setOption] = useState("");
  const [correct, setCorrect] = useState();
  const [showSolution, setShowSolution] = useState(false);
  const [score, setScore] = useState(0);
  const [revisionQuestion, setRevisionQuestion] = useState();
  const [data, setData] = useState();
  const [show_modal, setShowModal] = useState(false);
  const [show_sol, setShow_sol] = useState(false);
  const [back, setBack] = useState(true);
  const [autoHide, setAutoHide] = useState(true);
  const [userData, setUserData] = useState();
  const [child, setChild] = useState(0);
  const [hideButton, setHideButton] = useState("block");

  const [studentId, setStudentId] = useState();
  // speect-recognition
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [speaking, setSpeaking] = useState(false);
  const [supported, setSupported] = useState(false);
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    if ("speechSynthesis" in window) {
      setSupported(true);
      setVoices(window.speechSynthesis.getVoices());
    }
  }, []);

  const speak = (text, voice) => {
    if (supported) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = voice;
      window.speechSynthesis.speak(utterance);
      setSpeaking(true);
      utterance.onend = () => setSpeaking(false);
    }
  };

  const cancel = () => {
    if (supported) {
      window.speechSynthesis.cancel();
      setSpeaking(false);
    }
  };

  // end speect-recognition

  useEffect(() => {
    const userId = localStorage.getItem("User");
    const student = JSON.parse(userId);
    setStudentId(student?.id);

    const arr = JSON.parse(localStorage.getItem("pp_qstn"));
    if (arr) {
      setData(arr);
    }

    const isRivision = localStorage.getItem("isRivision");
    if (isRivision) {
      setIsRivision(isRivision);
    }
    const active_lesson = localStorage.getItem("active_video");
    if (active_lesson) {
      setActiveLesson(JSON.parse(active_lesson));
    }

    const selftest_qstn_arr = JSON.parse(
      localStorage.getItem("selftest_qstn_arr")
    );
    // const arr = JSON.parse(localStorage.getItem("pp_qstn"));
    // if (arr) {
    //   setData(arr);
    // }
    if (state) {
      pastPaperQuestion();
    }
    const active_qstn = localStorage.getItem("active_qstn");
    if (active_qstn) {
      setActive(Number(active_qstn));
    } else {
      setActive(0);
    }
    if (selftest_qstn_arr) {
      if (selftest_qstn_arr[Active]?.correct == false) {
        setShowSolution(true);
      }
    }
  }, []);
  const pastPaperQuestion = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/challenge-question`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        const filterQuestion = resp.data.filter(
          (item) => item.challenge_id == state?.id
        );
        setquestion(filterQuestion);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const question_duration_minute = state?.duration_minute;
    const selftest_qstn_arr = JSON.parse(
      localStorage.getItem("selftest_qstn_arr")
    );
    if (state && state?.isRivision == true) {
      setRevisionQuestion(question);
      const arr = [];
      for (let i = 0; i < question?.length; i++) {
        arr.push({ id: i, correct: "", ans_id: "" });
      }
      set_qstn_no_arr(arr);
    } else {
      if (selftest_qstn_arr) {
        set_qstn_no_arr(selftest_qstn_arr);
      } else {
        if (question?.length !== 0) {
          const arr = [];
          for (let i = 0; i < question?.length; i++) {
            arr.push({ id: i, correct: "", ans_id: "" });
          }
          set_qstn_no_arr(arr);
        }
      }
    }
    if (question?.length > 10) {
      setFinal_index(9);
    }

    const activityTimer =
      parseInt(localStorage.getItem("activitySetting"), 10) || 0;

    // Calculate the adjusted minutes
    let totalMinutes = (question_duration_minute || 0) + activityTimer;

    // Convert totalMinutes to seconds
    let totalSeconds = totalMinutes * 60;

    const oldTime = localStorage.getItem("Time");

    setTimeLeft(oldTime ? parseInt(oldTime, 10) : totalSeconds);
  }, [question]);

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [timeLeft]);

  useEffect(() => {
    if (question?.length) {
      if (question) {
        localStorage.setItem(
          "solution",
          JSON.stringify(question[Active]?.solution)
        );
        localStorage.setItem(
          "theory",
          JSON.stringify(question[Active]?.theory)
        );
      }
    }
  }, [question]);

  useEffect(() => {
    if (question?.length !== 0 && qstn_no_arr?.length !== 0) {
      if (qstn_no_arr[Active]?.ans_id !== "") {
        setCorrect();
        if (qstn_no_arr[Active]?.correct == false) {
          setShowSolution(true);
          localStorage.setItem(
            "solution",
            JSON.stringify(question[Active]?.solution)
          );
          localStorage.setItem(
            "theory",
            JSON.stringify(question[Active]?.theory)
          );
        } else {
          setShowSolution(false);
        }
        if (qstn_no_arr?.length === Active + 1) {
          setShow_submit(false);
          setShow_next(true);
        }
      }
    }
  }, [Active]);

  useEffect(() => {
    if (showSolution === true) {
      localStorage.setItem("active_qstn", Active);
      localStorage.setItem("selftest_qstn_arr", JSON.stringify(qstn_no_arr));
      if (qstn_no_arr?.length === Active + 1) {
        setShow_submit(false);
        setShow_next(true);
      }
    }
  }, [showSolution, Active]);

  // const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis();
  const navigate = useNavigate();
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleOptionChange = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        return [...prevSelectedOptions, optionId];
      }
    });
  };

  const handleSubmit = async (
    ques_id,
    id,
    index,
    questionType,
    answers,
    points
  ) => {
    if (option !== "" || selectedOptions.length > 0) {
      setShow_submit(false);
      let isCorrect = false;

      if (questionType === "Mcq") {
        const selectedAnswer = answers.find(
          (answer) => answer.id === parseInt(option)
        );
        isCorrect = selectedAnswer
          ? selectedAnswer.is_correct === "true"
          : false;

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? { ...item, id: index, correct: isCorrect, ans_id: option }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      } else if (questionType === "One word") {
        const correctAnswer = answers.find(
          (answer) => answer.is_correct === "true"
        );
        isCorrect =
          correctAnswer &&
          correctAnswer.answer.toLowerCase() === option.toLowerCase();

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? { ...item, id: index, correct: isCorrect, ans_id: option }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      } else if (questionType === "Mcq multiple") {
        const correctAnswers = answers
          .filter((answer) => answer.is_correct === "true")
          .map((answer) => answer.id);

        isCorrect =
          selectedOptions.length === correctAnswers.length &&
          selectedOptions.every((option) => correctAnswers.includes(option));

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? {
                  ...item,
                  id: index,
                  correct: isCorrect,
                  ans_id: selectedOptions,
                }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      }

      if (questionType === "Filling the blank") {
        const correctAnswer = answers.find(
          (answer) => answer.is_correct === "true"
        );
        isCorrect =
          correctAnswer &&
          correctAnswer.answer.toLowerCase() === option.toLowerCase();

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? { ...item, id: index, correct: isCorrect, ans_id: option }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      }

      setShow_next(true);
    }

    if (option === "") {
      if (qstn_no_arr[Active]?.ans_id !== "") {
        setShow_submit(false);
        handleNext(index);
      }
    }
  };

  const handleNext = (index) => {
    if (final_index === index) {
      setFinal_index(final_index + 1);
      setInitial_index(initial_index + 1);
    }
    setShow_next(false);

    if (correct || question?.length > Active + 1) {
      if (option !== "") {
        setOption("");
        setActive(Active + 1);
      } else {
        if (qstn_no_arr[index]?.ans_id) {
          setOption("");
          setActive(Active + 1);
        }
      }
      setActive(Active + 1);
    }
    setShowSolution();
    setCorrect();
    setOption("");
    setShow_submit(true);
    cancel();
  };
  const handle_back_btn = (index) => {
    setActive(Active - 1);
    if (initial_index === index) {
      handleBackward();
    }
    if (qstn_no_arr[Active]?.correct == false) {
      setShowSolution(true);
    }
  };

  const handleClose = () => {
    localStorage.removeItem("pp_qstn");
    localStorage.removeItem("solution");
    localStorage.removeItem("selftest_qstn_arr");
    localStorage.removeItem("active_qstn");
    localStorage.removeItem("theory");
    localStorage.removeItem("mode");
    localStorage.removeItem("time");
    localStorage.removeItem("activeChild");
    localStorage.removeItem("activeQuestion");
    localStorage.removeItem("checkboxStatus");
    localStorage.removeItem("answeredQuestions");
    localStorage.removeItem("currentQuestionAnswered");
    navigate(`/challenge`);
    cancel();
  };

  const handleForward = () => {
    if (final_index + 1 !== qstn_no_arr?.length) {
      setFinal_index(final_index + 1);
      setInitial_index(initial_index + 1);
    }
  };

  const handleBackward = () => {
    if (initial_index > 0) {
      setFinal_index(final_index - 1);
      setInitial_index(initial_index - 1);
    }
  };

  const handle_submit_test = () => {
    setScore_modal(true);
    let count = 0;
    for (let i = 0; i < qstn_no_arr?.length; i++) {
      if (qstn_no_arr[i]?.correct === true) {
        count++;
      }
    }
    setScore(count);
  };
  // const SolTime ;
  const handle_no_click = (index) => {
    if (Active !== index) {
      if (qstn_no_arr[index]?.correct !== "") {
        setActive(index);
        setShow_submit(true);
        setShow_next(false);
      } else if (qstn_no_arr[index - 1]?.correct !== "") {
        setActive(index);
        setShow_submit(true);
        setShow_next(false);
      }
    }
  };

  // structure leranng

  const [checkboxStatus, setCheckboxStatus] = useState({});
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());

  const handle_back_click = (index) => {
    setShow_sol(false);
    if (
      (Active !== 0 && child === 0) ||
      question[Active].sub_question.length === 0
    ) {
      setActive(Active - 1);
      setChild(question[Active - 1].sub_question.length - 1);
      if (final_index === Active) {
        setFinal_index(final_index - 1);
        setInitial_index(initial_index - 1);
      }
    } else {
      setChild(child - 1);
    }
    const currentQuestionId =
      question[Active]?.sub_question[child]?.id || question[Active]?.id;
    setCheckboxChecked(checkboxStatus[currentQuestionId] || false);
  };
  // useEffect(() => {
  //   setCheckboxChecked(false);
  // }, [Active, child]);
  // const handleCheckboxChange = () => {
  //   setCheckboxChecked(!checkboxChecked);
  // };

  const handleCheckboxChange = () => {
    const currentQuestionId =
      question[Active]?.sub_question[child]?.id || question[Active]?.id;
    const newStatus = !checkboxChecked;
    setCheckboxChecked(newStatus);
    setCheckboxStatus((prevStatus) => ({
      ...prevStatus,
      [currentQuestionId]: newStatus,
    }));
  };

  useEffect(() => {
    const currentQuestionId =
      question[Active]?.sub_question[child]?.id || question[Active]?.id;
    setCheckboxChecked(checkboxStatus[currentQuestionId] || false);
  }, [Active, child, checkboxStatus]);

  const [currentQuestionAnswered, setCurrentQuestionAnswered] = useState(null);
  const handle_next_click = async (questionAnswer) => {
    setShow_sol(false);
    const currentQuestionId =
      question[Active]?.sub_question[child]?.id || question[Active]?.id;
    if (answeredQuestions.has(currentQuestionId)) {
      if (
        (Active !== question?.length - 1 &&
          child == question[Active].sub_question.length - 1) ||
        question[Active].sub_question.length === 0
      ) {
        setActive(Active + 1);
        setChild(0);
        if (final_index === Active) {
          setFinal_index(final_index + 1);
          setInitial_index(initial_index + 1);
        }
      } else {
        setChild(child + 1);
      }
      return;
    }
    if (currentQuestionAnswered === currentQuestionId) {
      return;
    }

    setCurrentQuestionAnswered(currentQuestionId);
    const token = localStorage.getItem("token");

    const answerStatus = checkboxChecked ? 1 : 0;
    const ques_id =
      question[Active]?.sub_question[child]?.id || question[Active]?.id;
    const points =
      question[Active]?.sub_question[child]?.mark || question[Active]?.mark;
    try {
      let bodyData = new FormData();
      bodyData.append("student_id", studentId);
      bodyData.append("paper_id", state?.id);
      bodyData.append("question_id", ques_id);
      bodyData.append("answer_status", answerStatus);
      bodyData.append("points", points);
      bodyData.append("date", formatDate(new Date()));
      await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-challenge-question`,
        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      setAnsweredQuestions(new Set(answeredQuestions).add(currentQuestionId));
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        // clear_local_storage();
      } else if (e?.response?.status === 401) {
        // clear_local_storage();
      } else {
        // toast.error("Something went wrong");
        console.log(e);
      }
    }
    if (
      (Active !== question?.length - 1 &&
        child == question[Active].sub_question.length - 1) ||
      question[Active].sub_question.length === 0
    ) {
      setActive(Active + 1);
      setChild(0);
      if (final_index === Active) {
        setFinal_index(final_index + 1);
        setInitial_index(initial_index + 1);
      }
    } else {
      setChild(child + 1);
    }
  };

  const handleAutoCheck = () => {
    const data = question[Active];
    if (
      question[Active]?.answers == null ||
      question[Active]?.sub_question[child]?.answers == null
    ) {
      setShow_sol(true);
      setAutoHide(false);
    }
  };

  const handleSolution = () => {
    const data =
      question[Active]?.sub_question?.length > 0
        ? question[Active].sub_question[child]
        : question[Active];

    // Store the solution and theory in local storage
    localStorage.setItem("solution", JSON.stringify(data.solution));
    localStorage.setItem("theory", JSON.stringify(data.theory));
    localStorage.setItem("activeQuestion", Active);
    localStorage.setItem("activeChild", child);
    localStorage.setItem("checkboxStatus", JSON.stringify(checkboxStatus));
    localStorage.setItem(
      "answeredQuestions",
      JSON.stringify([...answeredQuestions])
    );
    localStorage.setItem("currentQuestionAnswered", currentQuestionAnswered);

    navigate("/solution_test", {
      state: {
        time: data.time,
      },
    });
  };
  const handleNavigate = () => {
    navigate("/challenge");

    localStorage.removeItem("pp_qstn");
    localStorage.removeItem("solution");
    localStorage.removeItem("selftest_qstn_arr");
    localStorage.removeItem("active_qstn");
    localStorage.removeItem("theory");
    localStorage.removeItem("mode");
    localStorage.removeItem("time");
    localStorage.removeItem("activeChild");
    localStorage.removeItem("activeQuestion");
    localStorage.removeItem("checkboxStatus");
    localStorage.removeItem("answeredQuestions");
    localStorage.removeItem("currentQuestionAnswered");
  };
  useEffect(() => {
    if (Active === 0 && child === 0) {
      setBack(false);
    } else {
      if (Active === 0 && question[Active]?.sub_question?.length === 0) {
        setBack(false);
      } else {
        setBack(true);
      }
    }
  }, [Active, child]);

  useEffect(() => {
    if (Active === question.length - 1) {
      if (
        question[Active].sub_question.length - 1 === child ||
        question[Active].sub_question.length == 0
      ) {
        setHideButton("none");
      }
    } else {
      setHideButton("block");
    }
  }, [Active, child]);
  useEffect(() => {
    if (data?.question_type == "Structured question") {
      const activeQuestion = localStorage.getItem("activeQuestion");
      const activeChild = localStorage.getItem("activeChild");
      const storedCheckboxStatus = localStorage.getItem("checkboxStatus");
      const storedAnsweredQuestions = localStorage.getItem("answeredQuestions");
      const storedCurrentQuestionAnswered = localStorage.getItem(
        "currentQuestionAnswered"
      );

      if (activeQuestion !== null) {
        setActive(Number(activeQuestion));
      } else {
        setActive(0);
      }

      if (activeChild !== null) {
        setChild(Number(activeChild));
      } else {
        setChild(0);
      }
      if (storedCheckboxStatus !== null) {
        setCheckboxStatus(JSON.parse(storedCheckboxStatus));
      }

      if (storedAnsweredQuestions !== null) {
        setAnsweredQuestions(new Set(JSON.parse(storedAnsweredQuestions)));
      }

      if (storedCurrentQuestionAnswered !== null) {
        setCurrentQuestionAnswered(storedCurrentQuestionAnswered);
      }
    }
  }, [data]);

  useEffect(() => {
    const user = localStorage.getItem("User");
    setUserData(JSON.parse(user));
  }, []);

  const handle_Submit = async () => {
    setShowModal(true);
    const currentQuestionId =
      question[Active]?.sub_question[child]?.id || question[Active]?.id;

    if (currentQuestionAnswered === currentQuestionId) {
      return;
    }

    setCurrentQuestionAnswered(currentQuestionId);
    const token = localStorage.getItem("token");

    const answerStatus = checkboxChecked ? 1 : 0;
    const ques_id =
      question[Active]?.sub_question[child]?.id || question[Active]?.id;
    const points =
      question[Active]?.sub_question[child]?.mark || question[Active]?.mark;
    try {
      let bodyData = new FormData();
      bodyData.append("student_id", studentId);
      bodyData.append("paper_id", state?.id);
      bodyData.append("question_id", ques_id);
      bodyData.append("answer_status", answerStatus);
      bodyData.append("points", points);
      bodyData.append("date", formatDate(new Date()));
      await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-challenge-question`,
        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        // clear_local_storage();
      } else if (e?.response?.status === 401) {
        // clear_local_storage();
      } else {
        // toast.error("Something went wrong");
        console.log(e);
      }
    }
  };
  return (
    <>
      {data?.question_type == "Structured question" ? (
        <>
          <div className="pp_test_container">
            <div className="d-flex gap-3 justify-content-between align-items-center w-100 ">
              <div className=""></div>

              <div className="num_pagination">
                {initial_index !== 0 && question?.length > 10 && (
                  <div className="mt-1" onClick={() => handleBackward()}>
                    <ArrowBackIosIcon style={{ color: "#8B60FD" }} />
                  </div>
                )}

                {question?.map((elem, index) => {
                  return (
                    <div key={index}>
                      {index <= final_index && index >= initial_index && (
                        <>
                          {index === Active ? (
                            <div className="mx-1">
                              <div
                                className="pp_index_active"
                                onClick={() => {
                                  handle_no_click(index);
                                }}
                              >
                                {index + 1}
                              </div>
                              <div className="d-flex mt-2">
                                {elem?.sub_question?.length > 0 &&
                                  elem?.sub_question?.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          index === child
                                            ? ` me-2 pp_index_active`
                                            : "me-2 pp_index"
                                        }
                                        onClick={() => setChild(index)}
                                      >
                                        {index + 1}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ) : (
                            <div
                              key={index}
                              className="mx-1"
                              onClick={() => {
                                handle_no_click(index);
                              }}
                            >
                              <div className="pp_index">{index + 1}</div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}

                {final_index + 1 !== question?.length &&
                  question?.length > 10 && (
                    <div className="mt-1 mx-2" onClick={() => handleForward()}>
                      <ArrowForwardIosIcon style={{ color: "#8B60FD" }} />
                    </div>
                  )}
              </div>

              {!show_sol && (
                <div
                  className="pp_close new-btn"
                  onClick={() => handleNavigate()}
                >
                  Close
                </div>
              )}
              {show_sol && (
                <img
                  src={close}
                  alt=""
                  onClick={() => {
                    setShow_sol(false);
                    setAutoHide(true);
                  }}
                  className="pp_close_icon"
                />
              )}
            </div>

            <div className="d-flex justify-content-center mt-3">
              {show_sol ? (
                <div className="d-flex justify-content-center mt-3">
                  {question[Active]?.sub_question.length === 0 ? (
                    <>
                      {
                        <div className="pp_test_white_div">
                          <img src={question[Active]?.sa_answer} alt="answer" />
                        </div>
                      }
                    </>
                  ) : (
                    <>
                      {
                        <div className="pp_test_white_div">
                          <img
                            src={
                              question[Active]?.sub_question[child]?.sa_answer
                            }
                            alt="answer"
                          />
                        </div>
                      }
                    </>
                  )}
                </div>
              ) : question[Active] && question[Active]?.sub_question == 0 ? (
                <div>
                  <div className="pp_test_white_div">
                    <div className="d-flex justify-content-end">
                      <input
                        className="custom-checkbox m-4"
                        type="checkbox"
                        checked={checkboxChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div
                      className="pp_test_white_div img-fluid"
                      style={{ height: "unset" }}
                    >
                      {question[Active]?.question?.type == "image" ? (
                        <figure className="image">
                          <img
                            src={question[Active]?.question?.content}
                            alt="question"
                          />
                        </figure>
                      ) : (
                        <p>{question[Active]?.question?.content}</p>
                      )}

                      {question[Active]?.sub_question_text && (
                        <p className="subQuestionText">
                          {question[Active]?.sub_question_text}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    {question[Active]?.sub_question.map((item, index) => {
                      return (
                        <div key={index}>
                          {index === child && (
                            <div className="pp_test_white_div">
                              <div className="d-flex justify-content-end">
                                <input
                                  className="custom-checkbox m-4"
                                  type="checkbox"
                                  checked={checkboxChecked}
                                  onChange={handleCheckboxChange}
                                />
                              </div>
                              <div
                                className="pp_test_white_div img-fluid"
                                style={{ height: "unset" }}
                              >
                                {item?.question?.type == "image" ? (
                                  <figure className="image">
                                    <img
                                      src={item?.question?.content}
                                      alt="question"
                                    />
                                  </figure>
                                ) : (
                                  <p>{item?.question?.content}</p>
                                )}

                                {item?.sub_question_text && (
                                  <p className="subQuestionText">
                                    {item?.sub_question_text}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            {!loading ? (
              <>
                {question.length === 0 ? (
                  <div className="no_data1_img" style={{ left: "0" }}>
                    <img src={no_data} alt="" />
                  </div>
                ) : (
                  <div className="postDiv">
                    <div className="pp_year d-flex justify-content-start">
                      Year : {state?.year}
                    </div>
                    <div className="d-flex justify-content-center pp_test_btn">
                      {back && !show_sol && (
                        <div
                          className="mx-2 pp_back_btn"
                          onClick={() => handle_back_click()}
                        >
                          Back
                        </div>
                      )}

                      {!show_sol && (
                        <>
                          <div
                            className="mx-2 pp_auto_check_btn"
                            // onClick={() => handleAutoCheck()}
                            onClick={() => handleSolution()}
                          >
                            Solution
                          </div>
                          <div
                            className="mx-2 pp_auto_check_btn d-none"
                            onClick={() => handleAutoCheck()}
                          >
                            {"Auto Check"}
                          </div>
                        </>
                      )}
                      {/* {hideButton === "block" && autoHide && ( */}
                      <>
                        {(Active !== question?.length - 1 ||
                          (Active === question?.length - 1 &&
                            question[Active]?.sub_question.length > 0 &&
                            child !==
                              question[Active]?.sub_question.length - 1)) && (
                          <div
                            className="mx-2 pp_back_btn"
                            onClick={handle_next_click}
                          >
                            Next
                          </div>
                        )}
                      </>
                      {/* )} */}

                      {(Active === question?.length - 1 &&
                        question[Active]?.sub_question.length === 0 &&
                        !show_sol) ||
                      (Active === question?.length - 1 &&
                        child === question[Active]?.sub_question.length - 1 &&
                        !show_sol) ? (
                        <div
                          className="mx-2 pp_back_btn"
                          onClick={handle_Submit}
                        >
                          Submit
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className=" mt-5">
                <ClipLoader
                  color="black"
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
          <ScoreModal
            className="pp_model mt-3"
            title={"Your score has been saved."}
            onClick={() => handleClose()}
            show={show_modal}
            onHide={() => setShowModal(!show_modal)}
          />
        </>
      ) : (
        <>
          <div className="selfTest_bg comic_font">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="d-flex justify-content-between">
              <div className="">
                <Back_btn
                  border="2px solid #8B60FD"
                  style={{ cursor: "default", display: "none" }}
                >
                  <AccessTimeIcon
                    style={{
                      fontSize: "19px",
                      background: "#E97838",
                      color: "#FDF980",
                      borderRadius: "50%",
                      marginTop: "-3px",
                      marginRight: "3px",
                    }}
                  />
                  {formatTime(timeLeft)}{" "}
                </Back_btn>
              </div>
              <div className="num_pagination" id="NumPage">
                {initial_index !== 0 && qstn_no_arr?.length > 10 && (
                  <div className="mt-1" onClick={() => handleBackward()}>
                    <ArrowBackIosIcon style={{ color: "#8B60FD" }} />
                  </div>
                )}
                {qstn_no_arr &&
                  qstn_no_arr?.map((elem, index) => (
                    <div key={index}>
                      {index <= final_index && index >= initial_index && (
                        <>
                          {index === Active ? (
                            <div
                              className="active_qtn_num"
                              onClick={() => {
                                handle_no_click(index);
                              }}
                              key={index}
                            >
                              {index + 1}
                            </div>
                          ) : (
                            <>
                              {elem.correct === "" ? (
                                <div
                                  className="qtn_num"
                                  onClick={() => {
                                    handle_no_click(index);
                                  }}
                                  key={index}
                                >
                                  {index + 1}
                                </div>
                              ) : (
                                <>
                                  {!elem.correct ? (
                                    <div
                                      className="qtn_num"
                                      style={{
                                        backgroundColor: "#EA1C1C",
                                        color: "white",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        handle_no_click(index);
                                      }}
                                      key={index}
                                    >
                                      {index + 1}
                                    </div>
                                  ) : (
                                    <div
                                      className="qtn_num"
                                      style={{
                                        backgroundColor: "#2BB10D",
                                        color: "white",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        handle_no_click(index);
                                      }}
                                      key={index}
                                    >
                                      {index + 1}
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                {final_index + 1 !== qstn_no_arr?.length &&
                  qstn_no_arr?.length > 10 && (
                    <div className="mt-1 mx-2" onClick={() => handleForward()}>
                      <ArrowForwardIosIcon style={{ color: "#8B60FD" }} />
                    </div>
                  )}
              </div>
              <div className="">
                <Back_btn
                  border="2px solid #8B60FD"
                  onClick={() => handleClose()}
                >
                  Close
                </Back_btn>
              </div>
            </div>
            {!loading ? (
              <>
                {question?.length ? (
                  <>
                    {question?.length > 0 && (
                      <>
                        {question?.map((elem, index) => (
                          <div key={index}>
                            {index === Active && (
                              <div className="qstn_bg">
                                <div>
                                  <div className="d-flex">
                                    <div className="question_1">
                                      <div className="qstn_sound">
                                        <div>Q. {index + 1}</div>
                                        {elem.question?.type == "text" && (
                                          <div className="mx-1">
                                            {supported && (
                                              <div className="speechMenu">
                                                {!speaking ? (
                                                  <VolumeOffIcon
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      speak(
                                                        elem?.question?.content,
                                                        voices[2]
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <img
                                                    src={sound}
                                                    alt=""
                                                    className="sound_qstn"
                                                    onClick={cancel}
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="question_div">
                                      <div className="question">
                                        {elem.question?.type == "image" ? (
                                          <div>
                                            <figure className="image image-size-paper">
                                              <img
                                                className="w-100"
                                                src={elem?.question?.content}
                                                alt="question"
                                              />
                                            </figure>
                                          </div>
                                        ) : (
                                          <div>
                                            <p>{elem.question?.content}</p>

                                            {elem?.image_path && (
                                              <figure className="image">
                                                <img
                                                  src={elem?.image_path}
                                                  alt="question"
                                                />
                                              </figure>
                                            )}
                                          </div>
                                        )}
                                        {elem?.sub_question_text && (
                                          <p className="subQuestionText">
                                            {elem?.sub_question_text}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {elem?.question_type === "One word" ? (
                                    // Render the input box for one-word answer
                                    <div
                                      className="question_div_mobile col-lg-6 col-md-6 col-sm-6"
                                      // style={{ zIndex: "99999" }}
                                    >
                                      <input
                                        // disabled={qstn_no_arr[Active].ans_id && true}
                                        className="p-2"
                                        type="text"
                                        placeholder=""
                                        value={
                                          qstn_no_arr[Active]?.ans_id !== ""
                                            ? qstn_no_arr[Active]?.ans_id
                                            : option
                                        }
                                        style={{
                                          borderRadius: "10px",
                                          // zIndex: "99999",
                                          backgroundColor: qstn_no_arr[Active]
                                            ?.correct
                                            ? "#2BB10D"
                                            : "#EA1C1C",
                                          color: qstn_no_arr[Active]?.correct
                                            ? "#fff"
                                            : "#fff",
                                          pointerEvents:
                                            qstn_no_arr[Active]?.ans_id &&
                                            "none",
                                        }}
                                        onChange={(e) =>
                                          setOption(e.target.value)
                                        }
                                        disabled={showSolution}
                                      />
                                    </div>
                                  ) : elem?.question_type ===
                                    "Filling the blank" ? (
                                    <div
                                      className="question_div_mobile col-lg-6 col-md-6 col-sm-6"
                                      // style={{ zIndex: "99999" }}
                                    >
                                      <input
                                        className="p-2"
                                        type="text"
                                        placeholder=""
                                        value={
                                          qstn_no_arr[Active]?.ans_id !== ""
                                            ? qstn_no_arr[Active]?.ans_id
                                            : option
                                        }
                                        style={{
                                          borderRadius: "10px",
                                          // zIndex: "99999",
                                          backgroundColor: qstn_no_arr[Active]
                                            ?.correct
                                            ? "#2BB10D"
                                            : "#EA1C1C",
                                          color: qstn_no_arr[Active]?.correct
                                            ? "#fff"
                                            : "#fff",
                                          pointerEvents:
                                            qstn_no_arr[Active]?.ans_id &&
                                            "none",
                                        }}
                                        onChange={(e) =>
                                          setOption(e.target.value)
                                        }
                                        disabled={showSolution}
                                      />
                                    </div>
                                  ) : (
                                    <div className="d-flex justify-content-center">
                                      <div className="options mt-3">
                                        {elem?.question_type ==
                                        "Mcq multiple" ? (
                                          <>
                                            {elem?.answers.map((item, idx) => (
                                              <div key={idx} className="optn">
                                                <label className="d-flex">
                                                  <div>
                                                    <input
                                                      type="checkbox"
                                                      className="me-2"
                                                      // checked={selectedOptions.includes(
                                                      //   item.id
                                                      // )}
                                                      checked={
                                                        qstn_no_arr[Active]
                                                          ?.ans_id !== ""
                                                          ? qstn_no_arr[
                                                              Active
                                                            ]?.ans_id?.includes(
                                                              item.id
                                                            )
                                                          : selectedOptions.includes(
                                                              item.id
                                                            )
                                                      }
                                                      onChange={() =>
                                                        handleOptionChange(
                                                          item.id
                                                        )
                                                      }
                                                      disabled={showSolution}
                                                    />
                                                  </div>
                                                  <div className="me-2">
                                                    <span>({idx + 1})</span>
                                                  </div>
                                                  {item?.type == "image" ? (
                                                    <div>
                                                      <figure className="image">
                                                        <img
                                                          className="w-100"
                                                          src={item?.answer}
                                                          alt="question"
                                                        />
                                                      </figure>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <p> {item?.answer}</p>
                                                    </div>
                                                  )}
                                                </label>
                                              </div>
                                            ))}
                                          </>
                                        ) : (
                                          <>
                                            {elem?.answers.map(
                                              (item, index) => (
                                                <div key={index}>
                                                  {qstn_no_arr[Active]
                                                    ?.correct == false ? (
                                                    <div
                                                      className="optn"
                                                      style={{
                                                        backgroundColor:
                                                          qstn_no_arr[Active]
                                                            ?.ans_id === item.id
                                                            ? "#EA1C1C"
                                                            : "#daccff",
                                                        color:
                                                          qstn_no_arr[Active]
                                                            ?.ans_id === item.id
                                                            ? "#fff"
                                                            : "#000",
                                                      }}
                                                    >
                                                      <div className="d-flex">
                                                        <span className="me-2">
                                                          ({index + 1})
                                                        </span>
                                                        {item?.type ===
                                                        "image" ? (
                                                          <div>
                                                            <figure className="image">
                                                              <img
                                                                className="w-100"
                                                                src={
                                                                  item?.answer
                                                                }
                                                                alt="question"
                                                              />
                                                            </figure>
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <p>
                                                              {item?.answer}
                                                            </p>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="optn"
                                                      style={{
                                                        backgroundColor:
                                                          qstn_no_arr[Active]
                                                            ?.ans_id === item.id
                                                            ? "#2BB10D"
                                                            : "#daccff",
                                                        color:
                                                          qstn_no_arr[Active]
                                                            ?.ans_id === item.id
                                                            ? "#fff"
                                                            : "#000",
                                                      }}
                                                    >
                                                      <div className="d-flex">
                                                        <span className="me-2">
                                                          ({index + 1})
                                                        </span>
                                                        {item?.type ===
                                                        "image" ? (
                                                          <div>
                                                            <figure className="image">
                                                              <img
                                                                className="w-100"
                                                                src={
                                                                  item?.answer
                                                                }
                                                                alt="question"
                                                              />
                                                            </figure>
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <p>
                                                              {item?.answer}
                                                            </p>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {correct === true && (
                                    <div className="d-flex justify-content-center d-none">
                                      <div className="right_mob d-none">
                                        <img src={right_ans} alt="" />
                                        <div className="correct_text">
                                          Correct
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {correct === false && (
                                    <div className="d-flex justify-content-center d-none">
                                      <div className="correct_mob d-none">
                                        <img src={wrong_ans} alt="" />
                                        <div
                                          className="correct_text"
                                          style={{ color: "#EB1D1D" }}
                                        >
                                          Incorrect
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {correct && (
                                    <div className="d-flex justify-content-center">
                                      <div className="right_mob">
                                        <img src={right_ans} alt="" />
                                        <div className="correct_text">
                                          Correct
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {correct === false && (
                                    <div className="d-flex justify-content-center">
                                      <div className="correct_mob">
                                        <img src={wrong_ans} alt="" />
                                        <div
                                          className="correct_text"
                                          style={{ color: "#EB1D1D" }}
                                        >
                                          Incorrect
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="correct_top_main_div mt-5">
                                    <div
                                      className="d-flex justify-content-between btn_div_selftest w-100"
                                      style={{ position: "unset" }}
                                    >
                                      <div className="mt-1 mb-2 solution_mob">
                                        {showSolution && (
                                          <SaveBtn
                                            onClick={() => {
                                              navigate("/solution_test", {
                                                state: {
                                                  id: id,
                                                  time: timeLeft,
                                                },
                                              });
                                            }}
                                            className=""
                                            width="95px"
                                            bg="#FF6700"
                                            height="38px"
                                            shadow="2px 2px 2px 2px #FF6700"
                                            color="white"
                                          >
                                            Solution
                                          </SaveBtn>
                                        )}
                                      </div>
                                      {correct === true && (
                                        <div className="right mt-1">
                                          <img src={right_ans} alt="" />
                                          <div className="correct_text">
                                            Correct
                                          </div>
                                        </div>
                                      )}
                                      {correct === false && (
                                        <div className="correct mt-2">
                                          <img src={wrong_ans} alt="" />
                                          <div
                                            className="correct_text"
                                            style={{ color: "#EB1D1D" }}
                                          >
                                            Incorrect
                                          </div>
                                        </div>
                                      )}

                                      <div className="mt-1 self_Test">
                                        <div className="d-flex">
                                          {index > 0 && (
                                            <SaveBtn
                                              onClick={() =>
                                                handle_back_btn(index)
                                              }
                                              className="bck_btn_self"
                                              width="89px"
                                              bg="#918E96"
                                              shadow="2px 2px 2px 2px #918E96"
                                              style={{ color: "white" }}
                                              height="35px"
                                              color="white"
                                              f_weight="550"
                                              f_size="20px"
                                            >
                                              Back
                                            </SaveBtn>
                                          )}

                                          {show_submit && !show_next && (
                                            <SaveBtn
                                              onClick={() => {
                                                let param = elem.answers;

                                                return handleSubmit(
                                                  elem.id,
                                                  param,
                                                  index,
                                                  elem?.question_type,
                                                  elem?.answers,
                                                  elem?.mark
                                                );
                                              }}
                                              className="next_btn_self"
                                              width="89px"
                                              bg="black"
                                              shadow="2px 2px 2px 2px black"
                                              height="35px"
                                              color="white"
                                              f_weight="550"
                                              f_size="20px"
                                            >
                                              Next
                                            </SaveBtn>
                                          )}
                                          {show_next &&
                                            !show_submit &&
                                            question?.length > Active + 1 && (
                                              <SaveBtn
                                                onClick={() =>
                                                  handleNext(index)
                                                }
                                                className="next_btn_self"
                                                width="89px"
                                                bg="black"
                                                shadow="2px 2px 2px 2px black"
                                                color="white"
                                                style={{ width: "89px" }}
                                                height="35px"
                                                f_weight="550"
                                                f_size="20px"
                                              >
                                                Next
                                              </SaveBtn>
                                            )}
                                          {show_next &&
                                            !show_submit &&
                                            question?.length === Active + 1 && (
                                              <SaveBtn
                                                onClick={() =>
                                                  handle_submit_test()
                                                }
                                                className="next_btn_self"
                                                width="89px"
                                                bg="black"
                                                shadow="2px 2px 2px 2px black"
                                                height="35px"
                                                color="white"
                                                f_weight="550"
                                                f_size="20px"
                                              >
                                                Submit
                                              </SaveBtn>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <div className="no_data1_img" style={{ left: "0" }}>
                    <img src={no_data} alt="" />
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex justify-content-center w-100">
                <ClipLoader
                  color="black"
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}

            <div className="chptr_name_selftest d-flex justify-content-start mt-2">
              Year : {state?.year}
            </div>
          </div>
          <SuccessModal
            title={"Are you sure? You want to submit your pastpaper test?"}
            show={submit_test}
            onHide={() => setsubmit_test(!submit_test)}
            myCallback={(param) => {
              if (param == true) {
                setScore_modal(param);
              } else if (param == false) {
                setsubmit_test(param);
              }
            }}
          />
          <ScoreModal
            title={"Your score has been saved."}
            onClick={() => handleClose()}
            // totalScore={qstn_no_arr?.length}
            // obtainedScore={score}
            // subTitle={" Score obtained"}
            show={score_modal}
            onHide={() => setScore_modal(!setScore_modal)}
          />
        </>
      )}
    </>
  );
}

export default ChallengeAnswer;
