import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import {
  noBadge,
  purplecloseBtn,
  unlock_reward,
} from "../../Assets/Images/index";
import LockIcon from "@mui/icons-material/Lock";
import "./RewardNew.css";
import { useApiContext } from "../../Context/ApiContext";
import SummaryModal from "./SummaryModal";

const Bagde = () => {
  const { exam_type, months, userData, getUserProfile } = useApiContext();
  const [AllBadges, setAllBadges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableBadges, setAvailableBadges] = useState([]);
  const [availableBadgesData, setAvailableBadgesData] = useState([]);
  const [availableBadgesNew, setAvailableBadgesNew] = useState([]);
  const [badgesDataNew, setBadgesDataNew] = useState([]);
  const [badgeCollection, setBadgeCollection] = useState([]);
  const [badgeCollectionData, setBadgeCollectionData] = useState([]);
  const [showDrp, setShowDrop] = useState(false);
  const [DrpData, setDrpData] = useState("All");
  const [showDrp2, setShowDrop2] = useState(false);
  const [DrpData2, setDrpData2] = useState("All");
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isBadgeModalOpen, setIsBadgeModalOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const [selectedCollectionBadge, setSelectedCollectionBadge] = useState(null);
  const [isCollectionViewAllModalOpen, setIsCollectionViewAllModalOpen] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    get_all_Badges();
  }, []);

  const get_all_Badges = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/badges`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setAllBadges(resp.data);
        setLoading(false);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const clear_local_storage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    navigate("/");
  };

  useEffect(() => {
    if (userData) {
      setAvailableBadgesNew(userData?.available_badges_new);
      setBadgesDataNew(userData?.badges_data_new);
    }
  }, [userData]);

  useEffect(() => {
    // Create a dictionary from all_badge for easy lookup
    if (availableBadgesNew?.length > 0) {
      const allBadgeDict = {};
      AllBadges.forEach((badge) => {
        allBadgeDict[badge.id] = badge;
      });

      // Filter the data by badges_id
      const filtered = availableBadgesNew
        .map((data) => {
          const badge = allBadgeDict[data.badges_id];
          if (badge) {
            return {
              ...badge,
              badge_date: data.badge_date,
              locked: false,
            };
          }
          return null;
        })
        .filter(Boolean);

      setAvailableBadgesData([...AllBadges, ...filtered]);
    } else {
      setAvailableBadgesData(AllBadges);
    }
  }, [AllBadges, userData]);

  useEffect(() => {
    if (badgesDataNew?.length > 0) {
      const badgeCounts = {};
      const badgeDates = {};
      badgesDataNew.forEach((badge) => {
        const { badges_id, badge_date } = badge;
        badgeCounts[badges_id] = (badgeCounts[badges_id] || 0) + 1;
        badgeDates[badges_id] = badge_date;
      });
      const filteredBadges = AllBadges.filter((badge) => {
        const { id } = badge;
        const count = badgeCounts[id] || 0;
        return count > 0;
      });
      const processedIDs = new Set();

      const groupedAndCountedBadges = [];

      filteredBadges.forEach((badge) => {
        const { id, type_id, badge_name, to, ...rest } = badge;
        if (!processedIDs.has(id)) {
          const totalId = badgeCounts[id] || 0;
          groupedAndCountedBadges.push({
            id,
            type_id,
            badge_name,
            totalId,
            to,
            badge_date:
              badgesDataNew.find((b) => b.badges_id === id)?.badge_date || null,
            ...rest,
          });
          processedIDs.add(id);
        }
      });

      //   console.log(groupedAndCountedBadges);
      setBadgeCollectionData(groupedAndCountedBadges);
    }
  }, [AllBadges, userData]);

  //   dropdown

  const handle_drpChange = (id, name) => {
    setDrpData(name);
    setShowDrop(false);
  };
  const handleMonthsChange = (name) => {
    setDrpData2(name);
    setShowDrop2(false);
  };

  //Filtering by type_id and end_date
  const filterRewards = (rewards) => {
    return rewards.filter((reward) => {
      const selectedType = exam_type.find((type) => type.type === DrpData);
      const selectedMonth = months.find(
        (month) => month.month_name === DrpData2
      );

      const rewardEndDate = new Date(reward?.badge_date);
      const rewardMonth = rewardEndDate.getMonth() + 1;
      // const currentYear = new Date().getFullYear();
      const monthFilter = selectedMonth
        ? rewardMonth === selectedMonth.month_number
        : true;

      const typeFilter = selectedType
        ? reward.type_id === selectedType.id
        : true;

      return monthFilter && typeFilter;
    });
  };

  useEffect(() => {
    if (badgeCollectionData?.length > 0) {
      setBadgeCollection(filterRewards(badgeCollectionData));
    }
    if (availableBadgesData?.length > 0) {
      setAvailableBadges(filterRewards(availableBadgesData));
    }
  }, [DrpData, DrpData2, badgeCollectionData, availableBadgesData]);

  //   modals
  const handleViewAllClick = () => {
    setIsMainModalOpen(true);
  };

  const handleCloseMainModal = () => {
    setIsMainModalOpen(false);
  };

  const handleBadgeClick = (badge) => {
    setSelectedBadge(badge);
    setIsMainModalOpen(false);
    setIsBadgeModalOpen(true);
  };

  const handleCloseBadgeModal = () => {
    setIsBadgeModalOpen(false);
  };

  const handleCollectionBadgeClick = (badge) => {
    setSelectedCollectionBadge(badge);
    setIsCollectionModalOpen(true);
    setIsCollectionViewAllModalOpen(false);
  };

  const handleCloseCollectionModal = () => {
    setIsCollectionModalOpen(false);
  };

  const handleCollectionViewAllClick = () => {
    setIsCollectionViewAllModalOpen(true);
  };

  const handleCloseCollectionViewAllModal = () => {
    setIsCollectionViewAllModalOpen(false);
  };

  //   add to collection

  const handleAddCollection = async (badges_id) => {
    const token = localStorage.getItem("token");
    try {
      const bodyData = new FormData();
      bodyData.append("badges_id", badges_id);
      await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/badges-collect`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      //   console.log(resp);
      setIsBadgeModalOpen(false);

      getUserProfile();
      get_all_Badges();
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // summary
  const [showSummaryModal, setSummaryShowModal] = useState(false);

  const handleShowSummaryModal = () => setSummaryShowModal(true);
  const handleCloseSummaryModal = () => setSummaryShowModal(false);

  //console.log(collectionData);

  // summary

  // const [filteredData, setFilteredData] = useState([]);
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    const filterRewardsByMonth = () => {
      if (DrpData2 === "All") {
        return badgeCollectionData;
      }

      return badgeCollectionData.filter((data) => {
        const monthNumber = new Date(data.badge_date).getMonth() + 1;
        const month = months.find(
          (month) => month.month_number === monthNumber
        );
        return month && month.month_name === DrpData2;
      });
    };

    const groupRewardsByTypeId = (rewards) => {
      return rewards.reduce((acc, reward) => {
        const { type_id, count: rewardValue } = reward;
        if (!acc[type_id]) {
          acc[type_id] = { ...reward, total_reward: parseInt(rewardValue, 10) };
        } else {
          acc[type_id].total_reward += parseInt(rewardValue, 10);
        }
        return acc;
      }, {});
    };

    const filtered = filterRewardsByMonth();
    const grouped = groupRewardsByTypeId(filtered);

    // setFilteredData(filtered);
    setGroupedData(grouped);
  }, [DrpData2, badgeCollectionData]);

  return (
    <>
      {loading ? (
        <div className="">
          <ClipLoader
            color="#000"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          {AllBadges?.length === 0 && (
            <>
              <div className="no_data_img">
                <div>
                  <img src={noBadge} alt="" />
                  <p className="textStyle">You don’t have any Badges</p>
                  <p className="textStyle">No Badges created by the Admin</p>
                </div>
              </div>
            </>
          )}
          {AllBadges.length !== 0 && (
            <>
              <div className="rewards_container w-100 align-items-center">
                <div className="self_dropdown mt-4">
                  <div className="pp_dropdown" style={{ height: "unset" }}>
                    <div className="d-flex flex-wrap">
                      <p className="mb-2 drp_labour_color">Select Exam type</p>
                      <button
                        className="pp_dropbtn mt-0"
                        onClick={() => setShowDrop(!showDrp)}
                      >
                        <div className="d-flex justify-content-between slectExam ">
                          <span className="pastPaper_drpdown"> {DrpData} </span>
                          <KeyboardArrowDownIcon />
                        </div>
                      </button>

                      {showDrp && (
                        <div className="d-flex justify-content-center mx-3 z ">
                          <div
                            className="pp_dropdown-content"
                            style={{
                              width: "100%",
                              left: "0",
                              height: "unset",
                            }}
                          >
                            {exam_type.length !== 0 && (
                              <>
                                <button
                                  onClick={() => handle_drpChange(" ", "All")}
                                >
                                  All
                                </button>
                                {exam_type.map((item, index) => (
                                  <button
                                    key={index}
                                    onClick={() =>
                                      handle_drpChange(item.id, item.type)
                                    }
                                  >
                                    {item.type}
                                  </button>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="pp_dropdown_year space-dropdown"
                    style={{ height: "unset", right: "unset" }}
                  >
                    <div className="d-flex flex-wrap">
                      <p className="mb-2 drp_labour_color">Month</p>
                      <button
                        className="pp_dropbtn mt-0"
                        onClick={() => setShowDrop2(!showDrp2)}
                      >
                        <div className="d-flex justify-content-between selectYear ">
                          <span className="pastPaper_drpdown">
                            {" "}
                            {DrpData2}{" "}
                          </span>
                          <KeyboardArrowDownIcon />
                        </div>
                      </button>

                      {showDrp2 && (
                        <div className="d-flex justify-content-center mx-3 ">
                          <div
                            className="pp_dropdown-content"
                            style={{ width: "100%", left: "0" }}
                          >
                            {months.length !== 0 && (
                              <>
                                <button
                                  onClick={() => handleMonthsChange("All")}
                                >
                                  All
                                </button>
                                {months.map((item, index) => (
                                  <button
                                    key={index}
                                    onClick={() =>
                                      handleMonthsChange(item?.month_name)
                                    }
                                  >
                                    {item?.month_name}
                                  </button>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-dropdown summary-btn">
                    <Button
                      className="btn btn-primary"
                      onClick={handleShowSummaryModal}
                    >
                      Summary
                    </Button>
                  </div>
                </div>

                <div className="mt-4 badges_rewards_div">
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="upcoming_head">
                        Available Badge ({availableBadges.length})
                      </div>
                      <div
                        className="rewards_viewall"
                        onClick={handleViewAllClick}
                      >
                        View all
                      </div>
                    </div>
                    {availableBadges.length > 0 ? (
                      <div>
                        <div className="mt-3 rewards_cards">
                          {availableBadges.slice(0, 8).map((item, index) => (
                            <div
                              className="rewards"
                              onClick={() => handleBadgeClick(item)}
                              key={index}
                            >
                              <div className="reward_box reward_box_background">
                                {item?.locked == false ? null : (
                                  <div className="lock_icon">
                                    <LockIcon
                                      style={{
                                        color: "#ff9d00",
                                        fontSize: "30px",
                                      }}
                                    />
                                  </div>
                                )}

                                <div className="rewards_img">
                                  <img src={item?.image} alt="" />
                                </div>
                                <div className="mt-4">{item.badge_name}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="no_data_img">
                        <div className="noBadgeImg">
                          <p className="textStyle">
                            Not filtered Available badge
                          </p>
                        </div>
                      </div>
                    )}
                  </>

                  <div className="mt-4">
                    <div className="d-flex justify-content-between">
                      <div className="upcoming_head">
                        My collection ({badgeCollection.length})
                      </div>
                      <div
                        className="rewards_viewall"
                        onClick={handleCollectionViewAllClick}
                      >
                        View all
                      </div>
                    </div>

                    {badgeCollection.length > 0 ? (
                      <div className="mt-1 rewards_cards">
                        {badgeCollection.slice(0, 8).map((item, index) => (
                          <div
                            className="rewards"
                            key={index}
                            onClick={() => handleCollectionBadgeClick(item)}
                          >
                            <div
                              className="reward_box mt-3"
                              style={{ background: "#B498FF" }}
                            >
                              <div className="rewards_img">
                                <div className="rewards_img">
                                  <img src={item?.image} alt="" />
                                </div>
                              </div>
                              <div className="rewards_img">
                                <div className="mt-4 title-name">
                                  {item?.totalId} {item?.badge_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no_data_img">
                        <div className="noBadgeImg">
                          <p className="textStyle">
                            Not filtered collection badge
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <SummaryModal
        show={showSummaryModal}
        handleClose={handleCloseSummaryModal}
        data={groupedData}
      />
      <Modal
        show={isMainModalOpen}
        onHide={handleCloseMainModal}
        className="custom-modal custom-width"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <div className="upcoming_head">
              Available Badge ({availableBadges.length})
            </div>
            <div className="">
              <img
                src={purplecloseBtn}
                alt="Close"
                onClick={handleCloseMainModal}
              />
            </div>
          </div>
          <div className="self_dropdown mt-4">
            <div className="pp_dropdown" style={{ height: "unset" }}>
              <div className="d-flex flex-wrap">
                {/* <p className="mb-2">Select Exam type</p> */}
                <button
                  disabled={true}
                  className="pp_dropbtn mt-0"
                  onClick={() => setShowDrop(!showDrp)}
                >
                  <div className="d-flex justify-content-between slectExam ">
                    <span className="pastPaper_drpdown"> {DrpData} </span>
                    <KeyboardArrowDownIcon />
                  </div>
                </button>

                {showDrp && (
                  <div className="d-flex justify-content-center mx-3 z ">
                    <div
                      className="pp_dropdown-content"
                      style={{
                        width: "100%",
                        left: "0",
                        height: "unset",
                      }}
                    >
                      {exam_type.length !== 0 && (
                        <>
                          <button onClick={() => handle_drpChange(" ", "All")}>
                            All
                          </button>
                          {exam_type.map((item, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handle_drpChange(item.id, item.type)
                              }
                            >
                              {item.type}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="pp_dropdown_year space-dropdown"
              style={{ height: "unset", right: "unset" }}
            >
              <div className="d-flex flex-wrap">
                {/* <p className="mb-0">Select Exam type</p> */}
                <button
                  disabled={true}
                  className="pp_dropbtn mt-0"
                  onClick={() => setShowDrop2(!showDrp2)}
                >
                  <div className="d-flex justify-content-between selectYear ">
                    <span className="pastPaper_drpdown"> {DrpData2} </span>
                    <KeyboardArrowDownIcon />
                  </div>
                </button>

                {showDrp2 && (
                  <div className="d-flex justify-content-center mx-3 ">
                    <div
                      className="pp_dropdown-content"
                      style={{ width: "100%", left: "0" }}
                    >
                      {months.length !== 0 && (
                        <>
                          <button onClick={() => handleMonthsChange("All")}>
                            All
                          </button>
                          {months.map((item, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handleMonthsChange(item?.month_name)
                              }
                            >
                              {item?.month_name}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {availableBadges.length > 0 ? (
            <div className="rewards_cards mt-3">
              {availableBadges.map((item, index) => (
                <div
                  className="rewards"
                  onClick={() => handleBadgeClick(item)}
                  key={index}
                >
                  <div className="reward_box reward_box_background">
                    {item?.locked == false ? null : (
                      <div className="lock_icon">
                        <LockIcon
                          style={{
                            color: "#ff9d00",
                            fontSize: "30px",
                          }}
                        />
                      </div>
                    )}
                    <div className="rewards_img">
                      <img src={item?.image} alt="" />
                    </div>
                    {/* <div className="mt-4">{item.badge_name}</div> */}
                    <div className="rewards_img">
                      <div className="mt-4 title-name">{item?.badge_name}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no_data_img">
              <div className="noBadgeImg">
                <img src={noBadge} alt="" />
                <p className="textStyle">You don’t have any Badges</p>
                <p className="textStyle">No Badges created by the Admin</p>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={isCollectionViewAllModalOpen}
        onHide={handleCloseCollectionViewAllModal}
        className="custom-modal custom-width"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <div className="upcoming_head">
              My Collection ({badgeCollection.length})
            </div>
            <div className="">
              <img
                src={purplecloseBtn}
                alt="Close"
                onClick={handleCloseCollectionViewAllModal}
              />
            </div>
          </div>
          <div className="self_dropdown mt-4">
            <div className="pp_dropdown" style={{ height: "unset" }}>
              <div className="d-flex flex-wrap">
                {/* <p className="mb-2">Select Exam type</p> */}
                <button
                  disabled={true}
                  className="pp_dropbtn mt-0"
                  onClick={() => setShowDrop(!showDrp)}
                >
                  <div className="d-flex justify-content-between slectExam ">
                    <span className="pastPaper_drpdown"> {DrpData} </span>
                    <KeyboardArrowDownIcon />
                  </div>
                </button>

                {showDrp && (
                  <div className="d-flex justify-content-center mx-3 z ">
                    <div
                      className="pp_dropdown-content"
                      style={{
                        width: "100%",
                        left: "0",
                        height: "unset",
                      }}
                    >
                      {exam_type.length !== 0 && (
                        <>
                          <button onClick={() => handle_drpChange(" ", "All")}>
                            All
                          </button>
                          {exam_type.map((item, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handle_drpChange(item.id, item.type)
                              }
                            >
                              {item.type}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className=" space-dropdown pp_dropdown_year"
              style={{ height: "unset", right: "unset" }}
            >
              <div className="d-flex flex-wrap">
                {/* <p className="mb-0">Select Exam type</p> */}
                <button
                  disabled={true}
                  className="pp_dropbtn mt-0"
                  onClick={() => setShowDrop2(!showDrp2)}
                >
                  <div className="d-flex justify-content-between selectYear ">
                    <span className="pastPaper_drpdown"> {DrpData2} </span>
                    <KeyboardArrowDownIcon />
                  </div>
                </button>

                {showDrp2 && (
                  <div className="d-flex justify-content-center mx-3 ">
                    <div
                      className="pp_dropdown-content"
                      style={{ width: "100%", left: "0" }}
                    >
                      {months.length !== 0 && (
                        <>
                          <button onClick={() => handleMonthsChange("All")}>
                            All
                          </button>
                          {months.map((item, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handleMonthsChange(item?.month_name)
                              }
                            >
                              {item?.month_name}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {badgeCollection.length > 0 ? (
            <div className="rewards_cards mt-3">
              {badgeCollection.map((item, index) => (
                <div
                  className="rewards"
                  onClick={() => handleCollectionBadgeClick(item)}
                  key={index}
                >
                  <div
                    className="reward_box mt-3"
                    style={{ background: "#B498FF" }}
                  >
                    <div className="rewards_img">
                      <img src={item?.image} alt="" />
                    </div>
                    {/* <div className="mt-4">{item.badge_name}</div> */}
                    <div className="rewards_img">
                      <div className="mt-4 title-name">
                        {item?.totalId} {item?.badge_name}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no_data_img">
              <div className="noBadgeImg">
                <img src={noBadge} alt="" />
                <p className="textStyle">You don’t have any Badges</p>
                <p className="textStyle">No Badges created by the Admin</p>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {selectedBadge && (
        <Modal
          show={isBadgeModalOpen}
          onHide={handleCloseBadgeModal}
          className="custom-modal custom-small-width"
        >
          <Modal.Body>
            <div className="d-flex justify-content-end align-items-center">
              {/* <div>{selectedBadge.badge_name}</div> */}
              <div className="">
                <img
                  src={purplecloseBtn}
                  alt="Close"
                  onClick={handleCloseBadgeModal}
                />
              </div>
            </div>
            {selectedBadge?.locked == false && (
              <div className="reward-desc">
                Woo! You have one selftest badges unlocked
              </div>
            )}
            {selectedBadge?.locked == false ? (
              <>
                <div className="unlocked-img">
                  <img src={unlock_reward} alt="unlock" />
                </div>
                <div className="rewards_img collection-img">
                  <img src={selectedBadge?.image} alt="badge" />
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div className="reward_box reward_box_background mt-3">
                  <div className="lock_icon">
                    <LockIcon
                      style={{
                        color: "#ff9d00",
                        fontSize: "30px",
                      }}
                    />
                  </div>

                  <div className="rewards_img">
                    <img src={selectedBadge?.image} alt="bagde" />
                  </div>

                  <div className="rewards_img">
                    <div className="mt-4 title-name">
                      {selectedBadge?.badge_name}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="rewards_img">
              <div className="reward-desc mt-4">
                {selectedBadge.description}
              </div>
            </div>
            {selectedBadge?.locked == false && (
              <div className="rewards_img mt-4">
                <button
                  className="addCollBtn"
                  onClick={() => handleAddCollection(selectedBadge?.id)}
                >
                  Add To Collection
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}

      {selectedCollectionBadge && (
        <Modal
          show={isCollectionModalOpen}
          onHide={handleCloseCollectionModal}
          className="custom-modal custom-small-width"
        >
          <Modal.Body>
            <div className="d-flex justify-content-end align-items-center">
              {/* <div>{selectedCollectionBadge.badge_name}</div> */}
              <div className="">
                <img
                  src={purplecloseBtn}
                  alt="Close"
                  onClick={handleCloseCollectionModal}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="reward_box mt-3"
                style={{ background: "#B498FF" }}
              >
                <div className="rewards_img">
                  <img src={selectedCollectionBadge?.image} alt="" />
                </div>
                <div className="rewards_img">
                  <div className="mt-4 title-name">
                    {selectedCollectionBadge.totalId}{" "}
                    {selectedCollectionBadge.badge_name}
                  </div>
                </div>

                {/* Add more badge details here */}
              </div>
            </div>
            <div className="rewards_img">
              <div className="reward-desc mt-4">
                {selectedCollectionBadge.description}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Bagde;
