/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import TopNavBar from "../../TopNavBar/TopNav";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  progressCheck,
  next,
  right,
  course_chptr,
  outerslidebar,
  progressValue,
  no_data,
  back,
} from "../../../Assets/Images";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Progress.css";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useApiContext } from "../../../Context/ApiContext";
import SubjectBar from "../../SubjectBar";

function Progress_Bar() {
  const [active, set_active] = useState("");
  const [initial_index, set_ini_index] = useState(0);
  const [final_index, setFinal_index] = useState(0);
  const [current_show, setCurrent_show] = useState(0);
  const [width_prgrs, set_width_prgrs] = useState([]);
  const [chapterListLength, setChapterListLength] = useState([]);
  const { prgrs_data, apiLoading, all_subject } = useApiContext();

  const navigate = useNavigate();
  useEffect(() => {
    if (all_subject) {
      set_active(all_subject[0]?.id);
    }
  }, [all_subject]);

  useEffect(() => {
    if (prgrs_data?.length !== 0) {
      const filterData = prgrs_data.filter((item) => item.subject_id == active);

      const arr = [];

      for (let i = 0; i < filterData.length; i++) {
        const chapters = filterData[i].chapterList;

        for (let j = 0; j < chapters.length; j++) {
          const chapterPercentage = chapters[j].chapterPercentage;

          if (Math.floor(chapterPercentage) >= 71) {
            let num = 71 - 100;
            arr.push(num + "%");
          } else if (Math.floor(chapterPercentage) >= 48) {
            let num2 = 48 - 100;
            arr.push(num2 + "%");
          } else if (Math.floor(chapterPercentage) >= 42) {
            let num3 = 42 - 100;
            arr.push(num3 + "%");
          } else {
            // const percentageDiff = Math.floor(chapterPercentage) - 100;
            // arr.push(percentageDiff + "%");
            const num1 = Math.floor(chapterPercentage) - 100;
            arr.push(num1 + "%");
          }
        }
      }
      set_width_prgrs(arr);
    }
  }, [active]);

  // useEffect(() => {
  //   get_score_Percent();
  // }, []);
  // useEffect(() => {
  //   if (active !== "") {
  //     setLoading(true);
  //     get_prgrs_data();
  //   }
  // }, [active]);

  // const get_prgrs_data = async () => {
  //   setLoading(true);
  //   const token = localStorage.getItem("token");

  //   try {
  //     const resp = await axios.get(
  //       `${process.env.REACT_APP_NEW_DEVELOPMENT}/app-home-progress-bar`,

  //       {
  //         headers: {
  //           Authorization: "Bearer " + token,
  //           "content-type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     if (resp?.data?.length !== 0) {
  //       const arr = [];

  //       for (let i = 0; i < resp.data.length; i++) {
  //         const chapters = resp.data[i].chapterList;

  //         for (let j = 0; j < chapters.length; j++) {
  //           const chapterPercentage = chapters[j].chapterPercentage;

  //           if (Math.floor(chapterPercentage) == 100) {
  //             let num = 71 - 100;
  //             arr.push(num + "%");
  //           } else if (Math.floor(chapterPercentage) == 66) {
  //             let num2 = 48 - 100;
  //             arr.push(num2 + "%");
  //           } else if (Math.floor(chapterPercentage) == 60) {
  //             let num3 = 42 - 100;
  //             arr.push(num3 + "%");
  //           } else {
  //             // const percentageDiff = Math.floor(chapterPercentage) - 100;
  //             // arr.push(percentageDiff + "%");
  //             const num1 = Math.floor(chapterPercentage) - 100;
  //             arr.push(num1 + "%");
  //           }
  //         }
  //       }
  //       set_width_prgrs(arr);
  //     }
  //     setPrgrs_data(resp?.data);

  //     setLoading(false);
  //   } catch (e) {
  //     if (e.status_code === 500 && e.message === "Unauthenticated") {
  //       clear_local_storage();
  //     } else if (e?.response?.data?.status_code === 401) {
  //       clear_local_storage();
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   }
  // };
  useEffect(() => {
    // if (prgrs_data?.length !== 0) {
    //   const arr = [];

    //   for (let i = 0; i < prgrs_data.length; i++) {
    //     const chapters = prgrs_data[i].chapterList;

    //     for (let j = 0; j < chapters.length; j++) {
    //       const chapterPercentage = chapters[j].chapterPercentage;

    //       if (Math.floor(chapterPercentage) == 100) {
    //         let num = 71 - 100;
    //         arr.push(num + "%");
    //       } else if (Math.floor(chapterPercentage) == 66) {
    //         let num2 = 48 - 100;
    //         arr.push(num2 + "%");
    //       } else if (Math.floor(chapterPercentage) == 60) {
    //         let num3 = 42 - 100;
    //         arr.push(num3 + "%");
    //       } else {
    //         // const percentageDiff = Math.floor(chapterPercentage) - 100;
    //         // arr.push(percentageDiff + "%");
    //         const num1 = Math.floor(chapterPercentage) - 100;
    //         arr.push(num1 + "%");
    //       }
    //     }
    //   }
    //   set_width_prgrs(arr);
    // }
    if (prgrs_data?.length > 0) {
      const chapterListLength = prgrs_data.reduce(
        (total, subject) => total + subject.chapterList.length,
        0
      );
      if (chapterListLength >= 1) {
        set_ini_index(0);
      }
      if (chapterListLength > 3) {
        setFinal_index(2);
      }
    }
  }, [prgrs_data, active]);

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  const getChapterListLength = (subjectId) => {
    const activeSubject = prgrs_data?.find(
      (subject) => subject.subject_id === subjectId
    );
    return activeSubject ? activeSubject.chapterList.length : 0;
  };

  useEffect(() => {
    if (prgrs_data?.length > 0 && active !== "") {
      const activeSubject = prgrs_data.find(
        (subject) => subject.subject_id === active
      );
      const length = activeSubject ? activeSubject.chapterList.length : 0;
      setChapterListLength(length);
    }
  }, [prgrs_data, active]);

  // const activeChapterListLength = getChapterListLength(active);

  const handleForward = () => {
    const activeChapterListLength = chapterListLength;
    if (final_index + 3 <= activeChapterListLength) {
      setFinal_index(final_index + 3);
      set_ini_index(initial_index + 3);
      setCurrent_show(3);
    } else if (final_index + 2 <= activeChapterListLength) {
      setFinal_index(final_index + 2);
      set_ini_index(initial_index + 2);
      setCurrent_show(2);
    } else if (final_index + 1 <= activeChapterListLength) {
      setFinal_index(final_index + 1);
      set_ini_index(initial_index + 1);
      setCurrent_show(1);
    }
  };

  const handleBackward = () => {
    if (initial_index > 2) {
      if (current_show === 3) {
        setFinal_index(final_index - 3);
        set_ini_index(initial_index - 3);
        setCurrent_show(3);
      } else if (current_show === 2) {
        setFinal_index(final_index - 2);
        set_ini_index(initial_index - 2);
        setCurrent_show(2);
      } else if (current_show === 1) {
        setFinal_index(final_index - 1);
        set_ini_index(initial_index - 1);
        setCurrent_show(1);
      }
    } else if (initial_index <= 2) {
      setFinal_index(final_index - 1);
      set_ini_index(initial_index - 1);
    }
  };

  return (
    <div className="comp_container progressContainer comic_font">
      <TopNavBar />
      <SubjectBar
        all_subject={all_subject}
        active={active}
        set_active={set_active}
      />
      <div className="sideDiv">
        <div className="w-100">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          {prgrs_data.length === 0 && (
            <>
              {apiLoading.prgrs_bar && (
                <div className="">
                  <ClipLoader
                    color="black"
                    loading={apiLoading.prgrs_bar}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}
              {!apiLoading.prgrs_bar && (
                <>
                  <div className="no_data_img">
                    <img src={no_data} alt="" />
                  </div>
                </>
              )}
            </>
          )}

          {prgrs_data.length !== 0 && (
            <>
              {prgrs_data.map((subject, index) => (
                <div key={index}>
                  {subject.subject_id === active && (
                    <div className="prgrs_chptr_div ">
                      {subject?.chapterList.map((item, index) => (
                        <div key={index}>
                          {index >= initial_index && index <= final_index && (
                            <div
                              key={index}
                              className="chaptr_one_div  pb-3 pt-3"
                            >
                              <div className="tablet_white_div ps-1">
                                <div className="d-flex progressBar">
                                  <div className="cont_chptr">
                                    <img
                                      src={course_chptr}
                                      alt="img"
                                      className="img_chptr"
                                    />
                                    <div className="chptr">C{index + 1}</div>
                                  </div>

                                  <div className="prg_container mx-2 d-flex">
                                    <img
                                      src={outerslidebar}
                                      alt="img"
                                      className="slider_out"
                                    />
                                    <div className="d-flex justify-content-start mx-2 sliderInside">
                                      <ProgressBar
                                        striped
                                        variant="success"
                                        now={Math.floor(item.chapterPercentage)}
                                      />
                                    </div>
                                    <div
                                      className="cont_chptr"
                                      style={{ marginLeft: width_prgrs[index] }}
                                    >
                                      <img
                                        src={progressValue}
                                        alt="img"
                                        className="prgrs_value"
                                      />

                                      <div
                                        className="prgs_value prgs_value_mobile"
                                        style={{
                                          marginLeft:
                                            item.chapterPercentage == 100
                                              ? "41px"
                                              : "48px",
                                        }}
                                      >
                                        {Math.floor(item.chapterPercentage)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2 lesson_prgs_div progressScroll">
                                  {item.lessonList?.map((item, index) => (
                                    <div key={index}>
                                      <div className="d-flex mt-3">
                                        <div className="cont_done">
                                          <img
                                            src={progressCheck}
                                            alt="img"
                                            className="img_done"
                                          />
                                          {item.is_completed === 1 && (
                                            <div className="done">
                                              <img src={right} alt="img" />
                                            </div>
                                          )}
                                        </div>
                                        <div className="chptr_name">
                                          <span style={{ textAlign: "center" }}>
                                            {item.lesson_number + " "}
                                          </span>
                                          {item.lesson_name.length > 18
                                            ? `${item.lesson_name.slice(
                                                0,
                                                14
                                              )}...${item.lesson_name.slice(
                                                item.lesson_name.length - 2,
                                                item.lesson_name.length
                                              )}`
                                            : item.lesson_name}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}

          {!apiLoading.prgrs_bar && (
            <div className="mt-2 d-flex justify-content-between">
              {initial_index !== 0 && getChapterListLength(active) > 3 && (
                <img
                  src={back}
                  alt="img"
                  style={{
                    boxShadow: "3px 3px 3px #00000029",
                    borderRadius: "10px",
                    width: "37px",
                    height: "35px",
                    marginTop: "3px",
                  }}
                  onClick={handleBackward}
                />
              )}
              <div className="d-flex justify-content-end w-100">
                {final_index + 1 < getChapterListLength(active) && (
                  <img
                    src={next}
                    alt="img"
                    onClick={handleForward}
                    style={{
                      width: "45px",
                      height: "45px",
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Progress_Bar;
