/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "./Lesson.css";
import ClipLoader from "react-spinners/ClipLoader";
import TopNavBar from "../../TopNavBar/TopNav";
import {
  Play_btn,
  num_block,
  empty_heart,
  Ongoing,
  red_heart,
  complete,
  play_blue,
} from "../../../Assets/Images/index";
import { Back_btn } from "../../Style/Styled";
import { useNavigate, useParams } from "react-router";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { no_data } from "../../../Assets/Images/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useApiContext } from "../../../Context/ApiContext";

function Lesson() {
  const { path_id } = useParams();
  const [id, setId] = useState();
  const [sub, setSub] = useState("");
  const [ongoing, setOngoing] = useState([]);
  const [lesson_name, setLesson_name] = useState("");
  const [active, setActive] = useState("lesson");
  const [lesson_Data, setLesson_data] = useState([]);
  const [all_wishlist_chptr, set_wishlist_chapter] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [wish_id_arr, set_wish_id_arr] = useState([]);
  const [chapterImage, setChapterImage] = useState("");
  const [chapterColor, setChapterColor] = useState("");
  const { allLesson, apiLoading, all_subject, userData, getUserProfile } =
    useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    const lessonId = localStorage.getItem("chptr_id");
    const chpterImg = localStorage.getItem("chptr_img");
    setChapterImage(chpterImg);
    const chptercolor = localStorage.getItem("chptr_color");
    setChapterColor(chptercolor);
    const sub = localStorage.getItem("sub");
    const lesson_name = localStorage.getItem("lesson_name");
    if (lessonId !== "") {
      setId(lessonId);
    } else {
      setId(path_id);
    }
    if (lesson_name !== "") {
      setLesson_name(lesson_name);
    }
  }, []);
  useEffect(() => {
    const sub = localStorage.getItem("sub");
    if (sub !== "") {
      const detail = all_subject.find((detail) => detail.id == sub);
      setSub(detail?.name);
    }
  }, [all_subject]);

  const filterLessonsByChapterId = (allLesson, id) => {
    return allLesson.filter((lesson) => lesson.chapter_id == id);
  };

  useEffect(() => {
    setLesson_data(filterLessonsByChapterId(allLesson, id));
  }, [id, allLesson]);
  useEffect(() => {
    const arr = [];
    for (let i = 0; i < userData?.lessonWishlistedList?.length; i++) {
      arr.push(userData?.lessonWishlistedList[i]);
    }
    set_wish_id_arr(arr);
  }, [userData]);

  useEffect(() => {
    if (active === "lesson") {
      setLesson_data(filterLessonsByChapterId(allLesson, id));
    }
    if (active === "ongoing") {
      const ongoingLessonIds = userData?.lessonOnGoingList.map(
        (ongoingLesson) => ongoingLesson?.lesson_id
      );
      const filterOngoingChapters = lesson_Data.filter((lesson) =>
        ongoingLessonIds.includes(lesson.id)
      );
      setOngoing(filterOngoingChapters);
    }
    if (active === "wish") {
      const filterWishList = lesson_Data.filter((lesson) =>
        userData?.lessonWishlistedList.includes(lesson.id)
      );
      set_wishlist_chapter(filterWishList);
    }
    if (active === "completed") {
      const filterCompleted = lesson_Data.filter((lesson) =>
        userData?.lessonCompletedList.includes(lesson.id)
      );
      setCompleted(filterCompleted);
    }
  }, [active, userData]);

  const handle_click = (id) => {
    localStorage.setItem("lesson_id", id);
    navigate(`/details/${id}`);
  };

  const add_to_wishlist = async (l_id) => {
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      bodyData.append("lesson_id", l_id);
      bodyData.append("is_wishlisted", 1);
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/lesson-wishlisted`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        // get_view_wishlist();
        getUserProfile();
      }
    } catch (e) {
      if (e.status_code === 500 && e.message === "Unauthenticated") {
        clear_local_storage();
      } else if (e?.response?.data?.status_code === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const remove_wishlist = async (l_id) => {
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      bodyData.append("lesson_id", l_id);
      bodyData.append("is_wishlisted", 0);
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/lesson-wishlisted`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        getUserProfile();
      }
    } catch (e) {
      if (e.status_code === 500 && e.message === "Unauthenticated") {
        clear_local_storage();
      } else if (e?.response?.data?.status_code === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  return (
    <>
      <div className="comp_container">
        <TopNavBar />
        <div className="d-flex header_prg d-flex justify-content-between p-2 comic_font">
          <div className="d-flex mt-2">
            <div className="sub_name" onClick={() => navigate("/course")}>
              {sub}
            </div>
            <div className="mx-2">
              <ArrowForwardIosIcon className="font-size: 14px;" />
            </div>
            <div className="lesson_name desktop_lesson_name">{lesson_name}</div>
            <div className="lesson_name mobile_lesson_name">
              {lesson_name.length > 10
                ? `${lesson_name.slice(0, 6)}...${lesson_name.slice(
                    lesson_name.length - 2,
                    lesson_name.length
                  )}`
                : lesson_name}
            </div>
          </div>
          <div className="backBtn">
            <Back_btn
              border="1px solid #064974"
              onClick={() => navigate("/course")}
            >
              Back
            </Back_btn>
          </div>
        </div>
        <div className="sideDiv">
          <div className="w-100">
            <div className="lession_li mt-4 mb-4">
              <div
                className={active === "lesson" ? "purple_line" : "tab"}
                onClick={() => setActive("lesson")}
              >
                <img className="lesson_icon" src={Play_btn} alt="" />
                <div
                  className={
                    active === "lesson"
                      ? "lesson_tab_active mx-1"
                      : "lesson_tab mx-1"
                  }
                >
                  All lesson
                </div>
              </div>
              <div
                className={active === "ongoing" ? "purple_line" : "tab"}
                onClick={() => setActive("ongoing")}
              >
                <img className="lesson_icon" src={Ongoing} alt="" />
                <div
                  className={
                    active === "ongoing"
                      ? "lesson_tab_active mx-1"
                      : "lesson_tab mx-1"
                  }
                >
                  Ongoing
                </div>
              </div>
              <div
                className={active === "wish" ? "purple_line" : "tab"}
                onClick={() => {
                  setActive("wish");
                }}
              >
                <img className="lesson_icon" src={red_heart} alt="" />
                <div
                  className={
                    active === "wish"
                      ? "lesson_tab_active mx-1"
                      : "lesson_tab mx-1"
                  }
                >
                  Wish-listed
                </div>
              </div>
              <div
                className={active === "completed" ? "purple_line" : "tab"}
                onClick={() => setActive("completed")}
              >
                <img src={complete} className="lesson_icon" alt="" />
                <div
                  className={
                    active === "completed"
                      ? "lesson_tab_active mx-1"
                      : "lesson_tab mx-1"
                  }
                >
                  Completed
                </div>
              </div>
            </div>

            <div className="lesson_contaier_div">
              {active === "lesson" && (
                <>
                  {apiLoading?.les_loading === true ? (
                    <div className="d-flex justify-content-center w-100">
                      <ClipLoader
                        color="black"
                        loading={apiLoading?.les_loading}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : (
                    <>
                      {lesson_Data?.length !== 0 ? (
                        <div className="lesson_row">
                          {lesson_Data?.map((elem, index) => (
                            <div
                              className="lesson_container"
                              key={index}
                              style={{ backgroundColor: chapterColor }}
                            >
                              <h2 className="lesson_num">
                                {elem?.lesson_number}
                                <span className="mx-2">
                                  {elem?.lesson_name.length > 15
                                    ? `${elem?.lesson_name.slice(
                                        0,
                                        10
                                      )}...${elem?.lesson_name.slice(
                                        elem?.lesson_name.length - 2,
                                        elem?.lesson_name.length
                                      )}`
                                    : elem?.lesson_name}
                                </span>
                              </h2>
                              <div
                                className="lesson_box"
                                onClick={() => handle_click(elem.id)}
                              >
                                <img
                                  src={chapterImage || num_block}
                                  alt=""
                                  className="lesson_img"
                                />
                              </div>
                              <div className="d-flex justify-content-between controls mx-2">
                                <div className="d-flex">
                                  <img
                                    src={play_blue}
                                    alt=""
                                    className="heart_img"
                                    height="27px"
                                    width="27px"
                                  />
                                  {elem?.lesson_duration_minute && (
                                    <div className="time comic_font mx-2 mt-1">
                                      {parseFloat(elem?.lesson_duration_minute)}{" "}
                                      min
                                    </div>
                                  )}
                                </div>
                                {wish_id_arr.includes(elem.id) && (
                                  <img
                                    src={red_heart}
                                    onClick={() => remove_wishlist(elem.id)}
                                    alt=""
                                    className="heart_img mt-2"
                                    height="17px"
                                    width="20px"
                                  />
                                )}
                                {!wish_id_arr.includes(elem.id) && (
                                  <img
                                    src={empty_heart}
                                    onClick={() => add_to_wishlist(elem.id)}
                                    alt=""
                                    className="heart_img mt-2"
                                    height="17px"
                                    width="20px"
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          <div className="no_data_img">
                            <img src={no_data} alt="" />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {active === "wish" && (
                <>
                  {all_wishlist_chptr.length === 0 && (
                    <>
                      <div className="no_data_img">
                        <img src={no_data} alt="" />
                      </div>
                    </>
                  )}
                  {all_wishlist_chptr.length !== 0 && (
                    <div className="lesson_row">
                      <>
                        {all_wishlist_chptr?.map((elem, index) => (
                          <div
                            className="lesson_container"
                            key={index}
                            style={{ backgroundColor: chapterColor }}
                          >
                            <h2 className="lesson_num">
                              {elem?.lesson_number}
                              <span className="mx-2">
                                {elem?.lesson_name.length > 15
                                  ? `${elem?.lesson_name.slice(
                                      0,
                                      10
                                    )}...${elem?.lesson_name.slice(
                                      elem?.lesson_name.length - 2,
                                      elem?.lesson_name.length
                                    )}`
                                  : elem?.lesson_name}
                              </span>
                            </h2>
                            <div className="lesson_box">
                              <img
                                onClick={() => handle_click(elem?.id)}
                                src={chapterImage || num_block}
                                alt=""
                                className="lesson_img"
                              />
                            </div>
                            <div className="d-flex justify-content-between controls mx-2">
                              <div
                                className="d-flex"
                                onClick={() => handle_click(elem?.id)}
                              >
                                <img
                                  src={play_blue}
                                  alt=""
                                  className="heart_img"
                                  height="27px"
                                  width="27px"
                                />

                                {elem?.lesson_duration_minute && (
                                  <div className="time comic_font mx-2 mt-1">
                                    {parseFloat(elem?.lesson_duration_minute)}{" "}
                                    min
                                  </div>
                                )}
                              </div>
                              <img
                                src={red_heart}
                                alt=""
                                className="heart_img"
                                height="21px"
                                width="24px"
                                onClick={() => remove_wishlist(elem?.id)}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  )}
                </>
              )}
              {active === "ongoing" && (
                <>
                  {ongoing.length === 0 && (
                    <>
                      <div className="no_data_img">
                        <img src={no_data} alt="" />
                      </div>
                    </>
                  )}
                  {ongoing.length !== 0 && (
                    <div className="lesson_row">
                      <>
                        {ongoing?.map((elem, index) => (
                          <div
                            className="lesson_container"
                            key={index}
                            style={{ backgroundColor: chapterColor }}
                          >
                            <h2 className="lesson_num">
                              {elem?.lesson_number}
                              <span className="mx-2">
                                {elem?.lesson_name.length > 15
                                  ? `${elem?.lesson_name.slice(
                                      0,
                                      10
                                    )}...${elem?.lesson_name.slice(
                                      elem?.lesson_name.length - 2,
                                      elem?.lesson_name.length
                                    )}`
                                  : elem?.lesson_name}
                              </span>
                            </h2>
                            <div className="lesson_box">
                              <img
                                onClick={() => handle_click(elem?.id)}
                                src={chapterImage || num_block}
                                alt=""
                                className="lesson_img"
                              />
                            </div>
                            <div className="d-flex justify-content-between controls mx-2">
                              <div
                                className="d-flex"
                                onClick={() =>
                                  navigate(`/details/:${elem?.id}`)
                                }
                              >
                                <img
                                  src={play_blue}
                                  alt=""
                                  className="heart_img"
                                  height="27px"
                                  width="27px"
                                />

                                {elem?.lesson_duration_minute && (
                                  <div className="time comic_font mx-2 mt-1">
                                    {parseFloat(elem?.lesson_duration_minute)}{" "}
                                    min
                                  </div>
                                )}
                              </div>
                              {wish_id_arr.includes(elem?.id) && (
                                <img
                                  src={red_heart}
                                  onClick={() => remove_wishlist(elem?.id)}
                                  alt=""
                                  className="heart_img mt-2"
                                  height="17px"
                                  width="20px"
                                />
                              )}
                              {!wish_id_arr.includes(elem?.id) && (
                                <img
                                  src={empty_heart}
                                  onClick={() => add_to_wishlist(elem?.id)}
                                  alt=""
                                  className="heart_img mt-2"
                                  height="17px"
                                  width="20px"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  )}
                </>
              )}
              {active === "completed" && (
                <>
                  {completed?.length === 0 && (
                    <>
                      <div className="no_data_img">
                        <img src={no_data} alt="" />
                      </div>
                    </>
                  )}
                  {completed?.length !== 0 && (
                    <div className="lesson_row">
                      <>
                        {completed?.map((elem, index) => (
                          <div
                            className="lesson_container"
                            key={index}
                            style={{ backgroundColor: chapterColor }}
                          >
                            <h2 className="lesson_num">
                              {elem?.lesson_number}
                              <span className="mx-2">
                                {elem?.lesson_name.length > 15
                                  ? `${elem?.lesson_name.slice(
                                      0,
                                      10
                                    )}...${elem?.lesson_name.slice(
                                      elem?.lesson_name.length - 2,
                                      elem?.lesson_name.length
                                    )}`
                                  : elem?.lesson_name}
                              </span>
                            </h2>
                            <div className="lesson_box">
                              <img
                                onClick={() => handle_click(elem?.id)}
                                src={chapterImage || num_block}
                                alt=""
                                className="lesson_img"
                              />
                            </div>
                            <div className="d-flex justify-content-between controls mx-2 cursor-pointer">
                              <div
                                className="d-flex cursor-pointer"
                                onClick={() =>
                                  navigate(`/details/:${elem?.id}`)
                                }
                              >
                                <img
                                  src={play_blue}
                                  alt=""
                                  className="heart_img cursor-pointer"
                                  height="27px"
                                  width="27px"
                                />

                                {elem?.lesson_duration_minute && (
                                  <div className="time comic_font mx-2 mt-1 cursor-pointer">
                                    {parseFloat(elem?.lesson_duration_minute)}{" "}
                                    min
                                  </div>
                                )}
                              </div>
                              {wish_id_arr.includes(elem?.id) && (
                                <img
                                  src={red_heart}
                                  onClick={() => remove_wishlist(elem?.id)}
                                  alt=""
                                  className="heart_img mt-2 cursor-pointer"
                                  height="17px"
                                  width="20px"
                                />
                              )}
                              {!wish_id_arr.includes(elem?.id) && (
                                <img
                                  src={empty_heart}
                                  onClick={() => add_to_wishlist(elem?.id)}
                                  alt=""
                                  className="heart_img mt-2 cursor-pointer"
                                  height="17px"
                                  width="20px"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Lesson;
