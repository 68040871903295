import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { IoIosNotifications } from "react-icons/io";
import { close, person, reward } from "../../Assets/Images/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Card from "react-bootstrap/Card";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./Topnav.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SideNav from "../SideNavBar/SideNav";
import MenuIcon from "@mui/icons-material/Menu";
import SuccessModal from "../SuccessModal";

function TopNavBar() {
  const [show, setShow] = useState(false);
  const [show_sideNav, setShow_sideNav] = useState(true);
  const [show_notification, setShow_notification] = useState(false);
  const [check, setCheck] = useState();
  const [Data_noti, setData_noti] = useState([]);
  const [Token, setToken] = useState("");
  const [open, setOpen] = useState(false);
  const [note_count, setNote_count] = useState(0);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  useEffect(() => {
    const Token = localStorage.getItem("token");
    const user = localStorage.getItem("User");
    const check_rem = localStorage.getItem("remember");
    setUserData(JSON.parse(user));
    setToken(Token);
    setCheck(check_rem);
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767) {
        setShow_sideNav(true);
      } else {
        setShow_sideNav(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (Token) {
      // get_all_note();
    }
  }, [Token]);

  const getGreetings = async (e) => {
    // e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/logout`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      if (resp.status === 200) {
        if (check === "true") {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("pass");
          localStorage.removeItem("name");
          localStorage.removeItem("remember");
        }

        navigate("/");
        setShow(false);

        // window.location.reload();
      }
    } catch (e) {
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };

  const handleToClose = () => {
    setOpen(false);
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  // const get_all_note = async () => {
  //   setLoading(true);

  //   try {
  //     const bodyData = new FormData();
  //     bodyData.append("target", "primary_student");
  //     const resp = await axios.post(
  //       `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewNotification`,

  //       bodyData,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + Token,
  //           "content-type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     if (resp) {
  //       if (resp.data.length !== 0) {
  //         var count = 0;
  //         for (let i = 0; i < resp.data.length; i++) {
  //           if (resp.data[i].read === "false") {
  //             count++;
  //           }
  //         }
  //       }
  //       setNote_count(Number(count));
  //       setData_noti(resp.data);
  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     if (
  //       e?.response?.data?.status_code === 401 ||
  //       e?.response?.data?.status_code === 500
  //     ) {
  //       clear_local_storage();
  //     }
  //   }
  // };

  const handle_notification_click = () => {
    setShow_notification(!show_notification);
    setOpen(false);
  };

  const handle_profile_click = () => {
    setOpen(!open);
    setShow_notification(false);
  };
  const handleNotificationClose = () => {
    setShow_notification(false);
  };
  return (
    <>
      <div className="topNav comic_font">
        <Navbar bg="#8b60fd" expand="lg">
          <Nav
            className="d-flex my-2 my-lg-0 justify-content-end w-100 nav_div"
            style={{ maxHeight: "50px" }}
            navbarScroll
          >
            <div className="topNav_toggle">
              <div className="d-flex justify-content-start mx-2 ">
                <Nav.Link className="">
                  <div
                    className="container menuIcon mt-4"
                    onClick={() => setShow_sideNav(!show_sideNav)}
                  >
                    <MenuIcon style={{ fontSize: "30px", color: "white" }} />
                  </div>
                </Nav.Link>
                {show_sideNav && (
                  <Nav.Link className="">
                    <div
                      className="container menuIcon mt-2 mt-4"
                      onClick={() => setShow_sideNav(false)}
                    >
                      <MenuIcon style={{ fontSize: "30px", color: "white" }} />
                    </div>
                  </Nav.Link>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end mx-2 navLink">
              <Nav.Link className="notification_link">
                <div>
                  <div
                    // className={
                    //   !show_notification
                    //     ? "container notification_icon_link mt-2"
                    //     : "container_open notification_icon_link mt-2"
                    // }
                    className="container notification_icon_link mt-2"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={() => handle_notification_click()}
                  >
                    <IoIosNotifications
                      style={{ fontSize: "30px", color: "white" }}
                    />
                    {note_count > 0 && (
                      <div
                        // className={!show_notification ? "redDot" : "redDot_open"}
                        className="redDot"
                      >
                        <h6 style={{ fontSize: 10, marginTop: 4 }}>
                          {" "}
                          {note_count}
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
                {show_notification && (
                  <div className="icon_dorp_down" style={{ width: "350px" }}>
                    <div className="close_btn_notification mt-2">
                      <img
                        src={close}
                        alt="img"
                        height={35}
                        width={35}
                        onClick={() => handleNotificationClose()}
                      />
                    </div>

                    {Data_noti?.length === 0 && (
                      <div className="no_note mt-2">
                        No new notification available
                      </div>
                    )}
                    {Data_noti.length !== 0 && (
                      <div className="dropDown_content">
                        {Data_noti.map((item, index) => (
                          <div key={index}>
                            {index <= 1 && (
                              <div
                                className={
                                  item.read === "true"
                                    ? "notification_drop_div d-flex justify-content-between"
                                    : "notification_drop_read_div d-flex justify-content-between"
                                }
                              >
                                <div className="image-card">
                                  <div className="image-container">
                                    <img src={item.image} alt="Card" />
                                  </div>
                                  <div className="text-container">
                                    <div>{item.title}</div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {Data_noti.length !== 0 && (
                      <div className="d-flex justify-content-end note_view_all">
                        <div
                          onClick={() => navigate("/notifications")}
                          className="view_all_noti"
                        >
                          View all
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Nav.Link>

              <Nav.Link className="reward_div">
                <img
                  src={reward}
                  alt="img"
                  height={30}
                  width={30}
                  className="mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/rewards")}
                />
              </Nav.Link>

              <Nav.Link>
                <div className="d-flex profile_nav_link">
                  <img
                    // src={userData?.image || person}
                    src={person}
                    alt="img"
                    className="profile"
                  />

                  <div className="d-flex profile_link">
                    <div className="dropDown_main">
                      <div
                        className="drp_down comic_font"
                        style={{
                          color: "white",
                          textAlign: "start",
                          height: "20px",
                        }}
                        onClick={() => handle_profile_click()}
                      >
                        Hello, <b>{userData?.first_name}</b>
                      </div>
                      <div className="student comic_font">Student</div>
                    </div>
                    <KeyboardArrowDownIcon
                      onClick={() => handle_profile_click()}
                      className="downArrow"
                    />
                  </div>
                </div>
                <div className="card_desktop">
                  {open && (
                    <Card className="profile_card">
                      <Card.Body>
                        <div className="profilecard_top_div">
                          <div className="close_btn">
                            <img
                              src={close}
                              alt="img"
                              height={35}
                              width={35}
                              onClick={() => handleToClose()}
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            <img
                              src={person}
                              alt="img"
                              className="profileDropDown"
                              height={80}
                              width={80}
                            />
                          </div>

                          <div
                            style={{
                              fontWeight: "600",

                              textAlign: "center",
                              color: "black",
                              marginLeft: "",
                            }}
                            className="mt-2"
                          >
                            {userData?.full_name}
                          </div>
                          <div
                            className="mt-1"
                            style={{
                              color: "#8B60FD",

                              textAlign: "center",
                            }}
                            onClick={() => navigate("/user")}
                          >
                            <VisibilityIcon /> View Profile
                          </div>
                        </div>
                        <hr style={{ color: "#989898" }} />
                        {/* <hr style={{ color: "black" }} /> */}
                        <div
                          className="profilecard_bottom_div"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <div className="d-flex justify-content-center mt-2">
                            <button
                              className="signOut_btn "
                              onClick={handleShow}
                            >
                              Sign Out
                            </button>
                          </div>
                          <div className="forWardArrow">
                            <ArrowForwardIcon />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              </Nav.Link>
            </div>
          </Nav>
        </Navbar>
      </div>
      <div className="card_show">
        {open && (
          <Card className="profile_card2">
            <Card.Body>
              <div className="profilecard_top_div">
                <div className="close_btn">
                  <img
                    src={close}
                    alt="img"
                    height={35}
                    width={35}
                    onClick={() => handleToClose()}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    src={person}
                    alt="img"
                    className="profileDropDown"
                    height={80}
                    width={80}
                  />
                </div>

                <div
                  style={{
                    fontWeight: "600",

                    textAlign: "center",
                    color: "black",
                    marginLeft: "",
                  }}
                  className="mt-2"
                >
                  {userData?.full_name}
                </div>
                <div
                  className="mt-1"
                  style={{
                    color: "#8B60FD",

                    textAlign: "center",
                  }}
                  onClick={() => navigate("/user")}
                >
                  <VisibilityIcon /> View Profile
                </div>
              </div>
              <hr style={{ color: "#989898" }} />
              <div
                className="profilecard_bottom_div"
                style={{
                  textAlign: "center",
                }}
              >
                <div className="d-flex justify-content-center mt-2">
                  <button className="signOut_btn " onClick={handleShow}>
                    Sign Out
                  </button>
                </div>
                <div className="forWardArrow">
                  <ArrowForwardIcon />
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
      {show_notification && (
        <div className="icon_dorp_down_mobile">
          <div className="close_btn mt-2">
            <img
              src={close}
              alt="img"
              height={35}
              width={35}
              onClick={() => setShow_notification(!show_notification)}
            />
          </div>
          {Data_noti?.length === 0 && (
            <div className="no_note  mt-2">No new notification available</div>
          )}
          {Data_noti.length !== 0 && (
            <div
              className="dropDown_content"
              style={{ overflowY: Data_noti?.length > 2 ? "scroll" : "none" }}
            >
              {Data_noti.map((item, index) => (
                <div key={index}>
                  {index <= 1 && (
                    <div
                      className={
                        item.read === "true"
                          ? "notification_drop_div d-flex justify-content-between"
                          : "notification_drop_read_div d-flex justify-content-between"
                      }
                    >
                      <div className="d-flex">
                        <img
                          src={item.image}
                          alt=""
                          className="topnav_profile_img"
                        />
                        <div className="title_Noti">{item.title}</div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {Data_noti.length !== 0 && (
            <div className="d-flex justify-content-end">
              <div
                onClick={() => navigate("/notifications")}
                className="view_all_noti"
              >
                View all
              </div>
            </div>
          )}
        </div>
      )}
      <div className="container_div comic_font">
        <SideNav nav_show={show_sideNav} />
      </div>
      <SuccessModal
        title={" Are you sure? You want to log out?"}
        show={show}
        onHide={handleClose}
        myCallback={(param) => {
          if (param == true) {
            getGreetings(param);
          } else if (param == false) {
            setShow(param);
          }
        }}
      />
    </>
  );
}

export default TopNavBar;
