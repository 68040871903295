import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Create a context
const ApiContext = createContext();

// Create a custom hook to use the context
export const useApiContext = () => useContext(ApiContext);

// Create a provider component
export const ApiProvider = ({ children }) => {
  const [apiLoading, setApiLoading] = useState({
    notice: true,
    avg_percent: true,
    avatar: true,
    prgrs_bar: true,
    calander: true,
    subject: true,
    chapter: true,
    les_loading: true,
    selftest_load: true,
    curriculum_load: true,
    revision_load: true,
    notes_load: true,
    months_load: true,
    years_load: true,
  });
  const [loading, setLoading] = useState(true);
  const [allAvatar, setAvatar] = useState([]);
  const [examBoard, setExamBoard] = useState([]);
  const [keyStage, setKeyStage] = useState([]);
  const [district, setDistrict] = useState([]);
  const [country, setCountry] = useState([]);
  const [school, setSchool] = useState([]);
  const [year, setYear] = useState([]);
  const [userData, setUserData] = useState([]);
  const [notice, setNotice] = useState([]);
  const [exam_type, setExamType] = useState([]);
  const [Average, setAverage] = useState([]);
  const [prgrs_data, set_prgrs_data] = useState([]);
  const [date_arr, setDate_arr] = useState([]);
  const [all_subject, setAll_subject] = useState([]);
  const [all_chapter, setAll_chapter] = useState([]);
  const [allLesson, setAll_lesson] = useState([]);
  const [selfTestQuestions, setSelfTestQuestions] = useState([]);
  const [curriculumCourse, setCurriculumCourse] = useState([]);
  const [revision, setRevision] = useState([]);
  const [notes, setNotes] = useState([]);
  const [months, setMonths] = useState([]);
  const [year_data, setYear_data] = useState([]);

  const navigate = useNavigate();

  const clear_local_storage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    navigate("/");
  };
  //   GET AVTHAR
  const getAllAvatar = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/avathar-image-list`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setAvatar(resp?.data);
        setApiLoading((loading) => ({
          ...loading,
          avatar: false,
        }));
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // Get Exam Board
  const getExamBoard = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/get-exam-board`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setExamBoard(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      if (e.status_code === 500 && e.message === "Unauthenticated") {
        clear_local_storage();
      } else if (e?.response?.data?.status_code === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // Get key stage
  const getKeyStage = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/get-key-stage`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setKeyStage(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // Get get-district
  const getDistrict = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/get-district`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setDistrict(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // Get get-country
  const getCountry = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/get-country`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setCountry(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // Get get-school
  const getSchool = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/get-school`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setSchool(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // Get get-year/class
  const getYear = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/get-year`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setYear(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // Get user profile

  const getUserProfile = async () => {
    const token = localStorage.getItem("token");
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/profile`,

        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        // formatDate(resp?.data?.data?.student_detail.dob);
        setUserData(resp.data);
        setLoading(false);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      }
    }
  };

  // get qoute

  const getNotice = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/quote`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      setNotice(resp?.data);
      setApiLoading((loading) => ({
        ...loading,
        notice: false,
      }));
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // Get exam type

  const getExamType = async () => {
    const token = localStorage.getItem("token");
    setLoading((loading) => ({
      ...loading,
      calander: true,
    }));
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/exam-type`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      setExamType(resp?.data);
      setApiLoading((loading) => ({
        ...loading,
        calander: false,
      }));
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // get score data

  const getAvrgPercent = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/app-home-score`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      if (resp?.data) {
        setAverage(resp?.data.over_all_point_percentage);
      }

      setApiLoading((loading) => ({
        ...loading,
        avg_percent: false,
      }));
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // Get Progress bar
  const get_score_Percent = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/app-home-progress-bar`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      if (resp?.data) {
        set_prgrs_data(resp?.data);
        setApiLoading((loading) => ({
          ...loading,
          prgrs_bar: false,
        }));
      }
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // get event

  const getEvent = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/calendar-event`,

        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      const arr = [];
      for (let i = 0; i < resp?.data.length; i++) {
        var date = new Date(resp?.data[i].start_date);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        arr.push({
          day: day,
          month: month,
          year: year,
          id: i,
          name: resp?.data[i].title,
        });
      }
      setDate_arr(arr);
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // get subject

  const callSubject = async () => {
    const token = localStorage.getItem("token");
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/subject`,

        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      setAll_subject(resp?.data);
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      }
    }
    setApiLoading((loading) => ({
      ...loading,
      subject: false,
    }));
  };

  // get all chapter

  const get_all_chapters = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/chapter`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      setAll_chapter(resp?.data);
    } catch (e) {
      if (
        e?.response?.status === 500 &&
        e?.response?.data?.message === "Unauthenticated"
      ) {
        clear_local_storage();
      } else if (e?.response?.status === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setApiLoading((loading) => ({
        ...loading,
        chapter: false,
      }));
    }
  };

  // Get all lesson
  const get_all_lesson = async () => {
    const token = localStorage.getItem("token");
    setApiLoading((loading) => ({
      ...loading,
      les_loading: true,
    }));

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/lesson`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setAll_lesson(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setApiLoading((loading) => ({
        ...loading,
        les_loading: false,
      }));
    }
  };
  // Get all selftest question
  const get_self_test = async () => {
    const token = localStorage.getItem("token");
    setApiLoading((loading) => ({
      ...loading,
      les_loading: true,
    }));

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/selftest-question`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setSelfTestQuestions(resp?.data);
        setApiLoading((loading) => ({
          ...loading,
          selftest_load: false,
        }));
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // Get Curriculum
  const getcurriculum = async () => {
    const token = localStorage.getItem("token");
    setApiLoading((loading) => ({
      ...loading,
      curriculum_load: true,
    }));

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/curriculum`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setCurriculumCourse(resp?.data);
        setApiLoading((loading) => ({
          ...loading,
          curriculum_load: false,
        }));
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // Get Revision
  const getRevision = async () => {
    const token = localStorage.getItem("token");
    setApiLoading((loading) => ({
      ...loading,
      revision_load: true,
    }));

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/revision`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setRevision(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setApiLoading((loading) => ({
        ...loading,
        revision_load: false,
      }));
    }
  };

  // Get Rivision notes
  const getNotes = async () => {
    const token = localStorage.getItem("token");
    setApiLoading((loading) => ({
      ...loading,
      notes_load: true,
    }));

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/note`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setNotes(resp?.data);
        setApiLoading((loading) => ({
          ...loading,
          notes_load: false,
        }));
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  // Get Months

  const getMonth = async () => {
    const token = localStorage.getItem("token");
    setApiLoading((loading) => ({
      ...loading,
      months_load: true,
    }));

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/month-list`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setMonths(resp?.data);
        setApiLoading((loading) => ({
          ...loading,
          months_load: false,
        }));
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // Get pastpaper/challenge Years

  const getPastpaperYear = async () => {
    const token = localStorage.getItem("token");
    setApiLoading((loading) => ({
      ...loading,
      years_load: true,
    }));

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/past-paper-years`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setYear_data(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setApiLoading((loading) => ({
        ...loading,
        years_load: false,
      }));
    }
  };

  useEffect(() => {
    getAllAvatar();
    getExamBoard();
    getKeyStage();
    getDistrict();
    getCountry();
    getSchool();
    getYear();
    getUserProfile();
    getNotice();
    getExamType();
    getAvrgPercent();
    get_score_Percent();
    getEvent();
    callSubject();
    get_all_chapters();
    get_all_lesson();
    get_self_test();
    getcurriculum();
    getRevision();
    getNotes();
    getMonth();
    getPastpaperYear();
  }, []);

  const value = {
    apiLoading,
    loading,
    allAvatar,
    getAllAvatar,
    examBoard,
    keyStage,
    district,
    country,
    school,
    year,
    userData,
    notice,
    exam_type,
    Average,
    prgrs_data,
    date_arr,
    getUserProfile,
    all_subject,
    get_score_Percent,
    all_chapter,
    allLesson,
    get_all_lesson,
    selfTestQuestions,
    get_all_chapters,
    curriculumCourse,
    revision,
    getRevision,
    notes,
    getNotes,
    months,
    year_data,
  };
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
