import React, { useEffect, useState } from "react";
import { close, download } from "../../../Assets/Images";
// import { PDFReader } from "reactjs-pdf-reader";
import { useNavigate } from "react-router-dom";
import "./Marking.css";
import MyPDFViewer from "../../PdfScreen/myPdfView";

function Marking_scheme() {
  const [data, setData] = useState();
  const [toggle, setAToggle] = useState();
  const [pdf, setPdf] = useState();
  const navigate = useNavigate();
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   'pdfjs-dist/legacy/build/pdf.worker.min.js',
  //   import.meta.url,
  // ).toString();

  // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("ms_data"));
    if (obj) {
      setData(obj);
      // convertToBase64(obj.ms_file);
      setPdf(obj.ms_file);
    }
    const toggle = localStorage.getItem("pp_toggle");
    if (toggle) {
      setAToggle(toggle);
    }
  }, []);

  const handleDownload = (pdf) => {
    fetch(pdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");

        alink.href = fileURL;
        alink.download = pdf?.split("/")?.pop();
        alink.click();
      });
    });
  };

  return (
    <div className="comic_font">
      <div className="scheme_nav">
        <div className="nav_data math_scheme">
          {data?.chapter?.subject?.name}
        </div>
        {toggle === "true" && (
          <div className="nav_data year_scheme">
            {data?.chapter.exam_board.name}
          </div>
        )}
        {toggle === "false" && (
          <div className="nav_data year_scheme">{data?.year}</div>
        )}

        <div className="nav_data">Marking Scheme</div>
        <div className="nav_data download_sign">
          <img
            src={download}
            alt="img"
            height="35px"
            width="35px"
            onClick={() => handleDownload(data?.ms_file)}
          />
          {/* <FileDownloadIcon style={{ fontSize: "30px" }} /> */}
        </div>
        <div className="nav_data close_btn_mark">
          <img src={close} alt="" onClick={() => navigate("/pastpaper")} />
        </div>
      </div>

      <div className="scheme_content">
        <div className="main">
          {/* <MyPDFViewer scale={0.4} pdfUrl={pdf} page={pageNumber} myCallback={(page)=>{setNumPages(page?._pdfInfo?.numPages
)}}
          /> */}
          {/* <iframe  src={pdf} className="pdfIframe" height="550" width="1000" frameborder="0"></iframe> */}
          <div className="iframe-container">
            <iframe
              width="560"
              height="315"
              src={pdf}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              loading="lazy"
              allowFullScreen
            ></iframe>
          </div>
          {/* {pdf && (
            <div>
              <div className="d-flex text-center justify-content-center ">
                <div className="w-100">
                  <div className="pagec mb-3">
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </div>

                  <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    className="Pre_btn mt-3 mt-md-3 me-1 new-next-btn"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    className="Pre_btn  mt-3 mb-5 mb-md-5 mt-md-3 new-next-btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )} */}
        </div>

        {/* <PDFReader showAllPage="true" url={d_pdf} scale={scale} /> */}
      </div>
    </div>
  );
}

export default Marking_scheme;
