import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./styles.css";
import { GlobalWorkerOptions } from "pdfjs-dist";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

const MyPDFViewer = ({ pdfUrl, page, scale, myCallback }) => {
  return (
    <div className="react-pdf__Page__canvas col-12">
      <Document
        className="react-pdf__Page__canvas"
        file={pdfUrl}
        onLoadSuccess={(pageTotal) => myCallback(pageTotal)}
      >
        <Page
          pageNumber={page}
          scale="1"
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  );
};

const handleLoadSuccess = ({ numPages }) => {};

export default MyPDFViewer;
