import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./ActivitySettingModal.css";
import { close } from "../../../Assets/Images";
import { useApiContext } from "../../../Context/ApiContext";

const ActivitySettingModal = ({ show, handleClose }) => {
  const { userData } = useApiContext();
  const [setting, setSetting] = useState();
  const [activityType, setActivityType] = useState("normal");

  const handleSave = () => {
    localStorage.setItem("activitySetting", setting);
    handleClose();
  };
  const handleActivityTime = (activityTime, name) => {
    setSetting(activityTime);
    setActivityType(name);
  };

  return (
    <Modal show={show} onHide={handleClose} className="activitySettingModal">
      <Modal.Header>
        <div className="upcoming_head_activity" style={{ color: "#fff" }}>
          Activity setting
        </div>
        <div className="">
          <img
            src={close}
            alt="Close"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          className="setting-option"
          onClick={() =>
            handleActivityTime(userData?.activityTimeSetting?.fast, "fast")
          }
        >
          Timer will set {userData?.activityTimeSetting?.fast} min less than the
          actual time{" "}
          <span className={activityType === "fast" ? "selected" : ""}>
            Fast
          </span>
        </div>
        <div
          className="setting-option"
          onClick={() =>
            handleActivityTime(userData?.activityTimeSetting?.normal, "normal")
          }
        >
          Get the actual time
          <span className={activityType === "normal" ? "selected" : ""}>
            Normal
          </span>
        </div>
        <div
          className="setting-option"
          onClick={() =>
            handleActivityTime(userData?.activityTimeSetting?.slow, "slow")
          }
        >
          You will get an extra {userData?.activityTimeSetting?.slow} minutes of
          the actual time{" "}
          <span className={activityType === "slow" ? "selected" : ""}>
            Slow
          </span>
        </div>
      </Modal.Body>

      <div className="activity-apply-btn">
        <Button variant="primary" onClick={handleSave}>
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default ActivitySettingModal;
