import React, { useEffect, useState } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import TopNavBar from "../TopNavBar/TopNav";
import axios from "axios";
import "./Notification.css";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { faq, no_data } from "../../Assets/Images";
import { ToastContainer, toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-toastify/dist/ReactToastify.css";
import "./Toggle.css";
import Toggle from "react-styled-toggle";
import { flushSync } from "react-dom";
import "./Notification_sidebar.css";

function Notifications() {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [Data_noti, setData_noti] = useState([]);
  const [setting_data, set_setting_data] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [settig_obj, setSetting_obj] = useStateWithCallbackLazy({
    admin_email_notification: "",
    admin_push_notification: "",
    admin_sms_notification: "",
    payment_email_notification: "",
    payment_push_notification: "",
    payment_sms_notification: "",
  });
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  useEffect(() => {
    const user = localStorage.getItem("User");
    setUserData(JSON.parse(user));
    // get_all_note();
    // get_note_setting();
  }, []);

  useEffect(() => {
    if (setting_data !== {}) {
      setSetting_obj({
        payment_email_notification:
          setting_data.payment_email_notification === "true" ? true : false,
        payment_push_notification:
          setting_data.payment_push_notification === "true" ? true : false,
        payment_sms_notification:
          setting_data.payment_sms_notification === "true" ? true : false,
        admin_email_notification:
          setting_data.admin_email_notification === "true" ? true : false,
        admin_push_notification:
          setting_data.admin_push_notification === "true" ? true : false,
        admin_sms_notification:
          setting_data.admin_sms_notification === "true" ? true : false,
      });
    }
  }, [setting_data]);
  const navigate = useNavigate();

  const get_all_note = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      bodyData.append("target", "primary_student");
      const resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewNotification`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setData_noti(resp.data);
        setLoading(false);
      }
    } catch (e) {
      // toast.error(e?.response?.data?.message);
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };

  const get_note_setting = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      const resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewNotificationSetting`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        set_setting_data(resp?.data);
        setLoading(false);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };

  const update_note_setting = async () => {
    setLoading(false);
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      bodyData.append(
        "payment_push_notification",
        settig_obj.payment_push_notification.toString()
      );
      bodyData.append(
        "payment_email_notification",
        settig_obj.payment_email_notification.toString()
      );
      bodyData.append(
        "payment_sms_notification",
        settig_obj.payment_sms_notification.toString()
      );
      bodyData.append(
        "admin_push_notification",
        settig_obj.admin_push_notification.toString()
      );
      bodyData.append(
        "admin_email_notification",
        settig_obj.admin_email_notification.toString()
      );
      bodyData.append(
        "admin_sms_notification",
        settig_obj.admin_sms_notification.toString()
      );
      const resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/updateNotificationSetting`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        setIsUpdate(false);
        // get_note_setting();
        setLoading(false);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  // const handle_change_setting = (data, e) => {
  //   setSetting_obj({
  //     ...settig_obj,
  //     data: e.target.checked,
  //   });
  // };

  useEffect(() => {
    if (isUpdate === true) {
      update_note_setting();
    }
  }, [isUpdate]);

  return (
    <>
      <div
        className="comp_container comic_font"
        style={{ height: "100%", minHeight: "100%" }}
      >
        <TopNavBar />

        <div className="sideDiv">
          <div className="w-100" style={{ height: "100vh" }}>
            <div className="toggle_div">
              <div className="form-inline ml-auto d-flex justify-content-end">
                <div className="toggle_btn" onClick={ToggleSidebar}>
                  Notification Setting
                </div>
              </div>
            </div>
            <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
              <div className="sd-header">
                <div className="notification_set_header mb-1">
                  Notification Setting
                </div>
                {isOpen && (
                  <div className="toggle_btn" onClick={ToggleSidebar}>
                    <CancelIcon style={{ color: "#8b60fd" }} />
                  </div>
                )}
              </div>
              <div className="sd-body">
                {!loading && (
                  <>
                    {setData_noti && (
                      <>
                        <div className="mt-2 p-3">
                          <div className="setting_head">
                            Payment notification
                          </div>

                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={9}
                              sliderHeight={9}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  payment_push_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                              }}
                              checked={settig_obj.payment_push_notification}
                            />
                            <div className="mx-2"> Push notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={9}
                              sliderHeight={9}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  payment_email_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                              }}
                              checked={settig_obj.payment_email_notification}
                            />
                            <div className="mx-2">Email notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={9}
                              sliderHeight={9}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  payment_sms_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                              }}
                              checked={settig_obj.payment_sms_notification}
                            />
                            <div className="mx-2">Text notifications</div>
                          </div>
                        </div>
                        <div className="px-3 mt-2">
                          <div className="setting_head">
                            Admin notifications
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={9}
                              sliderHeight={9}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  admin_push_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                              }}
                              checked={settig_obj?.admin_push_notification}
                            />
                            <div className="mx-2">Push notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={9}
                              sliderHeight={9}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  admin_email_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                              }}
                              checked={settig_obj.admin_email_notification}
                            />
                            <div className="mx-2">Email notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={9}
                              sliderHeight={9}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  admin_sms_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                              }}
                              checked={settig_obj.admin_sms_notification}
                            />
                            <div className="mx-2">Text notifications</div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
              onClick={ToggleSidebar}
            ></div>
            <div className="container_notification">
              <div className="Notification_left_container">
                <div className="notification_header mb-1">Notification</div>
                {Data_noti.length === 0 && (
                  <>
                    {loading && (
                      <div className="">
                        <ClipLoader
                          color="black"
                          loading={loading}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                    {!loading && (
                      <>
                        <div className="no_data_img">
                          <img src={no_data} alt="" className="img-fluid" />
                        </div>
                      </>
                    )}
                  </>
                )}

                {Data_noti.length !== 0 && (
                  <>
                    {/* {Data_noti.map((item, index) => (
                      <div
                        key={index}
                        className={
                          item.read === "true"
                            ? "notification_div d-flex justify-content-between"
                            : "notification_read_div d-flex justify-content-between"
                        }
                      >
                        <div className="d-flex">
                          <img
                            src={item.image}
                            alt=""
                            className="profile_img"
                          />
                          <div className="title_Noti">{item.title}</div>
                        </div>
                        <div className="month_ago">{item.created_at}</div>
                      </div>
                    ))} */}
                    {Data_noti.map((item, index) => (
                      <div key={index}>
                        {index <= 1 && (
                          <div
                            className={
                              item.read === "true"
                                ? "notification_drop_div d-flex justify-content-between"
                                : "notification_drop_read_div d-flex justify-content-between"
                            }
                          >
                            <div className="d-flex align-items-center col-12 col-lg-12 col-md-12 col-sm-12 d-flex">
                              <img
                                src={item.image}
                                alt=""
                                className="topnav_profile_img col-lg-1 col-md-1 col-sm-1 img-fluid"
                              />
                              <div className="col-lg-9 col-md-9 col-sm-9 w-75 title_Noti text-align-justify ">
                                {item.title}
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 month_ago ms-1 notification-count">
                                {item.created_at}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="Notification_right_container">
                <div className="notification_set_header mb-1">
                  Notification Setting
                </div>
                {loading && (
                  <div className="">
                    <ClipLoader
                      color="black"
                      loading={loading}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                )}
                {!loading && (
                  <>
                    {setData_noti && (
                      <>
                        <div className="mt-2 p-3">
                          <div className="setting_head">
                            Payment notifications
                          </div>
                          {/* <div className="d-flex row">
                            <div className="col-2 mt-1">
                              <input type="checkbox" id="toggle" class="toggle-input" />
                              <label for="toggle" class="toggle-label"></label>
                            </div>
                            <div className="col-10"> Push notifications</div>
                          </div> */}
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={10}
                              sliderHeight={10}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  payment_push_notification: e.target.checked,
                                });
                                setIsUpdate(true);

                                // handle_change_setting(
                                //   "payment_push_notification",
                                //   e
                                // );
                              }}
                              checked={settig_obj.payment_push_notification}
                            />
                            <div className="mx-2"> Push notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={10}
                              sliderHeight={10}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  payment_email_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                                // handle_change_setting(
                                //   "payment_email_notification",
                                //   e
                                // );
                              }}
                              checked={settig_obj.payment_email_notification}
                            />
                            <div className="mx-2">Email notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={10}
                              sliderHeight={10}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  payment_sms_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                                // handle_change_setting(
                                //   "payment_sms_notification",
                                //   e
                                // );
                              }}
                              checked={settig_obj.payment_sms_notification}
                            />
                            <div className="mx-2">Text notifications</div>
                          </div>
                        </div>
                        <div className="px-3 mt-2">
                          <div className="setting_head">
                            Admin notifications
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={10}
                              sliderHeight={10}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  admin_push_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                                // handle_change_setting(
                                //   "admin_push_notification",
                                //   e
                                // );
                              }}
                              checked={settig_obj?.admin_push_notification}
                            />
                            <div className="mx-2">Push notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={10}
                              sliderHeight={10}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  admin_email_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                                // handle_change_setting(
                                //   "admin_email_notification",
                                //   e
                                // );
                              }}
                              checked={settig_obj.admin_email_notification}
                            />
                            <div className="mx-2">Email notifications</div>
                          </div>
                          <div className="setting_cont mt-2 d-flex">
                            <Toggle
                              backgroundColorChecked="#4BBB46"
                              backgroundColorUnchecked="#999999"
                              width={43}
                              height={20}
                              sliderWidth={10}
                              sliderHeight={10}
                              onChange={(e) => {
                                setSetting_obj({
                                  ...settig_obj,
                                  admin_sms_notification: e.target.checked,
                                });
                                setIsUpdate(true);
                                // handle_change_setting(
                                //   "admin_sms_notification",
                                //   e
                                // );
                              }}
                              checked={settig_obj.admin_sms_notification}
                            />
                            <div className="mx-2">Text notifications</div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
