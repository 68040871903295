/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "./Chapter.css";
import TopNavBar from "../TopNavBar/TopNav";
import { Progress_btn } from "../Style/Styled";
import { course_bg, no_data } from "../../Assets/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import UseGetSubject from "../../CustomHook/UseGetSubject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubjectBar from "../SubjectBar";
import { useApiContext } from "../../Context/ApiContext";
import { SignalCellularNull } from "@mui/icons-material";

function Chapter() {
  const { all_chapter, apiLoading, all_subject } = useApiContext();
  const [math_data, setMath_data] = useState([]);
  const [active, set_Active] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedActive = localStorage.getItem("sub");
    if (storedActive) {
      set_Active(storedActive);
    } else {
      set_Active(all_subject[0]?.id);
    }
  }, [all_subject]);
  localStorage.removeItem("subId");
  const handle_lesson_id = (elem) => {
    localStorage.setItem("chptr_id", elem.id);
    localStorage.setItem("chptr_img", elem.image);
    localStorage.setItem("chptr_color", elem.color);
    localStorage.setItem("sub", active);
    localStorage.setItem("lesson_name", elem.chapter_name);
  };

  useEffect(() => {
    const filterData = all_chapter.filter(
      (chapter) => chapter.subject_id == active
    );
    setMath_data(filterData);
  }, [active, all_chapter]);

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  return (
    <>
      <div className="comp_container comic_font">
        <TopNavBar />

        <SubjectBar
          all_subject={all_subject}
          active={active}
          set_active={set_Active}
        />

        <div className="sideDiv">
          <div className="w-100">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="mt-5 chapter_contaier_div">
              <>
                {!apiLoading.chapter ? (
                  <>
                    {math_data.length !== 0 ? (
                      <div className="row_one">
                        {math_data?.map((elem, index) => (
                          <div
                            key={index}
                            className="chaptr_div"
                            onClick={() => {
                              handle_lesson_id(elem);
                              navigate(`/lesson/${elem?.id}`);
                            }}
                          >
                            <img
                              src={elem.bg_image || course_bg}
                              alt=""
                              className="chaptr_img"
                            />
                            <div className="one_anotherBox">
                              <div className="chpt_num">
                                Chapter
                                <span className="mx-2">
                                  {elem?.chapter_number}
                                </span>
                              </div>
                              <div className="chpt_name">
                                {elem?.chapter_name.length > 10
                                  ? `${elem?.chapter_name.slice(
                                      0,
                                      6
                                    )}...${elem?.chapter_name.slice(
                                      elem?.chapter_name.length - 2,
                                      elem?.chapter_name.length
                                    )}`
                                  : elem?.chapter_name}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <div className="no_data_img">
                          <img src={no_data} alt="" />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="">
                    <ClipLoader
                      color="black"
                      loading={apiLoading.Chapter}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chapter;
