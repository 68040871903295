import React from "react";
import Modal from "react-bootstrap/Modal";
import "./styles.css";
import { cartoon, close } from "../../Assets/Images";

const ScoreModal = ({
  show,
  onHide,
  className,
  title,
  subTitle,
  onClick,
  totalScore,
  obtainedScore,
  body,
  btn_content,
  btn,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="modal-lg"
      animation={false}
      aria-labelledby="contained-modal-title-lg"
      centered
      style={{
        borderRadius: "80px",
        // paddingLeft: '150x'
      }}
    >
      <Modal.Body
        style={{
          padding: "10% 112px 30px",
          height: "389px",
          borderRadius: "80px",
          backgroundColor: "white",
          border: "8px solid #8B60FD",
        }}
      >
        <div className="comic_font">
          <img src={cartoon} alt="" className="carton_img" />
          <div className="d-flex close_btn_self">
            <img src={close} alt="" onClick={onClick} />
          </div>
          <div className={className ? className : "post_model mt-3"}>
            {title}
          </div>
          <div className="check_ans_body d-flex justify-content-center">
            {body}
          </div>
          <div className="d-flex justify-content-center">
            {btn_content && (
              <button className="p-2 mt-2 check_ans_btn" onClick={btn}>
                {btn_content}
              </button>
            )}
          </div>
          {(subTitle || totalScore || obtainedScore) && (
            <div className="d-flex justify-content-center">
              <div className="score_green">
                {subTitle}
                <div className="rations">
                  {obtainedScore + "/" + totalScore}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ScoreModal;
