import "./App.css";
import { useState, useEffect } from "react";
import Home from "./Components/Home/Home";
import SeasonActivity from "./Components/SeasonActivity/SeasonActivity";
import { HashRouter as Router, useLocation } from "react-router-dom";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Aavtar from "./Components/Avatar/Aavtar";
import Login from "./Components/Login/Login";
import ProgressBar from "./Components/Home/ProgressBar_viewAll/ProgressBar";
import Score from "./Components/Home/Score_viewAll/Score";
import UserProfile from "./Components/Profile/UserProfile";
import "react-toastify/dist/ReactToastify.css";
import Chapter from "./Components/Chapter/Chapter";
import Chapter_details from "./Components/Chapter/Chapter_details/Chapter_details";
import Lesson from "./Components/Chapter/Lesson/Lesson";
import QA from "./Components/Chapter/Q&A/QA";
import Selftest from "./Components/Chapter/Selftest/Selftest";
import Solution from "./Components/Chapter/Selftest/Solution";
import PastPaper from "./Components/PastPaper/PastPaper";
import ChapterTest from "./Components/PastPaper/ChapterTest";
import Marking_scheme from "./Components/PastPaper/Marking_scheme/Marking_scheme";
import PracticeSelf from "./Components/My_practice_test/PracticeSelf/Practice_self";
import Start_test from "./Components/PastPaper/Start_test/Start_test";
import Instruction from "./Components/Challenge/Instruction";
import Error from "./Components/Error/Error";
import MyPractice_Test from "./Components/My_practice_test/PracticeTest/MyPractice_Test";
import Revison from "./Components/Revison/Revison";
import Curriculum from "./Components/Curriculum/Curriculum";
import Solution_test from "./Components/My_practice_test/PracticeSelf/Solution";
import Contact_us from "./Components/Contact_us/Contact_us";
import Faq from "./Components/FAQ/Faq";
import Notifications from "./Components/Notification/Notification";
import Test from "./Components/PastPaper/Start_test/Test";
import TestChallenge from "./Components/Challenge/TestChallenge";
import Selftest_sa from "./Components/SeasonActivity/SelfTest_sa/Selftest_sa";
import RewardNew from "./Components/Rewards/RewardNew";
import MyCollection from "./Components/Rewards/MyCollection/MyCollection";
import ProgressSteps from "./Components/PastPaper/Start_test/Embeded_pagination";
import TopNavBar from "./Components/TopNavBar/TopNav";
import Challenge from "./Components/Challenge/Challenge";
import CheckAnswer from "./Components/PastPaper/CheckAnswer/CheckAnswer";
import Mock from "./Components/Mock/Mock";
import MockTest from "./Components/Mock/MockTest";
import MockSolution from "./Components/Mock/MockSolution";
import LearnPastpaper from "./Components/PastPaper/Start_test/LearnPastpaper";
import LearnChallenge from "./Components/Challenge/LearnChallenge";
import { ApiProvider } from "./Context/ApiContext";
import { ToastContainer } from "react-toastify";
import ChallengeAnswer from "./Components/Challenge/ChallengeAnswer";

function AppContent() {
  return <div className="App"></div>;
}

function App() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const T_oken = localStorage.getItem("token");
  //   if (T_oken) {
  //     if (
  //       window.location.pathname === "/" ||
  //       window.location.pathname === "/task"
  //     ) {
  //       navigate("/dashboard");
  //     } else {
  //       navigate(window.location.pathname);
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token && location.pathname !== "/") {
      navigate("/");
    }
  }, [location, navigate]);

  const isLoginPage =
    location.pathname === "/" ||
    location.pathname === "/task" ||
    location.pathname === "*";

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="App">
        {isLoginPage ? (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/task" element={<Login />} />
            <Route path="*" element={<Error />} />
          </Routes>
        ) : (
          <ApiProvider>
            <Routes>
              <Route path="/user" element={<UserProfile />} />
              <Route path="/Season" element={<SeasonActivity />} />
              <Route path="/avatar" element={<Aavtar />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/progress" element={<ProgressBar />} />
              <Route path="/score" element={<Score />} />
              <Route path="/course" element={<Chapter />} />
              <Route path="/details/:id" element={<Chapter_details />} />
              <Route path="/lesson/:id" element={<Lesson />} />
              <Route path="/qa/:id" element={<QA />} />
              <Route path="/selftest/:id" element={<Selftest />} />
              <Route path="/solution" element={<Solution />} />
              <Route path="/pastpaper" element={<PastPaper />} />
              <Route path="/learn-pastpaper" element={<LearnPastpaper />} />
              <Route path="/learn-challenge" element={<LearnChallenge />} />
              <Route path="/chapter-pastpaper" element={<ChapterTest />} />
              <Route path="/scheme" element={<Marking_scheme />} />
              <Route path="/practicetest" element={<MyPractice_Test />} />
              <Route path="/practiceself" element={<PracticeSelf />} />
              <Route path="/starttest" element={<Start_test />} />
              <Route path="/instruction" element={<Instruction />} />
              <Route path="/revision" element={<Revison />} />
              <Route path="/curriculum" element={<Curriculum />} />
              <Route path="/challenge" element={<Challenge />} />
              <Route path="/solution_test" element={<Solution_test />} />
              <Route path="/contactus" element={<Contact_us />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/test" element={<Test />} />
              <Route path="/test-challenge" element={<TestChallenge />} />
              <Route path="/checkanswer" element={<CheckAnswer />} />
              <Route path="/challenge-answer" element={<ChallengeAnswer />} />
              <Route path="/rewards" element={<RewardNew />} />
              <Route path="/step" element={<ProgressSteps />} />
              <Route path="/collection" element={<MyCollection />} />
              <Route path="/s_test/:id" element={<Selftest_sa />} />
              <Route path="/mock" element={<Mock />} />
              <Route path="/mock-test" element={<MockTest />} />
              <Route path="/mock-solution" element={<MockSolution />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </ApiProvider>
        )}
      </div>
    </>
  );
}

export default App;

// npm run build:staging , npm run build:production.
