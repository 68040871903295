import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { logo, homeBg } from "../../Assets/Images/index";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";

import "./Login.css";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState(false);
  const [isUserNameError, setIsUserNameError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [Error, setEror] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [userType, setUserType] = useState("Student");
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const check_rem = localStorage.getItem("remember");
    if (check_rem) {
      const storedUserName = localStorage.getItem("name");
      const storedPassword = localStorage.getItem("pass");

      if (storedUserName !== "") {
        setUserName(storedUserName);
      }
      if (storedPassword !== "") {
        setPassword(storedPassword);
      }

      // Check token expiration and clear it if expired
      const tokenExpiration = localStorage.getItem("tokenExpiration");
      if (tokenExpiration && Date.now() > parseInt(tokenExpiration, 10)) {
        localStorage.removeItem("token");
        localStorage.removeItem("User");
        localStorage.removeItem("tokenExpiration");
      }
    }
  }, []);
  const saveToken = (token, expiration) => {
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiration", expiration);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const userNameChange = (e) => {
    e.preventDefault();
    setUserName(e.target.value);
    if (e.target.value === "") {
      setIsUserNameError(true);
    } else {
      setIsUserNameError(false);
    }
    setEror("");
  };

  const passwordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    if (e.target.value === "") {
      setIsPasswordError(true);
    } else {
      setIsPasswordError(false);
    }

    setEror("");
  };

  const getData = async () => {
    if (userName !== "" && password !== "") {
      if (Error === "") {
        try {
          const bodyData = new FormData();
          bodyData.append("user_name", userName);
          bodyData.append("password", password);

          const resp = await axios.post(
            `${process.env.REACT_APP_NEW_DEVELOPMENT}/login`,

            bodyData,
            {
              headers: {
                "content-type": "multipart/form-data",
                Accept: "application/json",
              },
            }
          );
          const token = resp?.data?.token;
          const tokenExpiration = Date.now() + 43200000;
          saveToken(token, tokenExpiration);
          localStorage.setItem("token", resp?.data?.token);
          localStorage.setItem("User", JSON.stringify(resp?.data?.user));
          localStorage.setItem("remember", check);
          localStorage.setItem("name", userName);
          localStorage.setItem("pass", password);
          if (resp?.data?.status === "ok") {
            navigate("/dashboard");
          }
        } catch (e) {
          setEror(e?.response?.data?.massage);
        }
      }
    } else {
      if (userName === "" && password === "") {
        setIsUserNameError(true);
        setIsPasswordError(true);
      } else if (userName === "") {
        setIsUserNameError(true);
      } else if (password === "") {
        setIsPasswordError(true);
      }
    }
  };

  return (
    <div className="login">
      <div className="spliterSize">
        <img alt="img" src={homeBg} className="mainImage" />

        <img alt="img" src={logo} className="loginLogo" />
      </div>
      <div className="w-100 parent_login_div">
        <div className="loginFields">
          <label className="welcomeText">Welcome back.</label>
          <label className="subHeaderText">Sign in to your account</label>
          <label className="smallHeaderText">
            Enter your username and password below
          </label>
          <div className="signInType">
            <label className="signInHeaderText">Sign in as</label>
            <div className="d-none align-items-center marginLeft mx-3">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="customRadio"
                  name="example1"
                  onClick={(e) => setUserType("Parents")}
                  checked={userType === "Parents" && true}
                  onChange={(e) => {}}
                  style={{ opacity: "1" }}
                />
                <label
                  htmlFor="type1"
                  className="mx-1 marginLeft radio_lable"
                  onClick={(e) => setUserType("Parents")}
                >
                  Parent
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center marginLeft mx-3">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="customRadio"
                  name="example1"
                  onClick={(e) => setUserType("Student")}
                  checked={userType === "Student" && true}
                  onChange={(e) => {}}
                  style={{ opacity: "1" }}
                />
                <label
                  htmlFor="type1"
                  className="mx-1 marginLeft radio_lable"
                  onClick={(e) => setUserType("Student")}
                >
                  Student
                </label>
              </div>
            </div>
          </div>
          <label className="userNameHeaderText">Enter username</label>
          <span className="p-input-icon-right d-flex">
            <input
              type="text"
              placeholder="Username"
              className="userNameField"
              onChange={(e) => {
                userNameChange(e);
              }}
              value={userName}
              required
            />
            {userName !== "" && (
              <div className="check_icon">
                <CheckIcon sx={{ color: "#8B60FD", fontSize: 20 }} />
              </div>
            )}
          </span>

          {isUserNameError && (
            <label className="errorText">The username is required</label>
          )}
          <label className="userNameHeaderText">Password</label>
          <span className="p-input-icon-right d-flex">
            <input
              type={passwordType}
              placeholder="****"
              className="userNameField"
              onChange={(e) => {
                passwordChange(e);
              }}
              value={password}
              // feedback={false}
              required
            />

            {password !== "" && passwordType === "password" && (
              <div className="check_icon">
                <VisibilityIcon onClick={() => togglePassword()} />
              </div>
            )}
            {password !== "" && passwordType === "text" && (
              <div className="check_icon">
                <VisibilityOffIcon onClick={() => togglePassword()} />
              </div>
            )}
          </span>

          {isPasswordError && (
            <label className="errorText">The password is required</label>
          )}
          <span className="p-input-icon-right d-flex">
            <div className="rememberDiv">
              <div className="d-flex align-items-center">
                <label
                  className="checkbox d-flex rememberMe"
                  htmlFor="yellow"
                  style={{ fontSize: "14px" }}
                >
                  <input
                    type="checkbox"
                    name="color"
                    value={check}
                    id="yellow"
                    className="remember_me_check"
                    onChange={(e) => setCheck(e.target.checked)}
                  />
                  Remember me
                </label>
              </div>
              <div className="forgotPasswordDiv">
                <label className="forgotPassBtn">Forgot password?</label>
              </div>
            </div>
          </span>
          {Error !== "" && <label className="errorText">{Error}</label>}
          <span className="p-input-icon-right d-flex">
            <Button
              label="SIGN IN"
              className="loginBtn"
              onClick={() => getData()}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Login;
