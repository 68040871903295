import React, { useEffect, useState } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./PastPaper.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { no_data } from "../../Assets/Images";
import { toast } from "react-toastify";
import { useApiContext } from "../../Context/ApiContext";
import SubjectBar from "../SubjectBar";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function PastPaper() {
  const { all_subject, examBoard, all_chapter, year_data } = useApiContext();
  const [loading, setLoading] = useState(true);
  const [pp_loading, setPP_loading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [active, set_active] = useState();
  const [pastPaperData, setPastPaperData] = useState([]);
  const [pastPaperChapterData, setPastPaperChapterData] = useState([]);
  const [showDrp, setShowDrop] = useState(false);
  const [DrpData, setDrpData] = useState("Select Exam Board");
  const [showDrp2, setShowDrop2] = useState(false);
  const [DrpData2, setDrpData2] = useState("Select Year");
  const [exam_board_id, setExamBoard_id] = useState("");
  const [year, setYear] = useState("");
  const [chptr, setChapter] = useState([]);
  const [show_chptr_data, setShow_chptr_data] = useState(false);
  const [chapter_data, setChapter_data] = useState("Select Chapter");

  const [allPastPaperData, setAllPastPaperData] = useState([]);
  const [filterPastPaper, setFilterPastPaper] = useState([]);
  const [allPastPaperChapterData, setAllPastPaperChapterData] = useState([]);
  const [filterPastPaperChapter, setFilterPastPaperChapter] = useState([]);

  const [isLoadingMap, setIsLoadingMap] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const storedActive = localStorage.getItem("subId");
    if (storedActive) {
      set_active(storedActive);
    } else {
      set_active(all_subject[0]?.id);
    }
  }, [all_subject]);

  localStorage.removeItem("sub");

  const handle_drpChange = (id, name) => {
    setDrpData(name);
    setShowDrop(false);
    setExamBoard_id(id);
  };
  const handle_drpChange2 = (data) => {
    if (data === "Select Year") {
      setYear("");
      setShowDrop2(false);
      setDrpData2(data);
    } else {
      setDrpData2(data);
      setShowDrop2(false);
      setYear(data);
    }
  };

  const handle_chapter_change = (data, id) => {
    setChapter_data(data);
    setShow_chptr_data(false);
  };

  useEffect(() => {
    if (toggle) {
      setDrpData2("Select Chapter");
    } else {
      setDrpData2("Select Year");
    }
  }, [toggle]);

  const handleDownload = (pdf) => {
    fetch(pdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");

        alink.href = fileURL;
        alink.download = pdf?.split("/")?.pop();
        alink.click();
      });
    });
  };

  useEffect(() => {
    getPastPaper();
    getPastPaperChapter();
  }, []);
  const getPastPaper = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/past-paper`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      if (resp) {
        setAllPastPaperData(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const getPastPaperChapter = async () => {
    const token = localStorage.getItem("token");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/chapter-question`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );

      if (resp) {
        setAllPastPaperChapterData(resp?.data);
      }
    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.status === 500) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    const filterData = allPastPaperData.filter(
      (paper) => paper.subject_id == active
    );
    setFilterPastPaper(filterData);
    const filterchapterData = allPastPaperChapterData.filter(
      (paper) => paper.subject_id == active
    );
    setFilterPastPaperChapter(filterchapterData);
  }, [active, allPastPaperData, allPastPaperChapterData]);

  // useEffect(() => {
  //   const extractedYears = filterPastPaper.map((item) => item.year);
  //   const uniqueYears = [...new Set(extractedYears)];
  //   setYear_data(uniqueYears);
  // }, [filterPastPaper]);

  useEffect(() => {
    setDrpData("Select Exam Board");
    setDrpData2("Select Year");
    setCurrentPage(1);
  }, [active]);

  useEffect(() => {
    if (DrpData === null || DrpData2 === null) return;

    let filterExamBoardData = filterPastPaper;
    let filterYearData = filterPastPaper;

    if (DrpData !== "Select Exam Board") {
      filterExamBoardData = filterPastPaper.filter(
        (paper) => paper.exam_board_id === exam_board_id
      );
    }

    if (DrpData2 !== "Select Year") {
      filterYearData = filterPastPaper.filter(
        (paper) => paper.year === DrpData2
      );
    }

    const filtered = filterExamBoardData.filter((paper) =>
      filterYearData.includes(paper)
    );

    setPastPaperData(filtered);
    if (filterPastPaper.length > 0) {
      setLoading(false);
    }
  }, [DrpData, DrpData2, filterPastPaper]);

  useEffect(() => {
    if (DrpData === null) return;
    let filterChapterExamBoardData = filterPastPaperChapter;

    if (DrpData !== "Select Exam Board") {
      filterChapterExamBoardData = filterPastPaperChapter.filter(
        (paper) => paper.exam_board_id === exam_board_id
      );
    }
    setPastPaperChapterData(filterChapterExamBoardData);
  }, [DrpData, filterPastPaperChapter]);

  useEffect(() => {
    setYear("");
    setDrpData2("Select Year");
    setChapter_data("Select Chapter");
  }, [toggle]);

  const handle_marking_scheme = (item) => {
    setPP_loading(true);
    if (item) {
      localStorage.setItem("ms_data", JSON.stringify(item));
      localStorage.setItem("pp_toggle", toggle);
      localStorage.setItem("subId", active);
    }
    navigate("/scheme");
  };

  const clear_local_storage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    navigate("/");
  };

  const handle_start_test = (item, mode) => {
    localStorage.setItem("pp_qstn", JSON.stringify(item));
    localStorage.setItem("mode", mode);
    localStorage.setItem("subId", active);
    localStorage.removeItem("chapter");
    localStorage.removeItem("challenge");
    localStorage.removeItem("solution");
    localStorage.removeItem("active_qstn");
    localStorage.removeItem("selftest_qstn_arr");
    localStorage.removeItem("Time");
    localStorage.removeItem("theory");
    localStorage.removeItem("activeChild");
    localStorage.removeItem("activeQuestion");
    localStorage.removeItem("checkboxStatus");
    localStorage.removeItem("answeredQuestions");
    localStorage.removeItem("currentQuestionAnswered");
    navigate("/starttest", { state: item });
  };
  const handle_chapter_start_test = (item, chapter) => {
    localStorage.setItem("pp_qstn", JSON.stringify(item));
    localStorage.setItem("chapter", chapter);
    localStorage.removeItem("mode");
    navigate("/chapter-pastpaper", { state: item });
  };

  // pagination

  const itemsPerPage = 10;
  // Get current data for the page
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return pastPaperData.slice(startIndex, endIndex);
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(pastPaperData.length / itemsPerPage);

  return (
    <div
      className="comp_container comic_font"
      style={{ backgroundColor: "#FEF9EF" }}
    >
      <TopNavBar />
      <SubjectBar
        all_subject={all_subject}
        active={active}
        set_active={set_active}
      />
      <div className="sideDiv">
        <div className="w-100">
          <div className="pp_drp_downs mx-4">
            <div className="self_dropdown">
              <div className="pp_dropdown">
                <button
                  className="pp_dropbtn"
                  onClick={() => setShowDrop(!showDrp)}
                >
                  <div className="d-flex justify-content-between slectExam ">
                    <span className="pastPaper_drpdown"> {DrpData}</span>
                    <KeyboardArrowDownIcon />
                  </div>
                </button>

                {showDrp && (
                  <div className="d-flex justify-content-center mx-3 z ">
                    <div
                      className="pp_dropdown-content"
                      style={{ width: "100%" }}
                    >
                      {examBoard.length !== 0 && (
                        <>
                          <button
                            onClick={() =>
                              handle_drpChange(" ", "Select Exam Board")
                            }
                          >
                            Select Exam Board
                          </button>
                          {examBoard.map((item, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handle_drpChange(item.id, item?.exam_board)
                              }
                            >
                              {item?.exam_board}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!toggle && (
                <div className="pp_dropdown_year mx-2">
                  <button
                    className="pp_dropbtn"
                    onClick={() => setShowDrop2(!showDrp2)}
                  >
                    <div className="d-flex justify-content-between  selectYear ">
                      <span className="pastPaper_drpdown"> {DrpData2}</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  </button>

                  {showDrp2 && (
                    <div className="d-flex justify-content-center mx-3 ">
                      <div
                        className="pp_dropdown-content"
                        style={{ width: "100%" }}
                      >
                        {year_data.length !== 0 && (
                          <>
                            <button
                              onClick={() => handle_drpChange2("Select Year")}
                            >
                              Select year
                            </button>
                            {year_data.map((item, index) => (
                              <button
                                onClick={() => handle_drpChange2(item?.year)}
                                key={index}
                              >
                                {item?.year}
                              </button>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {toggle && (
                <div
                  className="pp_dropdown_year mx-2"
                  style={{ display: "none" }}
                >
                  <button
                    className="pp_dropbtn"
                    onClick={() => setShow_chptr_data(!show_chptr_data)}
                  >
                    <div className="d-flex justify-content-between selectYear ">
                      <span className="pastPaper_drpdown"> {chapter_data}</span>
                      <KeyboardArrowDownIcon />
                    </div>
                  </button>

                  {show_chptr_data && (
                    <div className="d-flex justify-content-center mx-3">
                      <div
                        className="pp_dropdown-content"
                        style={{ width: "100%" }}
                      >
                        {chptr?.length !== 0 && (
                          <>
                            <button
                              onClick={() =>
                                handle_chapter_change("Select Chapter")
                              }
                            >
                              Select Chapter
                            </button>
                            {chptr?.map((item, index) => (
                              <button
                                onClick={() =>
                                  handle_chapter_change(item.name, item.id)
                                }
                                key={index}
                              >
                                {item.name.length > 15
                                  ? `${item.name.slice(
                                      0,
                                      15
                                    )}...${item.name.slice(
                                      item.name.length - 2,
                                      item.name.length
                                    )}`
                                  : item.name}
                              </button>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="year_div">
              <div className="pp_dropdown ">
                <div className="yearDropdown">
                  <button className="pp_dropbtn">
                    <div className="d-flex justify-content-between mt-2 mx-1 chooseYear ">
                      {toggle ? (
                        <span className="pastPaper_drpdown">Chapter</span>
                      ) : (
                        <span className="pastPaper_drpdown">Year</span>
                      )}

                      <div className="switch">
                        <input
                          type="checkbox"
                          value={toggle}
                          id="switch-checkbox"
                          onClick={(e) => {
                            {
                              setToggle(!toggle);
                            }
                          }}
                        />
                        <label htmlFor="switch-checkbox"></label>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {loading === true ? (
            <div className=" mt-5">
              <ClipLoader
                color="black"
                loading={pp_loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              {!toggle ? (
                <>
                  {pastPaperData?.length > 0 ? (
                    <>
                      <div className="table_main_div mb-4">
                        <div className="pp_table_div mt-5 ms-4 me-5 gap-4">
                          <div className="comic_font table_right">
                            <table className="pp_table">
                              <thead className="tr_row">
                                <tr className="">
                                  <th
                                    className="table_head"
                                    scope="col"
                                    style={{
                                      border: "none",
                                      borderRadius: "30px 0px 0px 0px",
                                    }}
                                  >
                                    Subject
                                  </th>
                                  <th className="table_head" scope="col">
                                    Board
                                  </th>
                                  <th className="table_head" scope="col">
                                    Year
                                  </th>
                                  <th className="table_head" scope="col">
                                    Paper type
                                  </th>
                                  <th className="table_head" scope="col"></th>

                                  <th className="table_head" scope="col">
                                    Mode
                                  </th>
                                  <th className="table_head" scope="col">
                                    Mode
                                  </th>
                                  <th
                                    className="table_head"
                                    scope="col"
                                    style={{
                                      border: "none",
                                      borderRadius: "0px 30px 0px 0px",
                                    }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {getCurrentPageData()?.map((item, index) => {
                                  const subName = all_subject.find(
                                    (detail) => detail.id === item?.subject_id
                                  );
                                  const boardName = examBoard.find(
                                    (detail) =>
                                      detail.id === item?.exam_board_id
                                  );
                                  return (
                                    <tr key={index} className="td_row">
                                      <td
                                        className="table_data"
                                        style={{ color: "#162880" }}
                                      >
                                        <u>{subName?.name}</u>
                                      </td>
                                      <td className="table_data">
                                        {boardName?.exam_board}
                                      </td>

                                      <td className="table_data">
                                        {item?.year}
                                      </td>

                                      {item?.paper_level == null ? (
                                        <td className="table_data">-</td>
                                      ) : (
                                        <td className="table_data">
                                          {item?.paper_level}
                                        </td>
                                      )}

                                      {item?.paper_type == null ? (
                                        <td className="table_data">-</td>
                                      ) : (
                                        <td className="table_data">
                                          {item?.paper_type}
                                        </td>
                                      )}

                                      <td
                                        className="start_test"
                                        onClick={() =>
                                          handle_start_test(item, "learn")
                                        }
                                      >
                                        <button className="mode">
                                          <span>Learn</span>
                                        </button>
                                      </td>
                                      <td
                                        className="start_test"
                                        onClick={() =>
                                          handle_start_test(item, "test")
                                        }
                                      >
                                        <button className="mode">
                                          {item?.score == null ? (
                                            <span>Test</span>
                                          ) : (
                                            <span>Redo</span>
                                          )}
                                        </button>
                                      </td>
                                      {item?.score == null ? (
                                        <td className="table_data">-</td>
                                      ) : (
                                        <td
                                          className="table_data"
                                          style={{ color: "#95C136" }}
                                        >
                                          {item?.score?.correct_score}/
                                          {item?.score?.total_score}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div className="comic_font left_table">
                            <table className="Marking table_marking">
                              <thead className="tr_row">
                                <tr className="">
                                  <th
                                    className="table_head"
                                    scope="col"
                                    style={{
                                      border: "none",
                                      borderRadius: "30px 0px 0px 0px",
                                    }}
                                  >
                                    Past Paper
                                  </th>
                                  <th
                                    className="table_head"
                                    scope="col"
                                    style={{
                                      border: "none",
                                      borderRadius: "0px 30px 0px 0px",
                                    }}
                                  >
                                    Marking Scheme
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getCurrentPageData()?.map((item, index) => {
                                  return (
                                    <tr key={index} className="td_row">
                                      <td className="table_data">
                                        <button
                                          className="download"
                                          onClick={() =>
                                            handleDownload(item?.pp_file)
                                          }
                                          disabled={!item?.pp_file}
                                        >
                                          {isLoadingMap[index] && (
                                            <ClipLoader
                                              color="black"
                                              loading={isLoadingMap[index]}
                                              size={20}
                                              aria-label="Loading Spinner"
                                              data-testid={`loader-${index}`}
                                            />
                                          )}
                                          Download
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          className="view_btn"
                                          onClick={() =>
                                            handle_marking_scheme(item)
                                          }
                                          disabled={!item?.ms_file}
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {pastPaperData.length > itemsPerPage && (
                        <div className="pagination d-flex justify-content-center my-4">
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            <ArrowLeftIcon />
                          </button>
                          <span className="mx-1">
                            Page {currentPage} of {totalPages}
                          </span>
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          >
                            <ArrowRightIcon />
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="no_data_img">
                        <img src={no_data} alt="" />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* <----------------------------ChapterTable------------------------------> */}

                  {pastPaperChapterData.length > 0 ? (
                    <div className="table_main_div">
                      <div className="pp_table_div pp_table_chapter mt-5">
                        <div className="comic_font table_right">
                          <table className="table_pp" id="dtHorizontalExample">
                            <thead className="tr_row">
                              <tr className="">
                                <th
                                  className="table_head"
                                  scope="col"
                                  style={{
                                    border: "none",
                                    borderRadius: "30px 0px 0px 0px",
                                  }}
                                >
                                  Subject
                                </th>
                                <th className="table_head" scope="col">
                                  Board
                                </th>
                                <th className="table_head" scope="col">
                                  Chapter
                                </th>

                                <th
                                  className="table_head"
                                  scope="col"
                                  style={{
                                    border: "none",
                                    borderRadius: "0px 30px 0px 0px",
                                  }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pastPaperChapterData.map((item, index) => {
                                const subName = all_subject.find(
                                  (detail) => detail.id === item?.subject_id
                                );
                                const boardName = examBoard.find(
                                  (detail) => detail.id === item?.exam_board_id
                                );
                                const chapterName = all_chapter.find(
                                  (detail) => detail.id === item?.chapter_id
                                );
                                return (
                                  <tr key={index} className="td_row">
                                    <td
                                      className="table_data"
                                      style={{ color: "#162880" }}
                                    >
                                      <u>{subName?.name}</u>
                                    </td>
                                    <td className="table_data">
                                      {boardName?.exam_board}
                                    </td>

                                    {item?.chapter_id == null ? (
                                      <td className="table_data">-</td>
                                    ) : (
                                      <td className="table_data">
                                        {chapterName?.chapter_name}
                                      </td>
                                    )}

                                    <td
                                      className="start_test"
                                      onClick={() =>
                                        handle_chapter_start_test(
                                          item,
                                          "chapter"
                                        )
                                      }
                                    >
                                      <button className="mode">
                                        <span>Start Test</span>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="no_data_img">
                        <img src={no_data} alt="" />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PastPaper;
