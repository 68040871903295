/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Back_btn, PostQuestion, SaveBtn } from "../../Style/Styled";
import "./QA.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PersonIcon from "@mui/icons-material/Person";
import { close, cmnt_arrow, no_data, share } from "../../../Assets/Images";
import { FaComments } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "../Chapter_details/Chapter_details.css";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import InfoIcon from "@mui/icons-material/Info";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function QA() {
  const { id } = useParams();

  const [open, setOpen] = useState({
    id: "",
    isOpen: false,
  });
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [qNa, set_qNa] = useState([]);
  const [post_question, setPost] = useState(false);
  const [lesson_id, setLesson_id] = useState("");
  const [question, setQuestion] = useState("");
  const [question_error, setQuestionError] = useState("");
  const [reply, setReply] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("User");
    setUserData(JSON.parse(user));
    const l_id = localStorage.getItem("lesson_id");
    if (l_id) {
      setLesson_id(l_id);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      get_self_test();
    }
  }, [userData]);

  const get_self_test = async () => {
    const token = localStorage.getItem("token");

    try {
      const bodyData = new FormData();
      bodyData.append("id", userData.id);
      bodyData.append("lesson_id", id);

      const resp = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewQnA`,

        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
          },
        }
      );
      if (resp) {
        set_qNa(resp.data?.data);
        setLoading(false);
      }
    } catch (e) {
      if (e.status_code === 500 && e.message === "Unauthenticated") {
        clear_local_storage();
      } else if (e?.response?.data?.status_code === 401) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const post_qstn = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    if (question !== "") {
      try {
        const bodyData = new FormData();
        bodyData.append("lesson_id", id);
        bodyData.append("question", question);

        const resp = await axios.post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/ps/createQnA`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (resp) {
          setPost(false);
          get_self_test();
          setQuestion("");
          setQuestionError("");
        }
      } catch (e) {
        if (
          e?.response?.data?.status_code === 401 ||
          e?.response?.data?.status_code === 500
        ) {
          clear_local_storage();
        } else {
          toast.error("Something went wrong");
        }
      }
    } else {
      setQuestionError("please type something");
    }
    setLoading(false);
  };

  const post_reply = async (Q_id) => {
    const token = localStorage.getItem("token");
    setLoading(true);

    if (reply !== "") {
      try {
        const bodyData = new FormData();
        bodyData.append("user_id", userData.id);
        bodyData.append("question_id", Q_id);
        bodyData.append("reply", reply);

        const resp = await axios.post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/createReply`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (resp) {
          setReply("");
          get_self_test();
        }
      } catch (e) {
        if (
          e?.response?.data?.status_code === 401 ||
          e?.response?.data?.status_code === 500
        ) {
          clear_local_storage();
        } else {
          toast.error("Something went wrong");
        }
      }
    }
    setLoading(false);
  };

  const handle_change = (e) => {
    e.preventDefault();

    if (e.target.value !== "") {
      setQuestionError("");
    } else {
      setQuestionError("please type something");
    }
    setQuestion(e.target.value);
  };

  const handle_reply = (e) => {
    e.preventDefault();

    setReply(e.target.value);
  };
  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };

  return (
    <>
      <div className="comp_container">
        <div className="w-100 QnA_bg">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="w-100 QA_heading">
            <h5 className="m-2 heading">Chapter 3: 3.1 Introduction</h5>

            <div className="QA_btn mx-2">
              <div className="mt-1 desktop_post">
                <PostQuestion
                  onClick={() => setPost(!post_question)}
                  style={{ paddingBottom: "5px" }}
                >
                  <AddCircleOutlineIcon
                    style={{
                      backgroundColor: "#FF6700",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                  Post questions?
                </PostQuestion>
              </div>
              <div className="mt-1 mobile_post">
                <PostQuestion
                  onClick={() => setPost(!post_question)}
                  style={{ paddingBottom: "5px" }}
                >
                  <AddCircleOutlineIcon
                    style={{
                      backgroundColor: "#FF6700",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                </PostQuestion>
              </div>

              <div className="back_btn_width">
                <Back_btn
                  border="1px solid #064974"
                  onClick={() => navigate(`/details/${lesson_id}`)}
                >
                  Back
                </Back_btn>
              </div>
            </div>
          </div>

          <div className="parent_QA comic_font">
            <div className="all_question">
              All questions in this course
              <span className="mx-1" style={{ color: "#FF6700" }}>
                (<span>{qNa.length}</span>)
              </span>
            </div>

            <div className="text-center mt-5">
              {qNa.length === 0 && (
                <>
                  {loading && (
                    <div className="d-flex justify-content-center w-100">
                      <ClipLoader
                        color="black"
                        loading={loading}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}
                  {!loading && (
                    <>
                      <div className="no_data_img">
                        <img src={no_data} alt="" />
                      </div>
                    </>
                  )}
                </>
              )}
              {qNa.length !== 0 && (
                <>
                  {qNa.map((item, index) => (
                    <div key={index}>
                      <div className="mt-3 questions_details" key={index}>
                        <div className="d-flex">
                          <div className="profile_student">
                            <PersonIcon className="person_icon mt-3" />
                          </div>
                          <div className="question_div">
                            <div className="question">{item?.question}</div>
                          </div>
                        </div>
                        <div>
                          {open.id === item.id && open.isOpen === true && (
                            <div
                              onClick={() => {
                                if (open.id !== item?.id) {
                                  setOpen({ id: item?.id, isOpen: true });
                                } else {
                                  setOpen({
                                    id: item?.id,
                                    isOpen: !open.isOpen,
                                  });
                                }
                              }}
                              className="up_arr"
                            >
                              <img src={cmnt_arrow} alt="" />
                            </div>
                          )}
                          <div
                            className="comment_icon mt-4"
                            onClick={() => {
                              if (open.id !== item?.id) {
                                setOpen({ id: item?.id, isOpen: true });
                              } else {
                                setOpen({
                                  id: item?.id,
                                  isOpen: !open.isOpen,
                                });
                              }
                            }}
                          >
                            <div className="c_count">{item?.replies_count}</div>
                            <div className="mx-2">
                              <FaComments
                                style={{ width: "18px", height: "16px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text_student d-flex justify-content-between mt-3">
                        Student
                        <div
                          className="comment_icon_2"
                          onClick={() => {
                            if (open.id !== item?.id) {
                              setOpen({ id: item?.id, isOpen: true });
                            } else {
                              setOpen({
                                id: item?.id,
                                isOpen: !open.isOpen,
                              });
                            }
                          }}
                        >
                          <div>{item?.replies_count}</div>
                          <div className="mx-2">
                            <FaComments />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="back_dot  mt-1"></div>
                          <span className="mx-2" style={{ color: "#424B54" }}>
                            {item?.created_date}
                          </span>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-
                      content-center"
                      >
                        {open.isOpen &&
                          open.id === item?.id &&
                          item.reply !== [] && (
                            <div className="comment_div mt-3">
                              {item.reply.map((elem, index) => (
                                <div key={index}>
                                  {elem.user.id !== userData.id && (
                                    <div className="d-flex mt-1">
                                      <div className="">
                                        <PersonIcon className="person_icon mt-3" />
                                      </div>
                                      <div className="purple_comnt mx-2">
                                        <div className="comnt_data">
                                          {elem.reply}
                                        </div>
                                        <div>
                                          <div className="text_student d-flex mt-3">
                                            Student
                                            <div className="d-flex mx-3">
                                              <div className="back_dot  mt-1"></div>
                                              <span
                                                className="mx-2"
                                                style={{ color: "#424B54" }}
                                              >
                                                {elem.created_date}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {elem.user.id === userData.id && (
                                    <div className="d-flex justify-content-end mt-1">
                                      <div className="yellow_comment mx-2">
                                        <div className="d-flex">
                                          <div
                                            className="comnt_data"
                                            style={{
                                              textAlign: "right",
                                            }}
                                          >
                                            {elem.reply}
                                          </div>
                                          <PersonIcon className="yelloW_person_icon mx-3" />
                                        </div>

                                        <div className="d-flex justify-content-end">
                                          <div className="text_student d-flex mt-3">
                                            Sqillup team
                                            <div className="d-flex mx-3">
                                              <div className="back_dot  mt-1"></div>
                                              <span
                                                className="mx-2"
                                                style={{ color: "#424B54" }}
                                              >
                                                {elem.created_date}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}

                              <div className="add_cmnt d-flex w-100 mt-5">
                                <div className="profile_student">
                                  <PersonIcon
                                    className="person_icon mt-3"
                                    style={{ height: "32px", width: "32px" }}
                                  />
                                </div>

                                <input
                                  type="text"
                                  className="add_comnt_inp"
                                  placeholder="Type your message here..."
                                  onChange={(e) => handle_reply(e)}
                                  value={reply}
                                />

                                {!loading && (
                                  <div
                                    className="share mt-2"
                                    onClick={() => post_reply(item.id)}
                                  >
                                    <img
                                      src={share}
                                      alt=""
                                      width={40}
                                      height={40}
                                    />
                                  </div>
                                )}
                                {loading && (
                                  <div className="share mt-2">
                                    <img
                                      src={share}
                                      alt=""
                                      width={40}
                                      height={40}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {post_question && (
        <Modal
          show={post_question}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body
            className="postModal"
            style={{ background: "white", borderRadius: "10px" }}
          >
            <div className="comic_font">
              <img
                src={close}
                alt=""
                className="glossary_close"
                onClick={() => setPost(false)}
              />
              <div className="post_head">Post Question</div>
              <textarea
                className="textarea_post mt-4"
                placeholder="Type your quetion..."
                onChange={(e) => handle_change(e)}
              />
              {question_error !== "" && (
                <div className="error_qna d-flex">
                  <div className="info_qna">
                    <InfoIcon style={{ fontSize: "14px" }} />
                  </div>
                  {question_error}
                </div>
              )}
              <div className="d-flex justify-content-center">
                {!loading && (
                  <SaveBtn
                    className="mt-4"
                    width="120px"
                    bg="#FF6700"
                    shadow="2px 2px 2px 2px #FF6700"
                    onClick={() => post_qstn()}
                    color="white"
                    height="35px"
                    f_size="23px"
                    f_weight="550"
                  >
                    Submit
                  </SaveBtn>
                )}

                {loading && (
                  <SaveBtn
                    className="mt-4"
                    width="120px"
                    bg="#FF6700"
                    shadow="2px 2px 2px 2px #FF6700"
                    color="white"
                    height="35px"
                    f_size="23px"
                    f_weight="550"
                  >
                    Submit
                  </SaveBtn>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default QA;
