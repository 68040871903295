import React from 'react'
import { SaveBtn } from '../Style/Styled'
import Modal from "react-bootstrap/Modal";
import './styles.css'

const SuccessModal = ({ show, onHide, title, myCallback }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={''}
      animation={false}
      dialogClassName="modal-lg"
      aria-labelledby="contained-modal-title-lg"
      centered
      className=""
      style={{
        borderRadius: "80px",
      }}
    >
      <Modal.Body
        className="successModal"
        style={{
          padding: "10% 78px 30px",
          height: "389px",  
          borderRadius: "80px",
          backgroundColor: "white",
          border: "8px solid #8B60FD",
        }}
      >
        <div className="comic_font">
          <h5 className="logOutHeader_self ">
            {title}
          </h5>

          <div className="d-flex justify-content-center yes_no_div">
            <SaveBtn
              className="saveBtn"
              width="120px"
              height="47px"
              f_size="20px"
              bg="#918E96"
              color="white"
              shadow="2px 2px 2px 2px #918E96"
              onClick={() => myCallback(false)}
            >
              No
            </SaveBtn>
            <SaveBtn
              className="saveBtn mx-3"
              width="120px"
              height="47px"
              f_size="20px"
              bg="#FF6700"
              color="white"
              shadow="2px 2px 2px 2px #FF6700"
              onClick={() => myCallback(true)}
            >
              Yes
            </SaveBtn>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default SuccessModal;
// import React from 'react';
// import { SaveBtn } from '../Style/Styled';
// import Modal from 'react-bootstrap/Modal';
// import './styles.css';

// const SuccessModal = ({ show, onHide, title, myCallback }) => {
//   return (
//     <Modal
//       show={show}
//       onHide={onHide}
//       size={''}
//       animation={false}
//       aria-labelledby="contained-modal-title-lg"
//       centered
//       className="custom-modal" // Add a custom class for styling
//     >
//       <Modal.Body className="custom-modal-body">
//         <div className="comic_font">
//           <h5 className="logOutHeader_self mx-4">{title}</h5>

//           <div className="d-flex justify-content-center yes_no_div">
//             <SaveBtn
//               className="saveBtn"
//               onClick={() => myCallback(false)}
//             >
//               No
//             </SaveBtn>
//             <SaveBtn
//               className="saveBtn mx-3"
//               onClick={() => myCallback(true)}
//             >
//               Yes
//             </SaveBtn>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default SuccessModal;
