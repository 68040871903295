/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSpeechRecognition } from "react-speech-recognition";
import { Back_btn, SaveBtn } from "../../Style/Styled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import ClipLoader from "react-spinners/ClipLoader";
import { sound, right_ans, wrong_ans, no_data } from "../../../Assets/Images";

import useSound from "use-sound";
import { right_ans_sound } from "../../../Assets/Sound/Index";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScoreModal from "../../ScoreModal";

function StructuredQues({ question, loading, data, state }) {
  const { id } = useParams();
  //   const { state } = useLocation();

  const [score_modal, setScore_modal] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [play] = useSound(right_ans_sound);
  //   const [question, setquestion] = useState([]);
  //   const [loading, setLoading] = useState(true);
  const [show_next, setShow_next] = useState(false);
  const [show_submit, setShow_submit] = useState(true);
  const [initial_index, setInitial_index] = useState(0);
  const [final_index, setFinal_index] = useState(9);
  const [qstn_no_arr, set_qstn_no_arr] = useState([]);
  const [Active, setActive] = useState(0);
  const [option, setOption] = useState("");
  const [correct, setCorrect] = useState();
  const [showSolution, setShowSolution] = useState(false);
  const [score, setScore] = useState(0);
  const [revisionQuestion, setRevisionQuestion] = useState();
  const [challengeMode, setChallengeMode] = useState();
  const [studentId, setStudentId] = useState();
  //   const { apiLoading, selfTestQuestions, userData } = useApiContext();

  // speect-recognition
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [speaking, setSpeaking] = useState(false);
  const [supported, setSupported] = useState(false);
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    if ("speechSynthesis" in window) {
      setSupported(true);
      setVoices(window.speechSynthesis.getVoices());
    }
  }, []);

  const speak = (text, voice) => {
    if (supported) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = voice;
      window.speechSynthesis.speak(utterance);
      setSpeaking(true);
      utterance.onend = () => setSpeaking(false);
    }
  };

  const cancel = () => {
    if (supported) {
      window.speechSynthesis.cancel();
      setSpeaking(false);
    }
  };

  // end speect-recognition
  useEffect(() => {
    const userId = localStorage.getItem("User");
    const student = JSON.parse(userId);
    setStudentId(student.id);

    // const active_qstn = localStorage.getItem("active_qstn");
    // if (active_qstn) {
    //   setActive(Number(active_qstn));
    // } else {
    //   setActive(0);
    // }
    const selftest_qstn_arr = JSON.parse(
      localStorage.getItem("selftest_qstn_arr")
    );
    if (selftest_qstn_arr) {
      set_qstn_no_arr(selftest_qstn_arr);
      if (selftest_qstn_arr[Active]?.correct == false) {
        setShowSolution(true);
      }
    }
    const challenge = localStorage.getItem("challenge");
    setChallengeMode(challenge);
  }, []);

  useEffect(() => {
    const selftest_qstn_arr = JSON.parse(
      localStorage.getItem("selftest_qstn_arr")
    );
    if (state && state?.isRivision == true) {
      setRevisionQuestion(question);
      const arr = [];
      for (let i = 0; i < question?.length; i++) {
        arr.push({ id: i, correct: "", ans_id: "" });
      }
      set_qstn_no_arr(arr);
    } else {
      if (selftest_qstn_arr) {
        set_qstn_no_arr(selftest_qstn_arr);
      } else {
        if (question?.length !== 0) {
          const arr = [];
          for (let i = 0; i < question?.length; i++) {
            arr.push({ id: i, correct: "", ans_id: "" });
          }
          set_qstn_no_arr(arr);
        }
      }
    }
    if (question?.length > 10) {
      setFinal_index(9);
    }
  }, [question]);

  useEffect(() => {
    const oldTime = localStorage.getItem("Time");
    if (oldTime) {
      setTimeLeft(oldTime);
    } else {
      let time =
        Number(data?.duration_hours) * 60 * 60 +
        Number(data?.duration_minutes) * 60;
      setTimeLeft(time);
    }
  }, [data]);

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [timeLeft]);

  useEffect(() => {
    if (question?.length) {
      if (question) {
        localStorage.setItem(
          "solution",
          JSON.stringify(question[Active]?.solution)
        );
        localStorage.setItem(
          "theory",
          JSON.stringify(question[Active]?.theory)
        );
      }
    }
  }, [question]);

  useEffect(() => {
    if (question?.length !== 0 && qstn_no_arr?.length !== 0) {
      if (qstn_no_arr[Active]?.ans_id !== "") {
        if (qstn_no_arr[Active]?.correct == false) {
          setShowSolution(true);
          localStorage.setItem(
            "solution",
            JSON.stringify(question[Active]?.solution)
          );
          localStorage.setItem(
            "theory",
            JSON.stringify(question[Active]?.theory)
          );
        } else {
          setShowSolution(false);
        }
        if (qstn_no_arr?.length === Active + 1) {
          setShow_submit(false);
          setShow_next(true);
        }
      }
    }
  }, [Active, showSolution]);

  useEffect(() => {
    if (showSolution === true) {
      // localStorage.setItem("active_qstn", Active);
      localStorage.setItem("selftest_qstn_arr", JSON.stringify(qstn_no_arr));
      if (qstn_no_arr?.length === Active + 1) {
        setShow_submit(false);
        setShow_next(true);
      }
    }
  }, [showSolution, Active]);

  const navigate = useNavigate();
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleOptionChange = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(optionId)) {
        return prevSelectedOptions.filter((id) => id !== optionId);
      } else {
        return [...prevSelectedOptions, optionId];
      }
    });
  };
  const handleSubmit = async (
    ques_id,
    id,
    index,
    questionType,
    answers,
    points
  ) => {
    if (option !== "" || selectedOptions.length > 0) {
      setShow_submit(false);
      let isCorrect = false;

      if (questionType === "Mcq") {
        const selectedAnswer = answers.find(
          (answer) => answer.id === parseInt(option)
        );
        isCorrect = selectedAnswer
          ? selectedAnswer.is_correct === "true"
          : false;

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? { ...item, id: index, correct: isCorrect, ans_id: option }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          // play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      } else if (questionType === "One word") {
        const correctAnswer = answers.find(
          (answer) => answer.is_correct === "true"
        );
        isCorrect =
          correctAnswer &&
          correctAnswer.answer.toLowerCase() === option.toLowerCase();

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? { ...item, id: index, correct: isCorrect, ans_id: option }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          // play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      } else if (questionType === "Mcq multiple") {
        const correctAnswers = answers
          .filter((answer) => answer.is_correct === "true")
          .map((answer) => answer.id);

        isCorrect =
          selectedOptions.length === correctAnswers.length &&
          selectedOptions.every((option) => correctAnswers.includes(option));

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? {
                  ...item,
                  id: index,
                  correct: isCorrect,
                  ans_id: selectedOptions,
                }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          // play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      }

      if (questionType === "Filling the blank") {
        const correctAnswer = answers.find(
          (answer) => answer.is_correct === "true"
        );
        isCorrect =
          correctAnswer &&
          correctAnswer.answer.toLowerCase() === option.toLowerCase();

        set_qstn_no_arr(
          qstn_no_arr?.map((item) =>
            item.id === index
              ? { ...item, id: index, correct: isCorrect, ans_id: option }
              : item
          )
        );

        if (isCorrect) {
          setCorrect(true);
          setShowSolution(false);
          // play();
        } else {
          setCorrect(false);
          setShowSolution(true);
        }
      }

      setShow_next(true);
      if (question?.length > Active + 1) {
        handleNext(index);
      }
      const token = localStorage.getItem("token");
      const challenge = localStorage.getItem("challenge");
      // Make the API request

      if (challenge == "true") {
        try {
          let bodyData = new FormData();
          bodyData.append("student_id", studentId);
          bodyData.append("challenge_id", state?.id);
          bodyData.append("question_id", ques_id);
          bodyData.append("answer_status", isCorrect ? 1 : 0);
          bodyData.append("points", points);
          bodyData.append("date", formatDate(new Date()));
          await axios.post(
            `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-challenge-question`,
            bodyData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "content-type": "multipart/form-data",
              },
            }
          );
        } catch (e) {
          if (
            e?.response?.status === 500 &&
            e?.response?.data?.message === "Unauthenticated"
          ) {
            clear_local_storage();
          } else if (e?.response?.status === 401) {
            clear_local_storage();
          } else {
            // toast.error("Something went wrong");
            console.log(e);
          }
        }
      } else {
        try {
          let bodyData = new FormData();
          bodyData.append("student_id", studentId);
          bodyData.append("paper_id", state?.id);
          bodyData.append("question_id", ques_id);
          bodyData.append("answer_status", isCorrect ? 1 : 0);
          bodyData.append("points", points);
          bodyData.append("date", formatDate(new Date()));
          await axios.post(
            `${process.env.REACT_APP_NEW_DEVELOPMENT}/do-past-paper-question`,
            bodyData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "content-type": "multipart/form-data",
              },
            }
          );
        } catch (e) {
          if (
            e?.response?.status === 500 &&
            e?.response?.data?.message === "Unauthenticated"
          ) {
            clear_local_storage();
          } else if (e?.response?.status === 401) {
            clear_local_storage();
          } else {
            // toast.error("Something went wrong");
            console.log(e);
          }
        }
      }
    }

    if (option === "") {
      if (qstn_no_arr[Active]?.ans_id !== "") {
        setShow_submit(false);
        handleNext(index);
      }
    }
  };

  const handleNext = (index) => {
    if (final_index === index) {
      setFinal_index(final_index + 1);
      setInitial_index(initial_index + 1);
    }

    setShow_next(false);

    if (correct || question?.length > Active + 1) {
      if (option !== "") {
        setOption("");
        setActive(Active + 1);
      } else {
        if (qstn_no_arr[index]?.ans_id) {
          setOption("");
          setActive(Active + 1);
        }
      }
    }
    setShowSolution(false);
    setCorrect();
    setOption("");
    setShow_submit(true);
    cancel();
    setSelectedOptions([]);
  };

  const handle_back_btn = (index) => {
    setActive(Active - 1);
    if (initial_index === index) {
      handleBackward();
    }
    if (qstn_no_arr[Active]?.correct == false) {
      setShowSolution(true);
    }
  };
  // other functions starts here

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };
  const handleClose = () => {
    const challenge = localStorage.getItem("challenge");
    setChallengeMode(challenge);
    if (challenge == "true") {
      navigate("/challenge");
    } else {
      navigate("/pastpaper");
    }
    setScore_modal(false);
    localStorage.removeItem("solution");
    localStorage.removeItem("active_qstn");
    localStorage.removeItem("selftest_qstn_arr");
    localStorage.removeItem("Time");
    localStorage.removeItem("pp_qstn");
    localStorage.removeItem("challenge");
    localStorage.removeItem("mode");
    localStorage.removeItem("theory");
    cancel();
  };

  const handleCheckAns = () => {
    navigate("/checkanswer", { state: state });
  };
  const handleChallengCheckAns = () => {
    navigate("/challenge-answer", { state: state });
  };

  const handleForward = () => {
    if (final_index + 1 !== qstn_no_arr?.length) {
      setFinal_index(final_index + 1);
      setInitial_index(initial_index + 1);
    }
  };

  const handleBackward = () => {
    if (initial_index > 0) {
      setFinal_index(final_index - 1);
      setInitial_index(initial_index - 1);
    }
  };

  const handle_submit_test = () => {
    setScore_modal(true);
    let count = 0;
    for (let i = 0; i < qstn_no_arr?.length; i++) {
      if (qstn_no_arr[i]?.correct === true) {
        count++;
      }
    }
    setScore(count);
  };
  // const SolTime ;
  const handle_no_click = (index) => {
    if (Active !== index) {
      if (qstn_no_arr[index]?.correct !== "") {
        setActive(index);
        setShow_submit(true);
        setShow_next(false);
      } else if (qstn_no_arr[index - 1]?.correct !== "") {
        setActive(index);
        setShow_submit(true);
        setShow_next(false);
      }
    }
  };

  // debugger
  return (
    <>
      <div className="selfTest_bg comic_font">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="d-flex justify-content-between">
          <div className="">
            <Back_btn border="2px solid #8B60FD" style={{ cursor: "default" }}>
              <AccessTimeIcon
                style={{
                  fontSize: "19px",
                  background: "#E97838",
                  color: "#FDF980",
                  borderRadius: "50%",
                  marginTop: "-3px",
                  marginRight: "3px",
                }}
              />
              {formatTime(timeLeft)}{" "}
            </Back_btn>
          </div>
          <div className="num_pagination" id="NumPage">
            {initial_index !== 0 && qstn_no_arr?.length > 10 && (
              <div className="mt-1" onClick={() => handleBackward()}>
                <ArrowBackIosIcon style={{ color: "#8B60FD" }} />
              </div>
            )}
            {qstn_no_arr &&
              qstn_no_arr?.map((elem, index) => (
                <div key={index}>
                  {index <= final_index && index >= initial_index && (
                    <>
                      {index === Active ? (
                        <div
                          className="active_qtn_num"
                          onClick={() => {
                            handle_no_click(index);
                          }}
                          key={index}
                        >
                          {index + 1}
                        </div>
                      ) : (
                        <>
                          {elem.correct === "" ? (
                            <div
                              className="qtn_num"
                              onClick={() => {
                                handle_no_click(index);
                              }}
                              key={index}
                            >
                              {index + 1}
                            </div>
                          ) : (
                            <>
                              {!elem.correct ? (
                                <div
                                  className="qtn_num"
                                  style={{
                                    backgroundColor: "#8b60fd",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    handle_no_click(index);
                                  }}
                                  key={index}
                                >
                                  {index + 1}
                                </div>
                              ) : (
                                <div
                                  className="qtn_num"
                                  style={{
                                    backgroundColor: "#8b60fd",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    handle_no_click(index);
                                  }}
                                  key={index}
                                >
                                  {index + 1}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}
            {final_index + 1 !== qstn_no_arr?.length &&
              qstn_no_arr?.length > 10 && (
                <div className="mt-1 mx-2" onClick={() => handleForward()}>
                  <ArrowForwardIosIcon style={{ color: "#8B60FD" }} />
                </div>
              )}
          </div>
          <div className="">
            <Back_btn border="2px solid #8B60FD" onClick={() => handleClose()}>
              Close
            </Back_btn>
          </div>
        </div>
        {/* /{loading == "false" ? ( */}
        <>
          {question?.length > 0 ? (
            <>
              {/* {question?.length > 0 && ( */}
              <>
                {question?.map((elem, index) => (
                  <div key={index}>
                    {index === Active && (
                      <div className="qstn_bg">
                        <div>
                          <div className="d-flex">
                            <div className="question_1">
                              <div className="qstn_sound">
                                <div>Q. {index + 1}</div>
                                {elem.question?.type == "text" && (
                                  <div className="mx-1">
                                    {supported && (
                                      <div className="speechMenu">
                                        {!speaking ? (
                                          <VolumeOffIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              speak(
                                                elem?.question?.content,
                                                voices[2]
                                              )
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={sound}
                                            alt=""
                                            className="sound_qstn"
                                            onClick={cancel}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="question_div">
                              <div className="question">
                                {elem.question?.type == "image" ? (
                                  <div>
                                    <figure className="image image-size-paper">
                                      <img
                                        className="w-100"
                                        src={elem?.question?.content}
                                        alt="question"
                                      />
                                    </figure>
                                  </div>
                                ) : (
                                  <div>
                                    <p>{elem.question?.content}</p>

                                    {elem?.image_path && (
                                      <figure className="image ">
                                        <img
                                          src={elem?.image_path}
                                          alt="question"
                                        />
                                      </figure>
                                    )}
                                  </div>
                                )}
                                {elem?.sub_question_text && (
                                  <p className="subQuestionText">
                                    {elem?.sub_question_text}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          {elem?.question_type === "One word" ? (
                            // Render the input box for one-word answer
                            <div
                              className="question_div_mobile col-lg-6 col-md-6 col-sm-6"
                              // style={{ zIndex: "99999" }}
                            >
                              <input
                                // disabled={qstn_no_arr[Active].ans_id && true}
                                className="p-2"
                                type="text"
                                placeholder=""
                                value={
                                  qstn_no_arr[Active]?.ans_id !== ""
                                    ? qstn_no_arr[Active]?.ans_id
                                    : option
                                }
                                style={{
                                  borderRadius: "10px",
                                  // zIndex: "99999",
                                  backgroundColor: qstn_no_arr[Active]?.ans_id
                                    ? "#daccff"
                                    : "#fff",
                                  pointerEvents:
                                    qstn_no_arr[Active]?.ans_id && "none",
                                }}
                                onChange={(e) => setOption(e.target.value)}
                                disabled={showSolution}
                              />
                            </div>
                          ) : elem?.question_type === "Filling the blank" ? (
                            <div
                              className="question_div_mobile col-lg-6 col-md-6 col-sm-6"
                              // style={{ zIndex: "99999" }}
                            >
                              <input
                                className="p-2"
                                type="text"
                                placeholder=""
                                value={
                                  qstn_no_arr[Active]?.ans_id !== ""
                                    ? qstn_no_arr[Active]?.ans_id
                                    : option
                                }
                                style={{
                                  borderRadius: "10px",
                                  // zIndex: "99999",
                                  backgroundColor: qstn_no_arr[Active]?.ans_id
                                    ? "#daccff"
                                    : "#fff",
                                  pointerEvents:
                                    qstn_no_arr[Active]?.ans_id && "none",
                                }}
                                onChange={(e) => setOption(e.target.value)}
                                disabled={showSolution}
                              />
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <div className="options mt-3">
                                {elem?.question_type == "Mcq multiple" ? (
                                  <>
                                    {elem?.answers.map((item, idx) => (
                                      <div key={idx} className="optn">
                                        <label className="d-flex">
                                          <div>
                                            <input
                                              type="checkbox"
                                              className="me-2"
                                              // checked={selectedOptions.includes(
                                              //   item.id
                                              // )}
                                              checked={
                                                qstn_no_arr[Active]?.ans_id !==
                                                ""
                                                  ? qstn_no_arr[
                                                      Active
                                                    ]?.ans_id?.includes(item.id)
                                                  : selectedOptions.includes(
                                                      item.id
                                                    )
                                              }
                                              onChange={() =>
                                                handleOptionChange(item.id)
                                              }
                                              disabled={showSolution}
                                            />
                                          </div>
                                          <div className="me-2">
                                            <span>({idx + 1})</span>
                                          </div>
                                          {item?.type == "image" ? (
                                            <div>
                                              <figure className="image">
                                                <img
                                                  className="w-100"
                                                  src={item?.answer}
                                                  alt="question"
                                                />
                                              </figure>
                                            </div>
                                          ) : (
                                            <div>
                                              <p> {item?.answer}</p>
                                            </div>
                                          )}
                                        </label>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {elem?.answers.map((item, index) => (
                                      <div key={index}>
                                        {qstn_no_arr[Active]?.ans_id !== "" && (
                                          <div
                                            className={
                                              qstn_no_arr[Active]?.ans_id ===
                                              item.id
                                                ? "selected_optn optn"
                                                : "optn"
                                            }
                                          >
                                            <div className="d-flex">
                                              <span className="me-2">
                                                ({index + 1})
                                              </span>
                                              {item?.type == "image" ? (
                                                <div>
                                                  <figure className="image">
                                                    <img
                                                      className="w-100"
                                                      src={item?.answer}
                                                      alt="question"
                                                    />
                                                  </figure>
                                                </div>
                                              ) : (
                                                <div>
                                                  <p> {item?.answer}</p>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {qstn_no_arr[Active]?.ans_id === "" && (
                                          <div
                                            className={
                                              option === item?.id
                                                ? "selected_optn optn"
                                                : "optn"
                                            }
                                            onClick={() =>
                                              !showSolution &&
                                              setOption(item?.id)
                                            }
                                          >
                                            <div className="d-flex">
                                              <span className="me-2">
                                                ({index + 1})
                                              </span>
                                              {item?.type == "image" ? (
                                                <div>
                                                  <figure className="image">
                                                    <img
                                                      className="w-100"
                                                      src={item?.answer}
                                                      alt="question"
                                                    />
                                                  </figure>
                                                </div>
                                              ) : (
                                                <div>
                                                  <p> {item?.answer}</p>
                                                </div>
                                              )}
                                            </div>
                                            {/* {item.answer} */}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          {correct === true && (
                            <div className="d-flex justify-content-center d-none">
                              <div className="right_mob d-none">
                                <img src={right_ans} alt="" />
                                <div className="correct_text">Correct</div>
                              </div>
                            </div>
                          )}
                          {correct === false && (
                            <div className="d-flex justify-content-center d-none">
                              <div className="correct_mob d-none">
                                <img src={wrong_ans} alt="" />
                                <div
                                  className="correct_text"
                                  style={{ color: "#EB1D1D" }}
                                >
                                  Incorrect
                                </div>
                              </div>
                            </div>
                          )}
                          {correct && (
                            <div className="d-flex justify-content-center d-none">
                              <div className="right_mob">
                                <img src={right_ans} alt="" />
                                <div className="correct_text">Correct</div>
                              </div>
                            </div>
                          )}
                          {correct === false && (
                            <div className="d-flex justify-content-center d-none">
                              <div className="correct_mob">
                                <img src={wrong_ans} alt="" />
                                <div
                                  className="correct_text"
                                  style={{ color: "#EB1D1D" }}
                                >
                                  Incorrect
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="correct_top_main_div mt-5">
                            <div
                              className="d-flex justify-content-between btn_div_selftest w-100"
                              style={{ position: "unset" }}
                            >
                              <div className="mt-1 mb-2 solution_mob">
                                {showSolution && (
                                  <SaveBtn
                                    onClick={() => {
                                      navigate("/solution_test", {
                                        state: { id: id, time: timeLeft },
                                      });
                                    }}
                                    className=" d-none"
                                    width="95px"
                                    bg="#FF6700"
                                    height="38px"
                                    shadow="2px 2px 2px 2px #FF6700"
                                    color="white"
                                  >
                                    Solution
                                  </SaveBtn>
                                )}
                              </div>
                              {correct === true && (
                                <div className="right mt-1 d-none">
                                  <img src={right_ans} alt="" />
                                  <div className="correct_text">Correct</div>
                                </div>
                              )}
                              {correct === false && (
                                <div className="correct mt-2 d-none">
                                  <img src={wrong_ans} alt="" />
                                  <div
                                    className="correct_text"
                                    style={{ color: "#EB1D1D" }}
                                  >
                                    Incorrect
                                  </div>
                                </div>
                              )}

                              <div className="mt-1 self_Test">
                                <div className="d-flex">
                                  {index > 0 && (
                                    <SaveBtn
                                      onClick={() => handle_back_btn(index)}
                                      className="bck_btn_self"
                                      width="89px"
                                      bg="#918E96"
                                      shadow="2px 2px 2px 2px #918E96"
                                      style={{ color: "white" }}
                                      height="35px"
                                      color="white"
                                      f_weight="550"
                                      f_size="20px"
                                    >
                                      Back
                                    </SaveBtn>
                                  )}

                                  {show_submit && !show_next && (
                                    <SaveBtn
                                      onClick={() => {
                                        let param = elem.answers;

                                        return handleSubmit(
                                          elem.id,
                                          param,
                                          index,
                                          elem?.question_type,
                                          elem?.answers,
                                          elem?.mark
                                        );
                                      }}
                                      className="next_btn_self"
                                      width="89px"
                                      bg="black"
                                      shadow="2px 2px 2px 2px black"
                                      height="35px"
                                      color="white"
                                      f_weight="550"
                                      f_size="20px"
                                    >
                                      Next
                                    </SaveBtn>
                                  )}
                                  {show_next &&
                                    !show_submit &&
                                    question?.length > Active + 1 && (
                                      <SaveBtn
                                        onClick={() => handleNext(index)}
                                        className="next_btn_self"
                                        width="89px"
                                        bg="black"
                                        shadow="2px 2px 2px 2px black"
                                        color="white"
                                        style={{ width: "89px" }}
                                        height="35px"
                                        f_weight="550"
                                        f_size="20px"
                                      >
                                        Next
                                      </SaveBtn>
                                    )}
                                  {show_next &&
                                    !show_submit &&
                                    question?.length === Active + 1 && (
                                      <SaveBtn
                                        onClick={() => handle_submit_test()}
                                        className="next_btn_self"
                                        width="89px"
                                        bg="black"
                                        shadow="2px 2px 2px 2px black"
                                        height="35px"
                                        color="white"
                                        f_weight="550"
                                        f_size="20px"
                                      >
                                        Submit
                                      </SaveBtn>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
              {/* )} */}
            </>
          ) : (
            <>
              {question?.length === 0 ? (
                <div className=" mt-5">
                  <ClipLoader
                    color="black"
                    loading={loading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                <div className="no_data1_img" style={{ left: "0" }}>
                  <img src={no_data} alt="" />
                </div>
              )}
            </>
          )}
        </>
        {/* ) : (
          <div className="d-flex justify-content-center w-100">
             <ClipLoader
              color="black"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
         )} */}
        {challengeMode == "true" ? (
          <div className="pp_year d-flex justify-content-start">
            Challenge : {state?.challenge_name}
          </div>
        ) : (
          <div className="pp_year d-flex justify-content-start">
            {state?.year}
          </div>
        )}
      </div>

      {challengeMode == "true" ? (
        <ScoreModal
          className="pp_model pp_model_title mt-3"
          title={"You have successfully completed your Challenge test."}
          onClick={() => handleClose()}
          // totalScore={qstn_no_arr?.length}
          // obtainedScore={score}
          // subTitle={" Score obtained"}
          btn_content={"check your Answer"}
          btn={() => handleChallengCheckAns()}
          body={"Check your answer and calculate your score."}
          show={score_modal}
          onHide={() => setScore_modal(!setScore_modal)}
        />
      ) : (
        <ScoreModal
          className="pp_model pp_model_title mt-3"
          title={"You have successfully completed your Past Paper test."}
          onClick={() => handleClose()}
          // totalScore={qstn_no_arr?.length}
          // obtainedScore={score}
          // subTitle={" Score obtained"}
          btn_content={"check your Answer"}
          btn={() => handleCheckAns()}
          body={"Check your answer and calculate your score."}
          show={score_modal}
          onHide={() => setScore_modal(!setScore_modal)}
        />
      )}
    </>
  );
}

export default StructuredQues;
