import React, { useEffect, useState } from "react";
import TopNavBar from "../TopNavBar/TopNav";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./activity.css";
import {
  sun_gif,
  sunflower_gif,
  autum_leafs,
  cold_cloud,
  rainy_girl,
  snowman,
  spring_leaf,
} from "../../Assets/Gif";
import axios from "axios";
import { Progress_btn } from "../Style/Styled";
import UseGetSubject from "../../CustomHook/UseGetSubject";
import { useNavigate } from "react-router-dom";

function SeasonActivity() {
  const { all_subject } = UseGetSubject();
  localStorage.removeItem("sub");
  localStorage.removeItem("subId");

  const [active, set_active] = useState();
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState();
  const [activity_data, setActivity_data] = useState([]);
  const [open, setOpen] = useState("summer");
  const [week, setWeek] = useState("week1");
  const navigate = useNavigate();

  useEffect(() => {
    if (all_subject) {
      set_active(all_subject[0]?.id);
    }
  }, [all_subject]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    const d = new Date();
    setMonth(d.getMonth());
    getCurrentSeason();
  }, []);

  useEffect(() => {
    setLoading(true);
    setWeek("week1");
    get_season_activity();
    setWeek("week1");
  }, [open, active]);

  useEffect(() => {
    setLoading(true);
    get_season_activity();
  }, [month, week]);

  const get_season_activity = async () => {
    setLoading(true);
    setActivity_data([]);
    const token = localStorage.getItem("token");

    try {
      let resp;
      const bodyData = new FormData();
      bodyData.append("season", open);
      bodyData.append("week", week);
      bodyData.append("subject_id", active);
      bodyData.append("month", monthNames[month].toLowerCase());
      if (open && week && active && monthNames[month].toLowerCase()) {
        resp = await axios.post(
          `${process.env.REACT_APP_DEVELOPMENT}/api/ps/viewSeasonActivity`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
      }
      if (resp.status == 200) {
        setActivity_data(resp.data.data);
        setLoading(false);
      }
    } catch (e) {
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      }
    }
  };

  // useEffect(() => {
  //   if (weekObj !== {}) {
  //     if (week === "week1") {
  //       setActiveWeekData(weekObj.week_1);
  //     } else if (week === "week2") {
  //       setActiveWeekData(weekObj.week_2);
  //     } else if (week === "week3") {
  //       setActiveWeekData(weekObj.week_3);
  //     } else if (week === "week4") {
  //       setActiveWeekData(weekObj.week_4);
  //     } else if (week === "week5") {
  //       setActiveWeekData(weekObj.week_5);
  //     }
  //   }
  // }, [weekObj, week]);

  const clear_local_storage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("User");
    // navigate("/");
  };
  const handle_start_test = (elem, type) => {
    let sub;
    if (active === 17) {
      sub = "Maths";
    } else {
      sub = "Science";
    }
    let week_sa;
    if (week === "week1") {
      week_sa = "Week 1";
    } else if (week === "week2") {
      week_sa = "Week 2";
    } else if (week === "week3") {
      week_sa = "Week 3";
    } else if (week === "week4") {
      week_sa = "Week 4";
    } else if (week === "week5") {
      week_sa = "Week 5";
    }

    const str = open;
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    let obj = { sub: sub, season: str2, week: week_sa };
    localStorage.setItem("season_data", JSON.stringify(obj));
    localStorage.removeItem("season_active_qstn");
    localStorage.removeItem("season_selftest_qstn_arr");
    const state = { data: elem, type: type };
    localStorage.setItem("seasonTypeData", JSON.stringify(state));
    navigate(`/s_test/${elem?.id}`);
  };

  const handleForward = () => {
    if (month + 1 !== monthNames.length) {
      setMonth(month + 1);
    }
  };

  const handleBackward = () => {
    if (month > 0) {
      setMonth(month - 1);
    }
  };

  const getCurrentSeason = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed, so we add 1 to get the correct month number.

    if (currentMonth >= 3 && currentMonth <= 5) {
      setOpen("spring");
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      setOpen("summer");
    } else if (currentMonth >= 9 && currentMonth <= 11) {
      setOpen("autumn");
    } else {
      setOpen("winter");
    }
  };

  return (
    <>
      <div
        className="comp_container comic_font"
        style={{ backgroundColor: "white" }}
      >
        <TopNavBar />
        <div className="d-flex header_prg">
          {all_subject?.map((elem, index) => (
            <div key={index}>
              {active === 1 && (
                <Progress_btn
                  bg={active === elem.id ? "#E97838" : "#D6D6D6"}
                  onClick={() => set_active(elem.id)}
                >
                  {elem.name}
                </Progress_btn>
              )}
              {active === 2 && (
                <Progress_btn
                  bg={active === elem.id ? "#6DEEA5" : "#D6D6D6"}
                  onClick={() => set_active(elem.id)}
                >
                  {elem.name}
                </Progress_btn>
              )}
            </div>
          ))}
        </div>
        <div className="activity_sideDiv">
          <div className="w-100">
            <div className="season_btn_inside mt-2">
              <div className="season_btn">
                <div className="d-flex">
                  <button
                    className={
                      open === "summer" ? "orangeBtn_Active" : "orangeBtn"
                    }
                    onClick={() => setOpen("summer")}
                    style={{ backgroundColor: open === "summer" && "#facd51" }}
                  >
                    Summer
                  </button>
                  <button
                    className={
                      open === "autumn"
                        ? "orangeBtn_Active margin_5"
                        : "orangeBtn margin_5"
                    }
                    style={{
                      backgroundColor: open === "autumn" && "#D68660",
                      color: open === "autumn" && "white",
                    }}
                    onClick={() => setOpen("autumn")}
                  >
                    Autumn
                  </button>
                </div>
                <div className="d-flex">
                  <button
                    className={
                      open === "winter"
                        ? "orangeBtn_Active margin_5"
                        : "orangeBtn margin_5"
                    }
                    onClick={() => setOpen("winter")}
                    style={{
                      backgroundColor: open === "winter" && "#005484",
                      color: open === "winter" && "white",
                    }}
                  >
                    Winter
                  </button>
                  <button
                    className={
                      open === "spring"
                        ? "orangeBtn_Active margin_5"
                        : "orangeBtn margin_5"
                    }
                    onClick={() => setOpen("spring")}
                    style={{
                      backgroundColor: open === "spring" && "#95C136",
                    }}
                  >
                    Spring
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-2 june_div">
                <div>
                  {month !== 0 && (
                    <div className="mt-1" onClick={() => handleBackward()}>
                      <ArrowBackIosIcon
                        style={{ fontSize: "9px", cursor: "pointer" }}
                      />
                    </div>
                  )}
                </div>
                <div className="june">{monthNames[month]}</div>
                <div>
                  {month + 1 !== monthNames.length && (
                    <div className="mt-1 mx-2" onClick={() => handleForward()}>
                      <ArrowForwardIosIcon
                        style={{ fontSize: "9px", cursor: "pointer" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {open === "summer" && (
              <>
                <div
                  className="weekdata mt-3 h-100 pe-5 w-100 ps-0 "
                  style={{
                    background:
                      "linear-gradient(180deg, #FACD51 0%, #D6F7D2 100%)",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <div className="d-flex justify-content-center mobileView">
                    <div>
                      <div className="d-flex week_div">
                        <div
                          onClick={() => setWeek("week1")}
                          className={week === "week1" ? "week1" : "week"}
                        >
                          Week 1
                        </div>
                        <div
                          onClick={() => setWeek("week2")}
                          className={
                            week === "week2"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 2
                        </div>
                        <div
                          onClick={() => setWeek("week3")}
                          className={week === "week3" ? "week1" : "week"}
                        >
                          Week 3
                        </div>
                        <div
                          onClick={() => setWeek("week4")}
                          className={
                            week === "week4"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 4
                        </div>
                        <div
                          onClick={() => setWeek("week5")}
                          className={week === "week5" ? "week1" : "week"}
                        >
                          Week 5
                        </div>
                      </div>
                      <div className="Card d-flex justify-content-between">
                        <div className="sun_div">
                          <img src={sun_gif} alt="img" />
                        </div>
                        <div className="d-flex justify-content-center w-100">
                          <div>
                            <div className="cardContent d-flex justify-content-center mx-3">
                              <div className="mb-3 mt-3">
                                {activity_data?.length === 0 && (
                                  <div className="d-flex justify-content-between mt-4">
                                    <div className="no_data_found_div">
                                      No Data Found
                                    </div>
                                  </div>
                                )}

                                {activity_data.length !== 0 && (
                                  <div className="col-12 col-lg-12 cl-md-12 col-sm-12 row season_data_div">
                                    {activity_data?.map((elem, index) => (
                                      <div
                                        className="d-flex justify-content-between mt-4   "
                                        key={index}
                                      >
                                        <div className="month01 col-3 col-lg-3 col-md-4 col-sm-4">
                                          {elem?.day}
                                        </div>
                                        <div className="grayDot mx-2 col-3 col-lg-3 col-md-3 col-sm-2"></div>
                                        <button
                                          className="start col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "start")
                                          }
                                        >
                                          Start
                                        </button>
                                        <button
                                          className="redo col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "redo")
                                          }
                                        >
                                          Redo
                                        </button>
                                        <div className="month01 margin_5 col-2 col-lg-2 col-md-1 col-sm-2">
                                          {elem?.season_activity_score?.score}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flower_div">
                          <img
                            src={sunflower_gif}
                            alt="img"
                            className="sunFlower"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </>
            )}
            {open === "autumn" && (
              <>
                <div
                  className="weekdata h-100 mt-3 pe-5 w-100 ps-0"
                  style={{
                    background:
                      "linear-gradient(180deg, #FFAA7B 0%, #FACD51 100%)",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <div className="d-flex justify-content-center mobileView">
                    <div>
                      <div className="d-flex week_div">
                        <div
                          onClick={() => setWeek("week1")}
                          className={week === "week1" ? "week1" : "week"}
                        >
                          Week 1
                        </div>
                        <div
                          onClick={() => setWeek("week2")}
                          className={
                            week === "week2"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 2
                        </div>
                        <div
                          onClick={() => setWeek("week3")}
                          className={week === "week3" ? "week1" : "week"}
                        >
                          Week 3
                        </div>
                        <div
                          onClick={() => setWeek("week4")}
                          className={
                            week === "week4"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 4
                        </div>
                        <div
                          onClick={() => setWeek("week5")}
                          className={week === "week5" ? "week1" : "week"}
                        >
                          Week 5
                        </div>
                      </div>
                      <div
                        className="Card d-flex justify-content-between"
                        style={{ boxShadow: "10px 10px #E87737" }}
                      >
                        <div className="sun_div">
                          <img src={autum_leafs} alt="img" />
                        </div>
                        <div className="d-flex justify-content-center w-100">
                          <div>
                            <div className="cardContent d-flex justify-content-center">
                              <div className="mb-3">
                                {activity_data?.length === 0 && (
                                  <div className="d-flex justify-content-between mt-4">
                                    <div className="no_data_found_div">
                                      No Data Found
                                    </div>
                                  </div>
                                )}

                                {activity_data.length !== 0 && (
                                  <div className="col-12 col-lg-12 cl-md-12 col-sm-12 row season_data_div">
                                    {activity_data?.map((elem, index) => (
                                      // <div
                                      //   className="d-flex justify-content-between mt-4"
                                      //   key={index}
                                      // >
                                      //   <div className="month01">
                                      //     {elem?.day}
                                      //   </div>
                                      //   <div className="grayDot mx-2"></div>
                                      //   <button
                                      //     className="start"
                                      //     onClick={() =>
                                      //       handle_start_test(elem, 'start')
                                      //     }
                                      //   >
                                      //     Start
                                      //   </button>
                                      //   <button
                                      //     className="redo"
                                      //     onClick={() =>
                                      //       handle_start_test(elem, 'redo')
                                      //     }
                                      //   >
                                      //     Redo
                                      //   </button>
                                      //   <div className="month01 margin_5">
                                      //     {elem?.season_activity_score?.score}
                                      //   </div>
                                      // </div>
                                      <div
                                        className="d-flex justify-content-between mt-4  "
                                        key={index}
                                      >
                                        <div className="month01 col-3 col-lg-3 col-md-4 col-sm-3">
                                          {elem?.day}
                                        </div>
                                        <div className="grayDot mx-2 col-3 col-lg-3 col-md-3 col-sm-3"></div>
                                        <button
                                          className="start col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "start")
                                          }
                                        >
                                          Start
                                        </button>
                                        <button
                                          className="redo col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "redo")
                                          }
                                        >
                                          Redo
                                        </button>
                                        <div className="month01 margin_5 col-2 col-lg-2 col-md-1 col-sm-2">
                                          {elem?.season_activity_score?.score}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flower_div">
                          <img
                            src={rainy_girl}
                            alt="img"
                            className="sunFlower"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </>
            )}
            {open === "winter" && (
              <>
                <div
                  className="weekdata h-100 mt-3 pe-5 w-100 ps-0"
                  style={{
                    background:
                      "linear-gradient(180deg, #AFC8F2 0%, #B1D0F7 100%)",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <div className="d-flex justify-content-center mobileView">
                    <div>
                      <div className="d-flex week_div">
                        <div
                          onClick={() => setWeek("week1")}
                          className={week === "week1" ? "week1" : "week"}
                        >
                          Week 1
                        </div>
                        <div
                          onClick={() => setWeek("week2")}
                          className={
                            week === "week2"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 2
                        </div>
                        <div
                          onClick={() => setWeek("week3")}
                          className={week === "week3" ? "week1" : "week"}
                        >
                          Week 3
                        </div>
                        <div
                          onClick={() => setWeek("week4")}
                          className={
                            week === "week4"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 4
                        </div>
                        <div
                          onClick={() => setWeek("week5")}
                          className={week === "week5" ? "week1" : "week"}
                        >
                          Week 5
                        </div>
                      </div>
                      <div
                        className="Card d-flex justify-content-between"
                        style={{ boxShadow: "10px 10px #005484" }}
                      >
                        <div className="sun_div">
                          <img src={cold_cloud} alt="img" width="130px" />
                        </div>
                        <div className="d-flex justify-content-center w-100 sunRes">
                          <div>
                            <div className="cardContent d-flex justify-content-center">
                              <div className="mb-3">
                                {activity_data?.length === 0 && (
                                  <div className="d-flex justify-content-between mt-4">
                                    <div className="no_data_found_div">
                                      No Data Found
                                    </div>
                                  </div>
                                )}

                                {activity_data.length !== 0 && (
                                  <div className="col-12 col-lg-12 cl-md-12 col-sm-12 row season_data_div">
                                    {activity_data.map((elem, index) => (
                                      <div
                                        className="d-flex justify-content-between mt-4 "
                                        key={index}
                                      >
                                        <div className="month01 col-3 col-lg-3 col-md-4 col-sm-3">
                                          {elem?.day}
                                        </div>
                                        <div className="grayDot mx-2 col-3 col-lg-3 col-md-3 col-sm-3"></div>
                                        <button
                                          className="start col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "start")
                                          }
                                        >
                                          Start
                                        </button>
                                        <button
                                          className="redo col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "redo")
                                          }
                                        >
                                          Redo
                                        </button>
                                        <div className="month01 margin_5 col-2 col-lg-2 col-md-1 col-sm-2">
                                          {elem?.season_activity_score?.score}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flower_div">
                          <img src={snowman} alt="img" className="sunFlower" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </>
            )}

            {open === "spring" && (
              <>
                <div
                  className="weekdata h-100 mt-3 pe-5 w-100 ps-0"
                  style={{
                    background:
                      "linear-gradient(180deg, #C3FC49 0%, #FFB4DC 100%)",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <div
                    className="d-flex justify-content-center mobileView"
                    style={{ height: "100vvh" }}
                  >
                    <div>
                      <div className="d-flex week_div">
                        <div
                          onClick={() => setWeek("week1")}
                          className={week === "week1" ? "week1" : "week"}
                        >
                          Week 1
                        </div>
                        <div
                          onClick={() => setWeek("week2")}
                          className={
                            week === "week2"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 2
                        </div>
                        <div
                          onClick={() => setWeek("week3")}
                          className={week === "week3" ? "week1" : "week"}
                        >
                          Week 3
                        </div>
                        <div
                          onClick={() => setWeek("week4")}
                          className={
                            week === "week4"
                              ? "week1 margin_5"
                              : "week margin_5"
                          }
                        >
                          Week 4
                        </div>
                        <div
                          onClick={() => setWeek("week5")}
                          className={week === "week5" ? "week1" : "week"}
                        >
                          Week 5
                        </div>
                      </div>
                      <div
                        className="Card d-flex justify-content-between"
                        style={{ boxShadow: "10px 10px #95C136" }}
                      >
                        <div className="sun_div">
                          <img src={spring_leaf} alt="img" />
                        </div>
                        <div className="d-flex justify-content-center w-100">
                          <div>
                            <div className="cardContent d-flex justify-content-center">
                              <div className="mb-3">
                                {activity_data?.length === 0 && (
                                  <div className="d-flex justify-content-between mt-4">
                                    <div className="no_data_found_div">
                                      No Data Found
                                    </div>
                                  </div>
                                )}

                                {activity_data.length !== 0 && (
                                  <div className="col-12 col-lg-12 cl-md-12 col-sm-12 row season_data_div">
                                    {activity_data.map((elem, index) => (
                                      <div
                                        className="d-flex justify-content-between mt-4 "
                                        key={index}
                                      >
                                        <div className="month01 col-3 col-lg-3 col-md-4 col-sm-3">
                                          {elem?.day}
                                        </div>
                                        <div className="grayDot mx-2 col-3 col-lg-3 col-md-3 col-sm-3"></div>
                                        <button
                                          className="start col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "start")
                                          }
                                        >
                                          Start
                                        </button>
                                        <button
                                          className="redo col-2 col-lg-2 col-md-2 col-sm-2"
                                          onClick={() =>
                                            handle_start_test(elem, "redo")
                                          }
                                        >
                                          Redo
                                        </button>
                                        <div className="month01 margin_5 col-2 col-lg-2 col-md-1 col-sm-2">
                                          {elem?.season_activity_score?.score}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flower_div">
                          <img
                            src={spring_leaf}
                            alt="img"
                            className="sunFlower"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SeasonActivity;
