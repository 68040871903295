import React, { useState } from "react";
import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const StepStyle = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: ${({ step }) =>
    step === "completed" ? "#daccff" : "#ffffff"};
  border: 1px solid #daccff;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ step }) => (step === "completed" ? "white" : "#daccff")};
`;

export const StepCount = styled.span`
  font-size: 22px;
  color: #daccff;
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: #daccff;
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
`;

export const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: #4a154b;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
  :active {
    transform: scale(0.98);
  }
  :disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
`;

export const CheckMark = styled.div`
  font-size: 22px;
  color: #fbfbfb;
`;
export const steps = [
  {
    name: "a",
    id: 1,
  },
  {
    name: "b",
    id: 2,
  },
  {
    name: "c",
    id: 3,
  },
  {
    name: "d",
    id: 4,
  },
];

const ProgressSteps = () => {
  const [activeStep, setActiveStep] = useState(1);
  const totalSteps = steps.length;
  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <MainContainer>
      <StepContainer>
        {steps.map(({ id, name }) => (
          <StepWrapper key={id}>
            <StepStyle step={activeStep >= id ? "completed" : "incomplete"}>
              {activeStep >= id ? (
                <CheckMark>{name}</CheckMark>
              ) : (
                <StepCount>{name}</StepCount>
              )}
            </StepStyle>
          </StepWrapper>
        ))}
      </StepContainer>
      {/* <ButtonsContainer>
        <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
          Previous
        </ButtonStyle>
        <ButtonStyle onClick={nextStep} disabled={activeStep === totalSteps}>
          Next
        </ButtonStyle>
      </ButtonsContainer> */}
    </MainContainer>
  );
};

export default ProgressSteps;
