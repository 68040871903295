import React, { useEffect, useState } from "react";
import {
  avatar_bg,
  uploadAvatar,
  close,
  avatar_select,
} from "../../Assets/Images/index";
import Modal from "react-bootstrap/Modal";
import { SaveBtn } from "../Style/Styled";
import "./Avatar.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useApiContext } from "../../Context/ApiContext";

function Aavtar() {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  const [show, setShow] = useState(false);
  const [uploadAvtar, setUploadAvatar] = useState();
  const [upload_avatar_name, setUpload_avatar_name] = useState("");
  const { getUserProfile, allAvatar, getAllAvatar, apiLoading } =
    useApiContext();
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setUploadAvatar("");
  };
  const handleShow = () => {
    setShow(true);
    setUploadAvatar("");
  };

  const update_Avatar = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const bodyData = new FormData();
      const file = uploadAvtar;
      bodyData.append("image_path", file);
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_DEVELOPMENT}/avathar-image-create`,
        bodyData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      if (resp.status === 200 || resp.status === 201) {
        toast.success("Avatar uploaded");
        getAllAvatar();
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      if (
        e?.response?.data?.status_code === 401 ||
        e?.response?.data?.status_code === 500
      ) {
        clear_local_storage();
      } else {
        toast.error("Something went wrong");
      }
    }
    setLoading(false);
    setUpload_avatar_name(""); // Clear the file name after upload
  };

  const select_avatar = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if (id !== "") {
      try {
        const bodyData = new FormData();
        bodyData.append("avathar_image_id", id);
        const resp = await axios.post(
          `${process.env.REACT_APP_NEW_DEVELOPMENT}/avathar-image-set`,

          bodyData,
          {
            headers: {
              Authorization: "Bearer " + token,
              "content-type": "multipart/form-data",
            },
          }
        );
        if (resp) {
          toast.success("Avatar Updated");
          getUserProfile();
          setTimeout(() => {
            navigate("/dashboard");
            setLoading(false);
          }, "2000");
        }
      } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 500) {
          clear_local_storage();
        } else {
          toast.error("Something went wrong");
        }
      }
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setUpload_avatar_name(file.name);
    setUploadAvatar(file);

    // var reader = new FileReader();
    // reader.onload = function (upload) {
    //   // Set uploadAvatar with the data URL of the image
    //   setUploadAvatar(upload.target.result);
    // };
    // reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (show && id === "") {
      setId(allAvatar[0].id);
    } else if (!show) {
      setId("");
    }
  }, [show]);

  const clear_local_storage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
    navigate("/");
  };

  return (
    <>
      <div
        className="top comic_font"
        style={{ backgroundImage: `url("${avatar_bg}")` }}
      >
        <div className="close_avatar">
          <img src={close} alt="img" onClick={() => navigate("/dashboard")} />
        </div>

        <div className="mt-3 avatar_header">
          <div className="btn_cntnr">
            <img src={avatar_select} alt="img" className="select_bg" />
            <div className="select_content">Select your avatar</div>
          </div>
          <div className="d-flex justify-content-end edit_div">
            <img
              src={uploadAvatar}
              alt="img"
              className="edit"
              onClick={handleShow}
            />
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <>
          <div className="mt-5 grid">
            {allAvatar?.length > 0 ? (
              <>
                {allAvatar?.map((elem, index) => (
                  <div
                    key={index}
                    className={elem.id === id ? "avatar_click" : "avatarBox"}
                    onClick={() => setId(elem?.id)}
                  >
                    <img src={elem?.image} alt="img" className="avatar_img" />
                  </div>
                ))}
              </>
            ) : (
              apiLoading.avatar && (
                <>
                  <div className="">
                    <ClipLoader
                      color="black"
                      loading={true}
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </>
              )
            )}
          </div>
          {loading && allAvatar?.length > 0 && (
            <>
              <div className="">
                <ClipLoader
                  color="black"
                  loading={true}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            </>
          )}
          <div style={{ paddingBottom: "40px" }}>
            {!loading && (
              <SaveBtn
                className="my-5 saveBtn"
                width="200px"
                bg="#E82968"
                shadow="2px 2px 2px 2px #e82968"
                color="white"
                height="42px"
                onClick={() => {
                  if (id !== "") {
                    select_avatar();
                  }
                }}
              >
                Save
              </SaveBtn>
            )}
          </div>
        </>
      </div>

      <Modal
        show={show}
        size="lg"
        // style={{ width: "500px" }}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          className="avatarModal"
          style={{
            padding: "30px",
            height: "300px",
            border: "8px solid #8B60FD",
            borderRadius: "80px",
          }}
        >
          <Modal.Body className="ModalBodyAvtar">
            <div className="comic_font">
              <div className="d-flex justify-content-end">
                <div
                  className="close"
                  style={{ marginRight: "-20px", marginTop: "-20px" }}
                  onClick={() => handleClose()}
                >
                  <img src={close} alt="img" />
                </div>
              </div>
              <h3>Upload Avatar</h3>
              <div className="uploadSectn my-4 col-12 col-lg-12 col-md-12 col-sm-12 d-flex align-item-center">
                <label htmlFor="inputTag" className="uploadBtn">
                  Upload icon
                  <div className="d-flex col-lg-2 col-md-2 col-sm-2 col-4 ">
                    <input
                      id="inputTag"
                      type="file"
                      className="uploadInp"
                      accept="image/*"
                      onChange={(e) => handleUpload(e)}
                    />
                  </div>
                </label>
                <div className="upload_name col-lg-8 col-md-8 col-sm-8 col-8 ms-1 align-self-center w-10">
                  <span className="uploadName">{upload_avatar_name}</span>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <SaveBtn
                  className="saveBtn"
                  width="100px"
                  bg="#FF6700"
                  color="white"
                  shadow="2px 2px 2px 2px #FF6700"
                  height="35px"
                  onClick={() => {
                    setShow(false);

                    update_Avatar();
                  }}
                  disabled={apiLoading.avatar}
                >
                  Save
                </SaveBtn>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Aavtar;
